import { FormControlLabel, Checkbox as MuiCheckbox } from '@mui/material';
import { useField } from 'formik';

const Checkbox = ({ name, label, ...props }) => {
	const [field] = useField(name);

	return <FormControlLabel control={<MuiCheckbox {...field} {...props} />} label={label} />;
};

export default Checkbox;

import { createContext, useCallback, useContext, useMemo, useState } from 'react';

const ModalContext = createContext({
	current: null,
	openModal: () => {},
	closeModal: () => {},
	registerModalOpen: false,
	inquiryModalOpen: false,
});

export const ModalProvider = ({ children }) => {
	const [current, setCurrent] = useState(null);
	const inquiryModalOpen = useMemo(() => Boolean(current && current.type === 'inquiry' && current.open), [current]);
	const companiesModalOpen = useMemo(() => Boolean(current && current.type === 'companies' && current.open), [current]);

	const openModal = useCallback((modalProps) => {
		setCurrent({
			...modalProps,
			open: true,
		});
	}, []);

	const closeModal = () => setCurrent(null);

	return (
		<ModalContext.Provider
			value={{
				current,
				openModal,
				closeModal,
				inquiryModalOpen,
				companiesModalOpen,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

export const useModalContext = () => useContext(ModalContext);

import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Link,
	Stack,
	Step,
	StepLabel,
	Stepper,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'd3';
import { apiClient, endpoints, onlyDateFormatter } from '../../../../utils';
import { buyerSellerOptions } from '../../../../utils/constants';
import { useAuthContext } from '../../../../context';
import { useState } from 'react';
import { Assignment, ExpandLess, ExpandMore, Warning } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { constants } from '../../../../utils/constants';
import { ActivityHeader } from './style';
import ActivityItem from '../../../../components/ActivityItem';
import MessageField from './components/MessageField';
import { strings } from '../../../../utils/strings';

const InquiryCard = ({ inquiry, userContext }) => {
	const defaultTheme = useTheme();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const steps = ['Onboarding', 'Marketing', 'Execution', 'Settlement'];
	const { user, reloadUserData } = useAuthContext();
	const [isCancelInstInquiryDialogOpen, setCancelInstInquiryDialogOpen] = useState(false);
	const [isActivityCollapsed, setActivityCollapsed] = useState(false);

	if (user.instie) {
		//add the step "Initial" to the start of the steps array
		steps.unshift('Initial');
		//remove the step "Onboarding" from the steps array
		steps.splice(
			steps.findIndex((step) => step === 'Onboarding'),
			1
		);
	}

	const getQuestionnaireLink = (type = '') => {
		if (type.toLowerCase().includes('transactional')) {
			return '/trxquest/';
		} else {
			return '/shareholder/';
		}
	};

	const cancelInquiry = async () => {
		try {
			if (user?.instie) {
				await apiClient.get(endpoints.cancelInstInquiry.replace('{inquiryId}', inquiry.id));
			} else {
				await apiClient.get(endpoints.cancelInquiry.replace('{inquiryId}', inquiry.id));
			}
			reloadUserData();
			toast.success(strings.successMessages.inquiry.canceled);
		} catch (err) {
			toast.error(strings.errorMessages.inquiry.cancel);
		} finally {
			setCancelInstInquiryDialogOpen(false);
		}
	};

	const enableInquiry = async () => {
		try {
			if (user?.instie) {
				await apiClient.get(endpoints.enableInstInquiry.replace('{inquiryId}', inquiry.id));
			} else {
				await apiClient.get(endpoints.enableInquiry.replace('{inquiryId}', inquiry.id));
			}
			reloadUserData();
			toast.success(strings.successMessages.inquiry.enabled);
		} catch (err) {
			toast.error(strings.errorMessages.inquiry.enable);
		}
	};

	const renderActivityAction = (activityLog) => {
		if (activityLog.fields.Link) {
			return (
				<Button
					variant={'outlined'}
					sx={{ width: isMobile ? '100%' : '200px', flexShrink: 0 }}
					size={isMobile ? 'medium' : 'small'}
					color={'secondary'}
					href={activityLog.fields['Link']}
					target={'_blank'}
				>
					Open
				</Button>
			);
		}

		if (activityLog.fields.Type.toLowerCase().includes('onboarding')) {
			if (activityLog.fields.Locked || activityLog.fields['Submitted At']) {
				return (
					<Button
						variant={'outlined'}
						sx={{ width: isMobile ? '100%' : '200px', flexShrink: 0 }}
						size={isMobile ? 'medium' : 'small'}
						color={'greyButton'}
						component={RouterLink}
						to={getQuestionnaireLink(activityLog.fields.Type) + activityLog.id}
					>
						{activityLog.fields.Locked ? 'View form' : 'Edit form'}
					</Button>
				);
			} else {
				return (
					<Button
						variant={'outlined'}
						sx={{ width: isMobile ? '100%' : '200px', flexShrink: 0 }}
						size={isMobile ? 'medium' : 'small'}
						component={RouterLink}
						color={'secondary'}
						to={getQuestionnaireLink(activityLog.fields.Type) + activityLog.id}
					>
						{activityLog.fields.Type === 'OnboardingPersonal' ? 'Shareholder Profile' : 'Onboarding'}
					</Button>
				);
			}
		}
	};

	return (
		<Card key={inquiry.id}>
			<CardContent sx={{ paddingBottom: `${isActivityCollapsed ? 0 : 24}px !important` }}>
				<Stack spacing={'20px'} direction={'column'}>
					<Stack spacing={isMobile ? 2 : 3} direction={'column'}>
						<Stack direction={'row'} alignItems={'center'}>
							<Stack direction={'column'} spacing={1} flex={1} alignItems={'flex-start'}>
								<Stack direction={'row'} spacing={1} alignItems={'center'}>
									<Assignment color="primary" fontSize="small" />
									<Typography variant={'overline'} color={'primary.main'} lineHeight={'normal'}>
										Inquiry
									</Typography>
								</Stack>
								<Link
									component={RouterLink}
									color={'text.primary'}
									underline={'hover'}
									variant="h5"
									to={`/company/${inquiry.fields.Company[0]}`}
								>
									{inquiry.fields['Company Name'][0]}
								</Link>
							</Stack>
							{!isMobile && user?.instie && inquiry.fields.InternalStatus === 'Cancelled' && (
								<Stepper activeStep={1}>
									<Step completed={true}>
										<StepLabel error>Cancelled</StepLabel>
									</Step>
								</Stepper>
							)}
							{!isMobile && inquiry.fields.InternalStatus !== 'Cancelled' && (
								<Stepper
									sx={{ width: 600 }}
									activeStep={steps.findIndex((step) => {
										const mapper = user.instie ? constants.INQUIRY.INQUISTION_STATUS_MAP : constants.INQUIRY.STATUS_MAP;

										if (!mapper) return false;
										return step === mapper[inquiry.fields.InternalStatus];
									})}
								>
									{steps.map((label) => (
										<Step
											key={label}
											completed={
												constants.INQUIRY.STATUS_MAP[inquiry.fields.InternalStatus] === 'Closed' ? true : undefined
											}
										>
											<StepLabel>{label}</StepLabel>
										</Step>
									))}
								</Stepper>
							)}
						</Stack>
						<Stack spacing={isMobile ? '6px' : '40px'} direction={!isMobile ? 'row' : 'column'}>
							{isMobile && Boolean(inquiry.fields.InternalStatus) && (
								<Stack alignItems={'center'} direction={'row'}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Status
									</Typography>
									<Box flex={isMobile ? 1 : undefined}>
										<Chip label={inquiry.fields.InternalStatus} variant="contained" color={'primary'} size={'small'} />
									</Box>
								</Stack>
							)}

							<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
								<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
									Type
								</Typography>
								<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
									Inquiry
								</Typography>
								{inquiry.fields['Went Live'] && (
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										Ticket
									</Typography>
								)}
							</Stack>
							<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
								<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
									Created at
								</Typography>
								<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
									{onlyDateFormatter(new Date(inquiry.fields['Created At']))}
								</Typography>
								{inquiry.fields['Went Live'] && (
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{onlyDateFormatter(new Date(inquiry.fields['Went Live']))}
									</Typography>
								)}
							</Stack>
							<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
								<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
									Action
								</Typography>
								<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
									{buyerSellerOptions.find((opt) => opt.value === inquiry.fields.Action)?.label}
								</Typography>
								{inquiry.fields['Went Live'] && (
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{buyerSellerOptions.find((opt) => opt.value === inquiry.fields.Action)?.label}
									</Typography>
								)}
							</Stack>
							{user?.instie && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Market Visibility
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{inquiry.fields['Market Visibility']}
									</Typography>
								</Stack>
							)}
							{user?.instie && Boolean(inquiry.fields['Valuation']) && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Valuation
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{inquiry.fields['Valuation']}
									</Typography>
								</Stack>
							)}
							{Boolean(inquiry.fields.Shares) && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Shares
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format(',')(inquiry.fields.Shares)}
									</Typography>
									{inquiry.fields['Went Live'] && (
										<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
											{inquiry.fields.QTY ? format(',')(inquiry.fields.QTY) : 'N/A'}
										</Typography>
									)}
								</Stack>
							)}
							{Boolean(inquiry.fields.PPS) && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Share price
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format('$,.2f')(inquiry.fields.PPS)}
									</Typography>
									{inquiry.fields['Went Live'] && (
										<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
											{inquiry.fields.Price ? format('$,.2f')(inquiry.fields.Price) : 'N/A'}
										</Typography>
									)}
								</Stack>
							)}
							{user?.instie && inquiry.fields['Block Size Min'] && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Block size min
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format('$,.2f')(inquiry.fields['Block Size Min'])}
									</Typography>
								</Stack>
							)}
							{user?.instie && inquiry.fields['Shares From'] && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Min Shares
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format(',.0f')(inquiry.fields['Shares From'])}
									</Typography>
								</Stack>
							)}
							{user?.instie && inquiry.fields['Block Size'] && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Block size max
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format('$,.2f')(inquiry.fields['Block Size'])}
									</Typography>
								</Stack>
							)}
							{user?.instie && inquiry.fields['Shares'] && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Max Shares
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format(',.0f')(inquiry.fields['Shares'])}
									</Typography>
								</Stack>
							)}
							{!user.instie && Boolean(inquiry.fields['Block Size']) && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Block size
									</Typography>
									<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
										{format('$,.2f')(inquiry.fields['Block Size'])}
									</Typography>
									{inquiry.fields['Went Live'] && (
										<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
											{inquiry.fields['Block Size Ticket']
												? format('$,.2f')(inquiry.fields['Block Size Ticket'])
												: 'N/A'}
										</Typography>
									)}
								</Stack>
							)}
							{!user?.instie && inquiry.fields['Expiration'] && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										Expiration
									</Typography>
									<Typography variant={'body2'} color="text.primary">
										&nbsp;
									</Typography>
									<Typography variant={'body2'} color="text.primary" flex={isMobile ? 1 : undefined}>
										{onlyDateFormatter(new Date(inquiry.fields['Expiration']))}
									</Typography>
								</Stack>
							)}
							{!user?.instie && inquiry.fields['ROFR Start'] && (
								<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
									<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
										ROFR End
									</Typography>
									<Typography variant={'body2'} color="text.primary">
										&nbsp;
									</Typography>
									<Typography variant={'body2'} color="text.primary" flex={isMobile ? 1 : undefined}>
										{onlyDateFormatter(
											new Date(
												new Date(inquiry.fields['ROFR Start']).setDate(
													new Date(inquiry.fields['ROFR Start']).getDate() + 30
												)
											)
										)}
									</Typography>
								</Stack>
							)}
						</Stack>
					</Stack>
					<Divider variant={'fullWidth'} />
					<Stack direction={'column'} spacing={'20px'}>
						<ActivityHeader onClick={() => setActivityCollapsed((prevState) => !prevState)}>
							<Typography variant={'body1'}>Activity</Typography>
							{isActivityCollapsed ? (
								<ExpandMore sx={{ color: defaultTheme.palette.grey['500'] }} />
							) : (
								<ExpandLess sx={{ color: defaultTheme.palette.grey['500'] }} />
							)}
						</ActivityHeader>
						<Collapse in={!isActivityCollapsed}>
							<Stack direction={'column'} spacing={'22px'}>
								<MessageField inquiryId={inquiry.id} />
								{inquiry.activityLogs?.map((activityLog) => (
									<ActivityItem
										key={activityLog.id}
										fromUser={activityLog.fields['From User']}
										dateTime={activityLog.fields['Created At']}
										message={activityLog.fields.Message}
										action={renderActivityAction(activityLog)}
										files={activityLog.fields['Uploads']}
									/>
								))}
								<ActivityItem
									fromUser={inquiry.fields['From User']}
									dateTime={inquiry.fields['Created At']}
									message={
										user?.instie
											? userContext.fields['Compliance Approved']
												? 'Your account manager will soon email you with the next steps regarding your inquiry. Thank you for reaching out.'
												: 'Your account manager will soon email you with the next steps regarding your inquiry. Thank you for reaching out.\n\nMeanwhile, please complete the compliance requirements by following the link below.'
											: "Thank you for your inquiry.\n\nTo proceed, please complete the onboarding forms. If you encounter any challenges or have questions during this process, message us, we're here to help."
									}
									action={
										<Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? '6px' : 1}>
											{user?.instie && !userContext.fields['Compliance Approved'] && (
												<Button
													variant={'outlined'}
													sx={{ width: isMobile ? '100%' : '200px', flexShrink: 0 }}
													size={isMobile ? 'medium' : 'small'}
													color={'secondary'}
													component={RouterLink}
													to={'/settings/compliance'}
												>
													{'Complete Compliance'}
												</Button>
											)}
											{inquiry.fields.InternalStatus !== 'Cancelled' && (
												<Button
													variant={'outlined'}
													size={isMobile ? 'medium' : 'small'}
													color={'greyButton'}
													component={RouterLink}
													to={'/inquiry/' + inquiry.id}
												>
													{`${
														constants.INQUIRY.STATUS_MAP[inquiry.fields.InternalStatus] === 'Onboarding'
															? 'edit inquiry'
															: 'view inquiry'
													}`}
												</Button>
											)}
											{inquiry.fields.InternalStatus !== 'Cancelled' && (
												<Button
													variant={'outlined'}
													size={isMobile ? 'medium' : 'small'}
													color={'error'}
													onClick={() => setCancelInstInquiryDialogOpen(true)}
												>
													{'Cancel inquiry'}
												</Button>
											)}
											{inquiry.fields.InternalStatus === 'Cancelled' && (
												<Button
													variant={'outlined'}
													sx={{ width: user?.instie ? undefined : isMobile ? '100%' : '200px', flexShrink: 0 }}
													size={isMobile ? 'medium' : 'small'}
													color={'greyButton'}
													onClick={enableInquiry}
												>
													{'Re-activate inquiry'}
												</Button>
											)}
										</Stack>
									}
								/>
							</Stack>
						</Collapse>
					</Stack>
				</Stack>
			</CardContent>
			<Dialog open={isCancelInstInquiryDialogOpen} onClose={() => setCancelInstInquiryDialogOpen(false)}>
				<DialogTitle>
					<Stack direction={'row'} alignItems={'center'}>
						<Warning color="error" sx={{ mr: 1 }} />
						Confirm Inquiry Cancellation
					</Stack>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to cancel your inquiry? Remember you can always re-activate it.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setCancelInstInquiryDialogOpen(false)}>Keep Inquiry</Button>
					<Button onClick={cancelInquiry} autoFocus variant={'contained'} color={'error'}>
						Cancel Inquiry
					</Button>
				</DialogActions>
			</Dialog>
		</Card>
	);
};

export default InquiryCard;

import { Box, Button, Card, CardContent, Chip, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'd3';
import { dateFormatter } from '../../../../utils';
import { OpenInNew, Sell } from '@mui/icons-material';

const OfferCard = ({ offer }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Card key={offer.id} sx={{ borderLeft: `2px dotted ${theme.palette.primary.main}` }}>
			<CardContent>
				<Stack spacing={isMobile ? '18px' : '20px'} direction={'column'}>
					<Stack direction={'row'} alignItems={'center'}>
						<Stack direction={'column'} spacing={1} flex={1}>
							<Stack direction={'row'} spacing={1} alignItems={'center'}>
								<Sell color="primary" fontSize="small" />
								<Typography variant={'overline'} color={'primary.main'} lineHeight={'normal'}>
									Offer
								</Typography>
							</Stack>
							<Stack spacing={2} alignItems={'center'} direction={'row'}>
								<Link
									component={RouterLink}
									color={'text.primary'}
									underline={'hover'}
									variant="h5"
									to={`/company/${offer.company?.id}`}
								>
									{offer.company?.name}
								</Link>
								{!isMobile && <Chip label="Applied" variant="contained" color={'success'} size={'small'} />}
							</Stack>
						</Stack>
						{!isMobile && (
							<Button
								size={'small'}
								endIcon={<OpenInNew />}
								variant={'text'}
								color={'primary'}
								component={RouterLink}
								to={'/offer/' + offer.id}
							>
								open details
							</Button>
						)}
					</Stack>
					<Stack spacing={isMobile ? '6px' : '40px'} direction={!isMobile ? 'row' : 'column'}>
						{isMobile && (
							<Stack alignItems={'center'} direction={'row'}>
								<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
									Status
								</Typography>
								<Box flex={isMobile ? 1 : undefined}>
									<Chip label="Applied" variant="contained" color={'success'} size={'small'} />
								</Box>
							</Stack>
						)}

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Applied at
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{dateFormatter(new Date(offer.lastModified))}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Price Per Share (Gross)
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{format('$,.2f')(offer.grossPrice)}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Shares/Options
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{format(',')(offer.shares)}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Block Size (Gross)
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{format('$,.2f')(offer.blockSize)}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Net Calculation
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{format('$,.2f')(offer.netCalculation)}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Structure
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{`${offer.structure?.[0]}, ${offer.layer}`}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Terms
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{`${offer.managementFeesPct}, ${offer.carryTermsPct}`}
							</Typography>
						</Stack>
					</Stack>
					{isMobile && (
						<Button
							size={'large'}
							endIcon={<OpenInNew />}
							variant={'outlined'}
							color={'primary'}
							component={RouterLink}
							to={'/offer/' + offer.id}
						>
							open details
						</Button>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default OfferCard;

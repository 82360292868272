export default (
	<div>
		<h2>Company Approval of a Transaction:</h2>
		<p>
			When engaging in the sale or transfer of company shares, obtaining company approval is a crucial step in the
			process. The company's approval ensures that the transaction aligns with existing agreements, bylaws, and
			regulatory requirements. This step is designed to safeguard the interests of the company and its stakeholders.
		</p>
		<div>
			<h3>Key Steps in Company Approval:</h3>
			<ol>
				<li>
					<b>Submission of Proposal:</b> Shareholders seeking to sell or transfer shares typically submit a formal
					proposal to the company, outlining the details of the intended transaction.
				</li>
				<li>
					<b>Review by the Board:</b> The company's board of directors or a designated committee reviews the proposal to
					assess its compliance with internal policies and legal considerations.
				</li>
				<li>
					<b>Final Approval:</b> Upon completion of the review and any necessary voting processes, the company grants
					final approval for the transaction to proceed.
				</li>
			</ol>
		</div>
		<div>
			<h3>Right of First Refusal:</h3>
			<p>
				The right of first refusal is a contractual provision that grants the company the opportunity to purchase shares
				before they are offered to external parties. This right is often outlined in shareholder agreements or bylaws
				and serves as a protective mechanism for the company.
			</p>
		</div>
		<div>
			<h3>How the Right of First Refusal Works:</h3>
			<ol>
				<li>
					<b>Offer to the Company:</b> When a shareholder intends to sell shares, they must first present the offer to
					the company, specifying the proposed terms.
				</li>
				<li>
					<b>Company's Decision:</b> The company has the option to either accept the offer and purchase the shares on
					the specified terms or decline, allowing the shareholder to proceed with external buyers.
				</li>
				<li>
					<b>Timeframe for Decision:</b> The right of first refusal typically includes a defined timeframe within which
					the company must make a decision. This ensures a timely resolution and clarity for all parties involved.
				</li>
			</ol>
		</div>
	</div>
);

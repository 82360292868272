import { Box, CardMedia, Stack, Typography, useTheme } from '@mui/material';
import { CloseButtonIcon, CloseButtonWrapper, FilePreviewPaper } from './style';
import { shortenFileName } from '../../utils';
import { useMemo } from 'react';

const FilePreview = ({ url, onDelete, fileName = '' }) => {
	const defaultTheme = useTheme();
	const isDeletable = useMemo(() => Boolean(onDelete), [onDelete]);

	const CloseButton = (
		<CloseButtonWrapper onClick={onDelete}>
			<CloseButtonIcon />
		</CloseButtonWrapper>
	);

	return (
		<Box position={'relative'} width={'fit-content'}>
			{isDeletable && CloseButton}
			<FilePreviewPaper $isDeletable={isDeletable} elevation={isDeletable ? undefined : 11}>
				<Stack direction={'column'} alignItems={'center'} spacing={'4px'}>
					<CardMedia
						component="img"
						sx={{ height: 100, width: 100, borderRadius: defaultTheme.shape.borderRadius + 'px' }}
						image={url ?? '/illustrations/dark/FileImageBackup.png'}
						onError={(e) => {
							e.target.src = '/illustrations/dark/FileImageBackup.png';
						}}
					/>
					<Typography textAlign={'center'} variant={'caption'}>
						{shortenFileName(fileName, 20)}
					</Typography>
				</Stack>
			</FilePreviewPaper>
		</Box>
	);
};

export default FilePreview;

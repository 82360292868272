import { Box, Button, Card, CardContent, Chip, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'd3';
import { dateFormatter } from '../../../../utils';
import { useMemo } from 'react';
import { AddShoppingCart, OpenInNew } from '@mui/icons-material';

const BidCard = ({ bid }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const shares = useMemo(() => (bid ? bid.fields.Shares ?? 0 : 0), [bid]);
	const commission = useMemo(() => {
		if (bid) {
			if (bid.fields['Commission per Share']?.[0] && bid.fields['Commission per Share'] > 0) {
				return bid.fields['Commission per Share'][0] * shares;
			} else {
				if (!shares) return 0;
				return bid.fields['Commission Pct']?.[0] * bid.fields['Gross Price']?.[0] * shares;
			}
		}
	}, [bid, shares]);
	const priceNet = useMemo(() => {
		if (bid) {
			if (shares > 0) {
				return shares * bid.fields['Gross Price'] - commission;
			} else {
				return 0;
			}
		}
	}, [bid, shares]);
	const blockSizeGross = useMemo(() => {
		if (bid) {
			return shares * bid.fields['Gross Price']?.[0];
		}
	}, [bid, shares]);
	const netToYou = useMemo(() => {
		if (bid) {
			if (priceNet > 0) {
				return priceNet - bid.fields['Admin Fee'];
			} else {
				return 0;
			}
		}
	}, [bid, priceNet]);

	return (
		<Card key={bid.id} sx={{ borderLeft: `2px dashed ${theme.palette.primary.main}` }}>
			<CardContent>
				<Stack spacing={isMobile ? '18px' : '20px'} direction={'column'}>
					<Stack direction={'row'} alignItems={'center'}>
						<Stack direction={'column'} spacing={1} flex={1}>
							<Stack direction={'row'} spacing={1} alignItems={'center'}>
								<AddShoppingCart color="primary" fontSize="small" />
								<Typography variant={'overline'} color={'primary.main'} lineHeight={'normal'}>
									Bid
								</Typography>
							</Stack>
							<Stack spacing={2} alignItems={'center'} direction={'row'}>
								<Link
									component={RouterLink}
									color={'text.primary'}
									underline={'hover'}
									variant="h5"
									to={`/company/${bid.fields.Company[0]}`}
								>
									{bid.fields['Company Name'][0]}
								</Link>
								{!isMobile && <Chip label="Applied" variant="contained" color={'success'} size={'small'} />}
							</Stack>
						</Stack>
						{!isMobile && (
							<Button
								size={'small'}
								endIcon={<OpenInNew />}
								variant={'text'}
								color={'primary'}
								component={RouterLink}
								to={'/bid/' + bid.id}
							>
								open details
							</Button>
						)}
					</Stack>
					<Stack spacing={isMobile ? '6px' : '40px'} direction={!isMobile ? 'row' : 'column'}>
						{isMobile && (
							<Stack alignItems={'center'} direction={'row'}>
								<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
									Status
								</Typography>
								<Box flex={isMobile ? 1 : undefined}>
									<Chip label="Applied" variant="contained" color={'success'} size={'small'} />
								</Box>
							</Stack>
						)}

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Applied at
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{dateFormatter(new Date(bid.fields['Last Modified']))}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Price Per Share (Gross)
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{format('$,.2f')(bid.fields['Gross Price']?.[0])}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Shares/Options
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{format(',')(bid.fields.Shares)}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Block Size (Gross)
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{format('$,.2f')(blockSizeGross)}
							</Typography>
						</Stack>

						<Stack spacing={'4px'} direction={isMobile ? 'row' : 'column'} sx={{ alignItems: 'end' }}>
							<Typography variant={'body2'} sx={{ color: 'text.secondary' }} flex={isMobile ? 1 : undefined}>
								Net Calculation
							</Typography>
							<Typography variant={'body2'} sx={{ color: 'text.primary' }} flex={isMobile ? 1 : undefined}>
								{format('$,.2f')(netToYou)}
							</Typography>
						</Stack>
					</Stack>
					{isMobile && (
						<Button
							size={'large'}
							endIcon={<OpenInNew />}
							variant={'outlined'}
							color={'primary'}
							component={RouterLink}
							to={'/bid/' + bid.id}
						>
							open details
						</Button>
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default BidCard;

const Loader = () => {
	return (
		<iframe
			title={'loader'}
			src="/loader/index.html"
			style={{
				width: 220,
				height: 220,
				border: 'none',
				overflow: 'visible',
			}}
		></iframe>
	);
};

export default Loader;

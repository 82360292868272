import { Box, Button, IconButton, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { ArrowBackIosNew, Chat, Search } from '@mui/icons-material';
import React, { useState } from 'react';
import { EmptyContainer, HeaderPaper, QuestionContainer, QuestionItem, QuestionListContainer } from './styles';
import questions from '../../../faqQuestions/index';
import { useChatWidgetContext } from '../../../../context';
import { useDebouncedCallback } from 'use-debounce';
import { searchInJSXElement } from '../../../../utils';

const HelpTab = () => {
	const { selectedQuestion, setSelectedQuestion, setSelectedTab } = useChatWidgetContext();
	const [filteredQuestions, setFilteredQuestions] = useState(questions);
	const [inputText, setInputText] = useState('');

	const searchQuestions = useDebouncedCallback(
		(e) => {
			if (e.target.value.trim()) {
				setFilteredQuestions(
					questions.filter((question) => searchInJSXElement(question.questionContent, e.target.value.trim()))
				);
			} else {
				setFilteredQuestions(questions);
			}
		},
		[1000]
	);

	return (
		<>
			<HeaderPaper>
				<Stack direction={'row'} alignItems={'center'}>
					{selectedQuestion && (
						<IconButton onClick={() => setSelectedQuestion(null)} size={'large'}>
							<ArrowBackIosNew sx={{ color: 'text.secondary', fontSize: 20 }} />
						</IconButton>
					)}
					<Typography variant={'body1'} color="text.primary" lineHeight={'44px'} ml={'12px'}>
						FAQ
					</Typography>
				</Stack>
			</HeaderPaper>

			{!selectedQuestion && (
				<>
					<Box p={'20px'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<OutlinedInput
							fullWidth
							placeholder={'Search for help'}
							size={'small'}
							onChange={searchQuestions}
							value={inputText}
							onInput={(e) => setInputText(e.target.value)}
							endAdornment={
								<InputAdornment position="end">
									<Search sx={{ color: 'text.secondary' }} />
								</InputAdornment>
							}
						/>
					</Box>
					{filteredQuestions.length > 0 && (
						<QuestionListContainer>
							{filteredQuestions.map((question) => (
								<QuestionItem key={question.questionId} onClick={() => setSelectedQuestion(question)}>
									<Typography variant={'body1'}>{question.questionTitle}</Typography>
								</QuestionItem>
							))}
						</QuestionListContainer>
					)}
					{filteredQuestions.length === 0 && (
						<EmptyContainer>
							<img src={'/illustrations/dark/notFound.svg'} style={{ height: 152 }} alt="Questions are not found" />
							<Typography>We couldn't find what you searched for</Typography>
							<Button variant="outlined" size="small" startIcon={<Chat />} onClick={() => setSelectedTab('chat')}>
								ask a question
							</Button>
						</EmptyContainer>
					)}
				</>
			)}

			{selectedQuestion && <QuestionContainer id={'faq'}>{selectedQuestion.questionContent}</QuestionContainer>}
		</>
	);
};

export default HelpTab;

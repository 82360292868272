import styled from 'styled-components';
import { Box, Paper, Stack, useTheme } from '@mui/material';

export const HeaderPaper = styled(Paper).attrs({
	elevation: 14,
	square: true,
})(() => {
	return {
		padding: '8px 20px 8px 8px',
		width: '100%',
		zIndex: 1,

		'&.MuiPaper-root': {
			boxShadow: '0px 2px 16px 1px rgba(0, 0, 0, 0.12), 0px 7px 10px 1px rgba(0, 0, 0, 0.14)',
		},
	};
});

export const QuestionListContainer = styled(Stack)(() => {
	return {
		paddingTop: '8px',
		paddingBottom: '8px',
		flexDirection: 'column',
		flexGrow: 1,
		overflowY: 'auto',
	};
});

export const EmptyContainer = styled(Stack).attrs({
	spacing: '12px',
})(() => {
	return {
		flexDirection: 'column',
		flexGrow: 1,
		justifyContent: 'center',
		alignItems: 'center',
		overflowY: 'auto',
	};
});

export const QuestionContainer = styled(Box)(() => {
	return {
		padding: '30px',
		paddingTop: 8,
		flexGrow: 1,
		overflowY: 'auto',
	};
});

export const QuestionItem = styled(Box)(() => {
	const defaultTheme = useTheme();

	return {
		marginLeft: '20px',
		marginRight: '20px',
		paddingTop: '12px',
		paddingBottom: '12px',
		borderBottom: `1px solid ${defaultTheme.palette.divider}`,

		'&:last-child': {
			borderBottom: 'none',
		},

		'&:hover': {
			cursor: 'pointer',
			color: defaultTheme.palette.primary.main,
		},
	};
});

import PageTitle from '../../components/PageTitle';
import {
	Box,
	Button,
	Chip,
	Divider,
	FormLabel,
	Paper,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import { useAudioContext, useAuthContext, useChatWidgetContext } from '../../context';
import React, { useMemo, useState } from 'react';
import { apiClient, blockSizeFormatter, endpoints, onlyDateFormatter } from '../../utils';
import { format } from 'd3';
import { toast } from 'react-toastify';
import { strings } from '../../utils/strings';

const OfferView = () => {
	const { id } = useParams();
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
	const { setChatWidgetOpen } = useChatWidgetContext();
	const { playSendSound: playSendMessageSound } = useAudioContext();

	const { offers, updateOffer } = useAuthContext();
	const offer = useMemo(() => offers.find((offer) => offer.id === id), [id, offers]);
	const defaultShares = useMemo(() => (offer ? offer.shares ?? 0 : 0), [offer]);
	const [shares, setShares] = useState(defaultShares);
	const priceNet = useMemo(() => {
		if (offer) {
			if (shares > 0) {
				return shares * offer.grossPrice - offer.commission;
			} else {
				return 0;
			}
		}
	}, [offer, shares]);
	const blockSizeGross = useMemo(() => {
		if (offer) {
			return shares * offer.grossPrice;
		}
	}, [offer, shares]);
	const netToYou = useMemo(() => {
		if (offer) {
			if (priceNet > 0) {
				return priceNet - offer.adminFee;
			} else {
				return 0;
			}
		}
	}, [offer, priceNet]);

	const netToYouPerShare = useMemo(() => {
		if (offer) {
			if (shares > 0) {
				return priceNet / shares;
			} else {
				return 0;
			}
		}
	}, [offer, priceNet, shares]);

	const isExpired = useMemo(() => {
		if (offer) {
			return new Date().getTime() > new Date(offer.validTo).getTime();
		} else {
			return true;
		}
	}, [offer]);

	const isReadOnly = useMemo(() => {
		if (offer) {
			return offer.answer === 'Applied' || isExpired;
		} else {
			return true;
		}
	}, [offer, isExpired]);

	const onSubmit = async () => {
		const res = await apiClient.post(endpoints.respondOffer, {
			id: id,
			answer: 'Applied',
			shares: shares ?? defaultShares,
		});

		if (res) {
			toast.success(strings.successMessages.offer.answerSaved);
			updateOffer({
				...offer,
				answer: 'Applied',
				shares: shares ?? defaultShares,
			});
		}
	};

	const onNotInterested = async () => {
		const res = await apiClient.post(endpoints.respondOffer, {
			id: id,
			answer: 'Not Interested',
		});

		if (res) {
			toast.success(strings.successMessages.offer.answerSaved);
			updateOffer({
				...offer,
				answer: 'Not Interested',
			});
		}
	};

	const renderStatus = () => {
		if (isExpired && !Boolean(offer?.answer)) {
			return <Chip size={'small'} color={'default'} label={'Expired'} variant={'outlined'} />;
		} else {
			switch (offer?.answer) {
				case 'Not Interested':
					return <Chip size={'small'} color={'default'} label={'Not interested'} />;
				case 'Applied':
					return <Chip size={'small'} color={'success'} label={'Applied'} />;
				default:
					return <Chip size={'small'} color={'primary'} label={'Waiting for answer...'} variant={'outlined'} />;
			}
		}
	};

	const handleSendMessage = () => {
		playSendMessageSound();

		apiClient
			.post(endpoints.sendChatMessage, {
				message: `I'd like to know more about offer #${id}`,
			})
			.then(() => {
				setChatWidgetOpen(true);
			});
	};

	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Home',
						link: '/home',
					},
					{
						title: 'Offer',
					},
				]}
			/>
			<Grid container spacing={3}>
				{!isMobile && <Grid size={1} />}
				<Grid size={isMobile ? 12 : 5}>
					<Paper elevation={1}>
						<Stack direction={'column'}>
							<Box p={isMobile ? 2 : 3} sx={{ borderBottom: `1px dashed ${defaultTheme.palette.divider}` }}>
								<Typography variant={'h4'}>{offer?.company?.name}</Typography>
							</Box>
							<Stack p={isMobile ? 2 : 3} spacing={'18px'} direction={'column'}>
								<Stack spacing={1} direction={'column'}>
									<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
										<Typography variant={'body1'} color={'text.secondary'}>
											Price Per Share (Gross)
										</Typography>
										<Typography variant={'body1'} color={'text.primary'}>
											{format('$,.2f')(offer.grossPrice)}
										</Typography>
									</Stack>
									<Stack direction={'column'} spacing={'4px'}>
										<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
											<FormLabel required={true}>Shares/Options</FormLabel>
											{isReadOnly ? (
												<Typography variant={'body1'} color={'text.primary'}>
													{format(',')(offer?.shares)}
												</Typography>
											) : (
												<TextField
													defaultValue={defaultShares}
													size={isMobile ? 'medium' : 'small'}
													placeholder={defaultShares.toString()}
													type={'number'}
													slotProps={{
														input: {
															step: 1
														}
													}}
													onChange={(e) => {
														setShares(Number(e.target.value));
													}}
												/>
											)}
										</Stack>
									</Stack>
									<Stack direction={'column'} spacing={'4px'}>
										<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
											<Typography variant={'body1'} color={'text.secondary'}>
												Block Size (Gross)
											</Typography>
											<Typography variant={'body1'} color={'text.primary'}>
												{format('$,.2f')(blockSizeGross ? blockSizeGross : 0)}
											</Typography>
										</Stack>
										{offer.minSize && offer.maxSize && (
											<Typography variant="caption" color={'text.secondary'} sx={{ marginTop: 2 }}>
												*The offer has a stated minimum size of min = <b>{blockSizeFormatter(offer.minSize)}</b> and a
												maximum or total size of max = <b>{blockSizeFormatter(offer.maxSize)}</b>. Any other block sizes
												the buyer will review on a case-by-case basis for approval.
											</Typography>
										)}
									</Stack>
								</Stack>

								<Divider />

								<Stack spacing={1} direction={'column'}>
									<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
										<Typography variant={'body1'} color={'text.secondary'}>
											Commission
										</Typography>
										<Typography variant={'body1'} color={'text.primary'}>
											{format('$,.2f')(offer.commission)}
										</Typography>
									</Stack>
									<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
										<Typography variant={'body1'} color={'text.secondary'}>
											Administrative fee
										</Typography>
										<Typography variant={'body1'} color={'text.primary'}>
											{format('$,.2f')(offer?.adminFee)}
										</Typography>
									</Stack>
								</Stack>
								<Divider />
								<Stack direction={'column'} spacing={1}>
									<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
										<Typography variant={'h6'} color={'text.primary'}>
											Net to you
										</Typography>
										<Typography variant={'h6'} color={'primary.main'}>
											{format('$,.2f')(netToYou)}
										</Typography>
									</Stack>
									<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
										<Typography variant={'body1'} color={'text.primary'}>
											Net per share
										</Typography>
										<Typography variant={'body1'} color={'primary.main'}>
											{format('$,.2f')(netToYouPerShare)}
										</Typography>
									</Stack>
									<Typography variant={'caption'} color={'text.secondary'}>
										*The company may charge a transfer fee and or require a third party legal opinion. Please contact
										your Company equity administrator for the most up to date rules, requirements and fees.
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Paper>
				</Grid>
				<Grid size={isMobile ? 12 : 5}>
					<Stack direction={'column'} spacing={3}>
						<Paper elevation={1}>
							<Stack direction={'column'} p={isMobile ? 2 : 3} spacing={isMobile ? 2 : 3}>
								<Stack direction={'column'} spacing={isMobile ? 1 : '12px'}>
									<Stack direction={'row'} spacing={'30px'} alignItems={'center'}>
										<Typography color={'text.secondary'}>Status</Typography>
										{renderStatus()}
									</Stack>
									<Stack direction={'row'} spacing={3}>
										<Typography color={'text.secondary'}>Valid to</Typography>
										<Typography color={'text.secondary'}>{onlyDateFormatter(new Date(offer?.validTo))}</Typography>
									</Stack>
								</Stack>
								<Stack spacing={isMobile ? 1 : '12px'} direction={'column'} sx={{ color: 'text.secondary' }}>
									<Button
										variant={'contained'}
										size={'large'}
										onClick={onSubmit}
										disabled={isReadOnly || (shares ? shares < 1 : defaultShares !== null)}
									>
										{offer.answer === 'Applied' ? "You've applied!" : 'Apply now'}
									</Button>
									<Button
										variant={'outlined'}
										color={'inherit'}
										size={'large'}
										onClick={onNotInterested}
										disabled={isExpired || offer.answer === 'Not Interested'}
									>
										Not Interested
									</Button>
									<Button variant={'outlined'} size={'large'} onClick={handleSendMessage}>
										start a conversation
									</Button>
								</Stack>
							</Stack>
						</Paper>
					</Stack>
					<Stack></Stack>
				</Grid>
				{!isMobile && <Grid size={1} />}
				{!isMobile && <Grid size={1} />}
				{Boolean(offer?.notesToBuyer) && (
					<Grid size={isMobile ? 12 : 10}>
						<Stack
							direction={'column'}
							p={isMobile ? 2 : 3}
							spacing={1}
							sx={{ border: `1px dashed ${defaultTheme.palette.divider}`, borderRadius: '4px' }}
						>
							<Typography>Note</Typography>
							<Typography variant={'body2'} color={'text.secondary'}>
								{offer?.notesToBuyer}
							</Typography>
						</Stack>
					</Grid>
				)}
				{!isMobile && <Grid size={1} />}
			</Grid>
		</>
	);
};

export default OfferView;

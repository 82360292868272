import { Form, Formik } from 'formik';
import { initialValues, schema } from './constants';
import { Alert, Box, Divider, FormLabel, MenuItem, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Select from '../../../../components/inputs/Select';
import { constants } from '../../../../utils/constants';
import { Check, DoneAll } from '@mui/icons-material';
import { apiClient, endpoints } from '../../../../utils';
import { useAuthContext } from '../../../../context';
import FileUploadMultiple from '../../../../components/FormComponents/FileUploadMultiple';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useMemo } from 'react';
import FileUploadInput from '../../../../components/FormComponents/FileUploadInput';
import InputField from '../../../../components/FormComponents/InputField';
import { strings } from '../../../../utils/strings';

const ComplianceTab = () => {
	const { user, reloadUserData } = useAuthContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const customInitialSettings = useMemo(() => {
		if (user) {
			return {
				compliance: user.fields['Compliance Required'],
				corporationType: user.fields['Compliance CorpType'],
				crd: user.fields['Compliance CRD'],
				uploadDocuments: user.fields['Compliance Files'],
			};
		}
		return {};
	}, [user]);
	const isApproved = useMemo(() => {
		return user?.fields['Compliance Approved'] === true;
	}, [user]);

	const handleSubmit = async (values) => {
		if (isApproved) {
			return;
		}
		const id = user?.id;
		const { uploadDocuments } = values;
		const newFiles = [...uploadDocuments].filter((file) => !file.id);
		const uploadedFiles = [...uploadDocuments].filter((file) => file.id);

		for (const uploadedFile of uploadedFiles) {
			const blob = await fetch(uploadedFile.url).then((r) => r.blob());
			const parts = [blob];
			const file = new File(parts, uploadedFile.filename, { ...uploadedFile });
			newFiles.push(file);
		}

		const formData = new FormData();
		newFiles.forEach((file) => {
			formData.append('files', file);
		});

		try {
			try {
				await apiClient.post(
					endpoints.handleUploads
						.replace('{recordId}', id)
						.replace('{object}', 'Users')
						.replace('{prop}', 'Compliance Files'),
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					},
				);
			} catch (err) {
				toast.error(strings.errorMessages.compliance.saveFiles);
			}

			const updatedFields = {};

			if (values.corporationType) {
				updatedFields['Compliance CorpType'] = values.corporationType;
			}

			if (values.crd) {
				updatedFields['Compliance CRD'] = values.crd;
			}

			//check if updatedFields is not empty
			if (Object.keys(updatedFields).length > 0) {
				await apiClient.post(endpoints.updateUserFields, {
					id: user?.id,
					updatedFields,
				});
				reloadUserData();
			}

			toast.success(strings.successMessages.compliance.updated);
		} catch (err) {
			console.log('ComplianceTab.err', err);
			toast.error(strings.errorMessages.compliance.update);
		}
	};

	const renderCheck = (value) => {
		return (
			<Stack spacing={'12px'} direction={'row'} alignItems={'flex-start'}>
				<Check color={'primary'} sx={{ fontSize: 16, marginTop: '3px' }} />
				<Typography variant={'body1'}>{value}</Typography>
			</Stack>
		);
	};

	const renderFileUpload = (errors, touched, isSubmitting, setFieldValue, values) => {
		return (
			<FileUploadMultiple
				name={'uploadDocuments'}
				errors={errors}
				touched={touched}
				disabled={isApproved || isSubmitting}
				setFieldValue={setFieldValue}
				values={values}
			/>
		);
	};

	const renderLLC = (isSubmitting, setFieldValue, values, touched, errors) => {
		return (
			<Stack spacing={4}>
				<Stack spacing={3}>
					<Stack spacing={2}>
						<FormLabel error={touched.uploadDocuments && Boolean(errors.uploadDocuments)}>
							Upload listed documents:
						</FormLabel>
						<Stack spacing={'10px'}>{renderCheck('Certificate of formation')}</Stack>
						<Stack spacing={'10px'}>{renderCheck('Articles of organization')}</Stack>
						<Stack spacing={'10px'}>{renderCheck('Operating agreement')}</Stack>
					</Stack>
					<Stack spacing={'12px'}>
						<Typography variant={'body2'} color={'text.secondary'}>
							For members’ list and authorized signers’ list (if not included in the documents)
						</Typography>
						<Stack spacing={'10px'}>
							{renderCheck(
								'For each member that is a beneficial owner of 20% or more and authorized signer(s) we require their full legal name, DOB and residential address.',
							)}
						</Stack>
						<Stack spacing={'10px'}>
							{renderCheck('For all U.S. individuals that fail KYC or AML a driver’s license or passport is required.')}
						</Stack>
						<Stack spacing={'10px'}>
							{renderCheck('A passport is required for all foreign individuals, as they will automatically fail KYC')}
						</Stack>
					</Stack>
				</Stack>
				{renderFileUpload(errors, touched, isSubmitting, setFieldValue, values)}
			</Stack>
		);
	};

	const renderCorporation = (isSubmitting, setFieldValue, values, touched, errors) => {
		return (
			<Stack spacing={4}>
				<Stack spacing={3}>
					<Stack spacing={2}>
						<FormLabel error={touched.uploadDocuments && Boolean(errors.uploadDocuments)}>
							Upload listed documents:
						</FormLabel>
						<Stack spacing={'10px'}>{renderCheck('By-laws')}</Stack>
						<Stack spacing={'10px'}>{renderCheck('Certificate of formation')}</Stack>
						<Stack spacing={'10px'}>{renderCheck('Articles of incorporation')}</Stack>
					</Stack>
					<Stack spacing={'12px'}>
						<Typography variant={'body2'} color={'text.secondary'}>
							For shareholders’ list and authorized signers’ list (if not included in the documents)
						</Typography>
						<Stack spacing={'10px'}>
							{renderCheck(
								'For each member that is a beneficial owner of 20% or more and authorized signer(s) we require their full legal name, DOB and residential address.',
							)}
						</Stack>
						<Stack spacing={'10px'}>
							{renderCheck('For all U.S. individuals that fail KYC or AML a driver’s license or passport is required.')}
						</Stack>
						<Stack spacing={'10px'}>
							{renderCheck('A passport is required for all foreign individuals, as they will automatically fail KYC')}
						</Stack>
					</Stack>
				</Stack>
				{renderFileUpload(errors, touched, isSubmitting, setFieldValue, values)}
			</Stack>
		);
	};

	const renderPartnership = (isSubmitting, setFieldValue, values, touched, errors) => {
		return (
			<Stack spacing={4}>
				<Stack spacing={3}>
					<Stack spacing={2}>
						<FormLabel error={touched.uploadDocuments && Boolean(errors.uploadDocuments)}>
							Upload listed documents:
						</FormLabel>
						<Stack spacing={'10px'}>{renderCheck('Certificate of formation')}</Stack>
						<Stack spacing={'10px'}>{renderCheck('Partnership agreement')}</Stack>
					</Stack>
					<Stack spacing={'12px'}>
						<Typography variant={'body2'} color={'text.secondary'}>
							For LP list and general partner(s) list (if not included in the documents)
						</Typography>
						<Stack spacing={'10px'}>
							{renderCheck(
								'For each member that is a beneficial owner of 20% or more and authorized signer(s) we require their full legal name, DOB and residential address.',
							)}
						</Stack>
						<Stack spacing={'10px'}>
							{renderCheck('For all U.S. individuals that fail KYC or AML a driver’s license or passport is required.')}
						</Stack>
						<Stack spacing={'10px'}>
							{renderCheck('A passport is required for all foreign individuals, as they will automatically fail KYC')}
						</Stack>
					</Stack>
				</Stack>
				{renderFileUpload(errors, touched, isSubmitting, setFieldValue, values)}
			</Stack>
		);
	};

	const renderTrusts = (isSubmitting, setFieldValue, values, touched, errors) => {
		return (
			<Stack spacing={4}>
				<Stack spacing={3}>
					<Stack spacing={2}>
						<FormLabel error={touched.uploadDocuments && Boolean(errors.uploadDocuments)}>
							Upload listed documents:
						</FormLabel>
						<Stack spacing={'10px'}>{renderCheck('The full trust document or the certificate of trust')}</Stack>
					</Stack>
					<Stack spacing={'12px'}>
						<Typography variant={'body2'} color={'text.secondary'}>
							List of all trustees, including their full legal name, DOB and residential address
						</Typography>
						<Stack spacing={'10px'}>{renderCheck('Trustees info')}</Stack>
					</Stack>
				</Stack>
				{renderFileUpload(errors, touched, isSubmitting, setFieldValue, values)}
			</Stack>
		);
	};

	const renderComplianceYes = (isSubmitting, setFieldValue, values, touched, errors, setTouched, dirty) => {
		return (
			<Stack spacing={isMobile ? 3 : '36px'}>
				<Select
					fullWidth
					name={'corporationType'}
					label={'Select corporation type'}
					required
					disabled={isApproved || isSubmitting}
					showAsterisk={false}
					value={values.corporationType}
					onChange={(e) => {
						setFieldValue('corporationType', e.target.value);
						setFieldValue('uploadDocuments', []);
						setTouched('uploadDocuments', false);
					}}
				>
					{constants.PROFILE.INSTITUTIONAL.CORPORATION_TYPE.map((opt) => (
						<MenuItem value={opt} key={opt}>
							{opt}
						</MenuItem>
					))}
				</Select>
				{values.corporationType && <Divider variant={'fullWidth'} />}
				{values.corporationType === 'LLC' && renderLLC(isSubmitting, setFieldValue, values, touched, errors)}
				{values.corporationType === 'Corporation' &&
					renderCorporation(isSubmitting, setFieldValue, values, touched, errors)}
				{values.corporationType === 'LIMITED PARTNERSHIP/GENERAL PARTNERSHIP' &&
					renderPartnership(isSubmitting, setFieldValue, values, touched, errors)}
				{values.corporationType === 'TRUSTS (REVOCABLE AND IRREVOCABLE)' &&
					renderTrusts(isSubmitting, setFieldValue, values, touched, errors)}
				{values.corporationType && <Divider variant={'fullWidth'} />}
				{values.corporationType && (
					<Stack alignItems={isMobile ? undefined : 'flex-end'}>
						<LoadingButton
							variant='contained'
							type='submit'
							size={'large'}
							loading={isSubmitting}
							disabled={isApproved || !dirty}
							startIcon={<DoneAll />}
							loadingPosition={'start'}
						>
							{isSubmitting ? 'Saving...' : 'Save changes'}
						</LoadingButton>
					</Stack>
				)}
			</Stack>
		);
	};

	const renderComplianceBigBoyLetter = (isSubmitting, setFieldValue, values, touched, errors, dirty) => {
		return (
			<Stack spacing={isMobile ? 3 : '36px'}>
				<FileUploadInput
					label='Please upload the Big Boy Letter'
					id='uploadDocuments'
					name='uploadDocuments'
					disabled={isApproved || isSubmitting}
					setFieldValue={setFieldValue}
					value={values.uploadDocuments}
					showAsterisk={false}
					fullWidth
					error={touched.uploadDocuments && Boolean(errors.uploadDocuments)}
					helperText={touched.uploadDocuments && Boolean(errors.uploadDocuments) ? errors.uploadDocuments : undefined}
				/>
				<Stack alignItems={isMobile ? undefined : 'flex-end'}>
					<LoadingButton
						variant='contained'
						type='submit'
						size={'large'}
						loading={isSubmitting}
						disabled={isApproved || !dirty}
						startIcon={<DoneAll />}
						loadingPosition={'start'}
					>
						{isSubmitting ? 'Saving...' : 'Save changes'}
					</LoadingButton>
				</Stack>
			</Stack>
		);
	};

	const renderComplianceBrokerDealer = (isSubmitting, values, dirty) => {
		return (
			<Stack spacing={isMobile ? 3 : '36px'}>
				<InputField
					label={'Please enter your CRD number'}
					name='crd'
					fullWidth
					disabled={isApproved || isSubmitting}
					required={true}
					showAsterisk={false}
				/>
				<Stack alignItems={isMobile ? undefined : 'flex-end'}>
					<LoadingButton
						variant='contained'
						type='submit'
						size={'large'}
						loading={isSubmitting}
						disabled={isApproved || !dirty}
						startIcon={<DoneAll />}
						loadingPosition={'start'}
					>
						{isSubmitting ? 'Saving...' : 'Save changes'}
					</LoadingButton>
				</Stack>
			</Stack>
		);
	};

	return (
		<Box>
			<Grid container spacing={isMobile ? [3] : 3} mt={isMobile ? 0 : 3}>
				{!isMobile && <Grid size={3} />}
				<Grid size={isMobile ? 12 : 6}>
					{(user?.fields['Compliance Files'] || user?.fields['Compliance CRD']) && (
						<Box mb={isMobile ? 3 : 4}>
							{isApproved ? (
								<Alert severity='success' variant={'outlined'}>
									Your compliance documents were approved by the ShareWell team
								</Alert>
							) : (
								<Alert severity='warning' variant={'outlined'}>
									Your compliance documents are still under review
								</Alert>
							)}
						</Box>
					)}
					<Formik
						initialValues={{ ...initialValues, ...customInitialSettings }}
						onSubmit={handleSubmit}
						validationSchema={schema}
						validateOnBlur={true}
					>
						{({ isSubmitting, dirty, setFieldValue, values, touched, errors, setTouched }) => (
							<Form autoComplete='off' style={{ width: '100%' }}>
								{user?.fields['Compliance Required'] === 'No (Big Boy Letter)' &&
									renderComplianceBigBoyLetter(isSubmitting, setFieldValue, values, touched, errors, dirty)}
								{user?.fields['Compliance Required'] === 'No (Broker Dealer)' &&
									renderComplianceBrokerDealer(isSubmitting, values, dirty)}
								{user?.fields['Compliance Required'] === 'Yes' &&
									renderComplianceYes(isSubmitting, setFieldValue, values, touched, errors, setTouched, dirty)}
							</Form>
						)}
					</Formik>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ComplianceTab;

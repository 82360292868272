import { Card, CardContent, CardHeader, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useData } from '../../../../useData';
import { onlyDateFormatter } from '../../../../utils';
import React from 'react';
import { format } from 'd3';

const LastUpdates = ({ loading }) => {
	const { companyId } = useParams();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { data } = useData({ companyId });

	const pricingDateIcon = isMobile ? (
		<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.15"
				d="M47.8041 0L5.81055 25.5276L5.81701 60L47.8105 34.4724L47.8041 0Z"
				fill="url(#paint0_linear_2662_45358)"
			/>
			<path opacity="0.25" d="M54.502 5.07031L40.6641 13.0566V16.889L54.502 8.90274V5.07031Z" fill="#CE93D8" />
			<path opacity="0.15" d="M44.266 19.6436L13.5488 37.3799V38.6903L44.2742 20.954L44.266 19.6436Z" fill="white" />
			<path opacity="0.15" d="M44.2736 22.2822L17.7598 37.5872V38.8977L44.2654 23.6009L44.2736 22.2822Z" fill="white" />
			<path
				opacity="0.2"
				d="M47.9257 0L5.89258 24.242V28.6814L47.9192 4.43941L47.9257 0Z"
				fill="url(#paint1_linear_2662_45358)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2662_45358"
					x1="19.724"
					y1="17.0684"
					x2="30.434"
					y2="44.9785"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2662_45358"
					x1="37.4966"
					y1="-3.57699"
					x2="21.5576"
					y2="23.4444"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	) : (
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.15"
				d="M63.9914 0L8 34.0368L8.00862 80L64 45.9632L63.9914 0Z"
				fill="url(#paint0_linear_2657_34405)"
			/>
			<path opacity="0.25" d="M72.9233 6.75977L54.4727 17.4082V22.5181L72.9233 11.8697V6.75977Z" fill="#CE93D8" />
			<path opacity="0.15" d="M59.2746 26.1924L18.3184 49.8408V51.5881L59.2856 27.9396L59.2746 26.1924Z" fill="white" />
			<path opacity="0.15" d="M59.2854 29.71L23.9336 50.1166V51.8639L59.2744 31.4682L59.2854 29.71Z" fill="white" />
			<path
				opacity="0.2"
				d="M64.1535 0L8.10938 32.3227V38.2419L64.1449 5.91921L64.1535 0Z"
				fill="url(#paint1_linear_2657_34405)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2657_34405"
					x1="26.5512"
					y1="22.7579"
					x2="40.8313"
					y2="59.9713"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2657_34405"
					x1="50.2481"
					y1="-4.76932"
					x2="28.996"
					y2="31.2592"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);

	const usersIcon = isMobile ? (
		<svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.5"
				d="M23.9623 26.7137C30.4528 23.1345 35.821 26.4839 36.7706 34.198C36.9355 35.5392 35.8602 37.3029 34.5574 38.0217L13.375 49.6928C12.0722 50.4116 10.997 49.8336 11.1539 48.3145C12.1036 39.5556 17.4718 30.2854 23.9623 26.7137Z"
				fill="url(#paint0_linear_2662_45370)"
			/>
			<path
				opacity="0.5"
				d="M16.7285 16.6881C16.7285 22.0161 19.9934 24.5356 24.0195 22.3125C28.0457 20.0968 31.3027 13.976 31.3027 8.65544C31.3027 3.32749 28.0378 0.808014 24.0117 3.03108C19.9855 5.24674 16.7285 11.3676 16.7285 16.6881Z"
				fill="url(#paint1_linear_2662_45370)"
			/>
			<path
				opacity="0.07"
				d="M20.8236 24.492C27.3141 20.9128 32.6823 24.2623 33.632 31.9763C33.7968 33.3176 32.7216 35.0812 31.4188 35.8L10.2363 47.4711C8.93351 48.1899 7.8583 47.6119 8.01527 46.0928C8.96491 37.3339 14.3331 28.0637 20.8236 24.492Z"
				fill="url(#paint2_linear_2662_45370)"
			/>
			<path
				opacity="0.07"
				d="M13.5879 14.4655C13.5879 19.7934 16.8528 22.3129 20.8789 20.0898C24.8972 17.8742 28.1621 11.7607 28.1621 6.43279C28.1621 1.10483 24.8972 -1.41464 20.8711 0.808425C16.8449 3.02408 13.5879 9.14493 13.5879 14.4655Z"
				fill="url(#paint3_linear_2662_45370)"
			/>
			<path
				opacity="0.5"
				d="M40.6071 30.4926C46.5461 27.7088 51.4582 30.3139 52.3271 36.3137C52.4779 37.3569 51.4941 38.7286 50.302 39.2877L30.9194 48.3652C29.7272 48.9243 28.7434 48.4747 28.887 47.2932C29.756 40.4807 34.668 33.2706 40.6071 30.4926Z"
				fill="url(#paint4_linear_2662_45370)"
			/>
			<path
				opacity="0.5"
				d="M33.9844 22.6972C33.9844 26.8412 36.9718 28.8008 40.6559 27.0717C44.34 25.3484 47.3202 20.5878 47.3202 16.4496C47.3202 12.3056 44.3328 10.346 40.6487 12.0751C36.9647 13.7984 33.9844 18.559 33.9844 22.6972Z"
				fill="url(#paint5_linear_2662_45370)"
			/>
			<path
				opacity="0.07"
				d="M37.7321 28.765C43.6711 25.9813 48.5832 28.5864 49.4521 34.5862C49.6029 35.6294 48.6191 37.0011 47.427 37.5601L28.0444 46.6377C26.8522 47.1967 25.8684 46.7472 26.012 45.5656C26.881 38.7532 31.793 31.543 37.7321 28.765Z"
				fill="url(#paint6_linear_2662_45370)"
			/>
			<path
				opacity="0.07"
				d="M31.1113 20.9667C31.1113 25.1107 34.0988 27.0703 37.7829 25.3412C41.4597 23.618 44.4472 18.8631 44.4472 14.7191C44.4472 10.5751 41.4597 8.61554 37.7757 10.3446C34.0916 12.0679 31.1113 16.8285 31.1113 20.9667Z"
				fill="url(#paint7_linear_2662_45370)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2662_45370"
					x1="-2.81973"
					y1="20.8741"
					x2="22.4751"
					y2="40.4167"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2662_45370"
					x1="10.6183"
					y1="3.44428"
					x2="35.9131"
					y2="22.9868"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_2662_45370"
					x1="-5.95836"
					y1="18.6525"
					x2="19.3364"
					y2="38.195"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_2662_45370"
					x1="7.47765"
					y1="1.22162"
					x2="32.7725"
					y2="20.7641"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_2662_45370"
					x1="16.1007"
					y1="25.9507"
					x2="36.3405"
					y2="44.3473"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_2662_45370"
					x1="28.3933"
					y1="12.3964"
					x2="48.6332"
					y2="30.793"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_2662_45370"
					x1="13.2257"
					y1="24.2232"
					x2="33.4655"
					y2="42.6198"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_2662_45370"
					x1="25.5203"
					y1="10.666"
					x2="45.7601"
					y2="29.0626"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	) : (
		<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.5"
				d="M22.3198 42.8027C31.3955 37.7368 38.9018 42.4775 40.2297 53.3959C40.4601 55.2943 38.9567 57.7905 37.1349 58.8079L7.5157 75.3271C5.694 76.3445 4.19054 75.5264 4.41002 73.3763C5.73789 60.979 13.2442 47.8581 22.3198 42.8027Z"
				fill="url(#paint0_linear_2657_34432)"
			/>
			<path
				opacity="0.5"
				d="M12.2031 28.612C12.2031 36.1532 16.7684 39.7192 22.3981 36.5727C28.0279 33.4367 32.5821 24.7733 32.5821 17.2426C32.5821 9.70147 28.0169 6.13542 22.3871 9.28193C16.7574 12.418 12.2031 21.0814 12.2031 28.612Z"
				fill="url(#paint1_linear_2657_34432)"
			/>
			<path
				opacity="0.07"
				d="M17.9312 39.6562C27.0068 34.5903 34.5131 39.331 35.841 50.2494C36.0714 52.1478 34.568 54.6441 32.7463 55.6614L3.12703 72.1806C1.30532 73.198 -0.198135 72.3799 0.0213476 70.2298C1.34922 57.8325 8.85554 44.7116 17.9312 39.6562Z"
				fill="url(#paint2_linear_2657_34432)"
			/>
			<path
				opacity="0.07"
				d="M7.8125 25.4655C7.8125 33.0067 12.3777 36.5727 18.0075 33.4262C23.6263 30.2902 28.1915 21.6373 28.1915 14.0961C28.1915 6.55498 23.6263 2.98894 17.9965 6.13545C12.3668 9.27148 7.8125 17.9349 7.8125 25.4655Z"
				fill="url(#paint3_linear_2657_34432)"
			/>
			<path
				opacity="0.5"
				d="M45.5912 48.1493C53.8957 44.2092 60.7642 47.8965 61.9793 56.3886C62.1901 57.8651 60.8144 59.8066 59.1475 60.5979L32.0449 73.4461C30.378 74.2374 29.0023 73.6011 29.2031 71.9288C30.4182 62.2865 37.2867 52.0813 45.5912 48.1493Z"
				fill="url(#paint4_linear_2657_34432)"
			/>
			<path
				opacity="0.5"
				d="M36.332 37.1159C36.332 42.9812 40.5094 45.7548 45.6608 43.3075C50.8122 40.8684 54.9795 34.1302 54.9795 28.273C54.9795 22.4077 50.8021 19.6341 45.6507 22.0814C40.4993 24.5205 36.332 31.2587 36.332 37.1159Z"
				fill="url(#paint5_linear_2657_34432)"
			/>
			<path
				opacity="0.07"
				d="M41.5736 45.705C49.8781 41.7649 56.7466 45.4521 57.9617 53.9442C58.1726 55.4207 56.7968 57.3623 55.1299 58.1536L28.0274 71.0018C26.3604 71.7931 24.9847 71.1568 25.1855 69.4845C26.4006 59.8422 33.2691 49.637 41.5736 45.705Z"
				fill="url(#paint6_linear_2657_34432)"
			/>
			<path
				opacity="0.07"
				d="M32.3164 34.6667C32.3164 40.532 36.4938 43.3056 41.6451 40.8583C46.7865 38.4192 50.9639 31.6891 50.9639 25.8238C50.9639 19.9585 46.7865 17.1849 41.6351 19.6322C36.4837 22.0713 32.3164 28.8095 32.3164 34.6667Z"
				fill="url(#paint7_linear_2657_34432)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2657_34432"
					x1="-15.1293"
					y1="34.5374"
					x2="20.5607"
					y2="61.778"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2657_34432"
					x1="3.65925"
					y1="9.86678"
					x2="39.3492"
					y2="37.1073"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_2657_34432"
					x1="-19.5179"
					y1="31.391"
					x2="16.172"
					y2="58.6315"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_2657_34432"
					x1="-0.731404"
					y1="6.72028"
					x2="34.9585"
					y2="33.9608"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_2657_34432"
					x1="11.3241"
					y1="41.7209"
					x2="39.9362"
					y2="67.413"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_2657_34432"
					x1="28.5141"
					y1="22.5362"
					x2="57.1262"
					y2="48.2284"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_2657_34432"
					x1="7.30653"
					y1="39.2766"
					x2="35.9186"
					y2="64.9687"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_2657_34432"
					x1="24.4985"
					y1="20.087"
					x2="53.1106"
					y2="45.7792"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);

	const priceIcon = isMobile ? (
		<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.11"
				d="M11.0001 33.2985C11.0001 45.5567 18.1799 51.3606 27.0108 46.2572C35.8668 41.1538 43.0216 27.0693 43.0216 14.8111C43.0216 2.55289 35.8417 -3.251 27.0108 1.85242C18.1549 6.95584 10.975 21.0403 11.0001 33.2985Z"
				fill="url(#paint0_linear_2662_45386)"
			/>
			<path
				opacity="0.4"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.2227 37.1891C17.2477 49.4474 24.4025 55.2513 33.2584 50.1478C42.0893 45.0444 49.2692 30.96 49.2441 18.7017C49.2441 6.44351 42.0893 0.639621 33.2334 5.74305C24.4025 10.8465 17.2227 24.9309 17.2227 37.1891ZM33.4271 22.8888L41.2607 18.4056V15.7904L36.0913 18.7488V16.1107L33.6789 17.3372V19.9543C33.0469 20.3423 31.8186 21.429 31.0611 22.5152C30.2062 23.7343 29.5899 25.268 29.5899 26.9394C29.5899 28.7877 30.067 29.8299 30.9617 30.1445C31.8166 30.4198 32.8108 29.9478 33.4271 29.5939L36.648 27.7456L36.6679 27.7259C36.9462 27.5686 37.3837 27.4113 37.7216 27.5096C38 27.5883 38.338 27.8832 38.338 28.9057C38.338 29.9282 38.0199 30.5574 37.7415 30.931C37.4035 31.4029 36.9661 31.7372 36.6878 31.8748L36.648 31.8945L26.7865 37.5182V40.1334L33.6789 36.1927V38.5318L36.0913 36.9303V34.8135L36.5884 34.5294C37.2644 34.1754 38.2386 33.4479 39.0736 32.2878C39.9882 31.0097 40.6244 29.3973 40.6244 27.5883C40.6244 25.7989 39.9882 24.8747 39.0935 24.6191C38.2585 24.3832 37.2842 24.7371 36.6082 25.1304L33.4072 26.959C32.9499 27.2147 32.5921 27.3326 32.3734 27.254C32.1944 27.195 31.8763 26.9394 31.8763 25.6219C31.8763 24.7568 32.1546 24.2062 32.433 23.8129C32.7511 23.3803 33.1686 23.0264 33.4271 22.8888Z"
				fill="url(#paint1_linear_2662_45386)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2662_45386"
					x1="23.7936"
					y1="5.7276"
					x2="31.0347"
					y2="46.9628"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2662_45386"
					x1="33.2334"
					y1="3.89063"
					x2="33.2334"
					y2="52.0003"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" stopOpacity="0.71" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	) : (
		<svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				opacity="0.11"
				d="M9.17962e-05 53.8973C9.17962e-05 71.162 10.1123 79.3363 22.5499 72.1486C35.0228 64.9608 45.0998 45.124 45.0998 27.8593C45.0998 10.5946 34.9876 2.42025 22.5499 9.60801C10.0771 16.7958 -0.0351423 36.6326 9.17962e-05 53.8973Z"
				fill="url(#paint0_linear_2657_34560)"
			/>
			<path
				opacity="0.4"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.76758 59.3768C8.80281 76.6415 18.8798 84.8158 31.3526 77.628C43.7903 70.4403 53.9025 50.6035 53.8672 33.3388C53.8672 16.074 43.7903 7.89974 31.3174 15.0875C18.8798 22.2753 8.76758 42.1121 8.76758 59.3768ZM31.5916 39.2358L42.6245 32.9216V29.2383L35.3439 33.405V29.6894L31.9463 31.4168V35.1028C31.0561 35.6493 29.3261 37.1798 28.2593 38.7096C27.0552 40.4266 26.1871 42.5868 26.1871 44.9408C26.1871 47.544 26.8592 49.0118 28.1193 49.4549C29.3234 49.8426 30.7235 49.1779 31.5916 48.6794L36.128 46.0762L36.156 46.0485C36.548 45.827 37.1641 45.6054 37.6401 45.7439C38.0321 45.8547 38.5082 46.2701 38.5082 47.7102C38.5082 49.1502 38.0601 50.0364 37.6681 50.5626C37.1921 51.2273 36.576 51.6981 36.184 51.8919L36.128 51.9196L22.2388 59.8401V63.5234L31.9463 57.9734V61.2678L35.3439 59.0122V56.0309L36.044 55.6306C36.996 55.1321 38.3682 54.1075 39.5443 52.4735C40.8324 50.6734 41.7285 48.4025 41.7285 45.8547C41.7285 43.3345 40.8324 42.0329 39.5723 41.6729C38.3962 41.3405 37.024 41.839 36.072 42.3929L31.5636 44.9685C30.9195 45.3285 30.4155 45.4946 30.1075 45.3839C29.8554 45.3008 29.4074 44.9408 29.4074 43.0853C29.4074 41.8667 29.7994 41.0913 30.1915 40.5374C30.6395 39.9282 31.2275 39.4297 31.5916 39.2358Z"
				fill="url(#paint1_linear_2657_34560)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_2657_34560"
					x1="18.0187"
					y1="15.0659"
					x2="28.2172"
					y2="73.1423"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_2657_34560"
					x1="31.3175"
					y1="12.4785"
					x2="31.3175"
					y2="80.237"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#CE93D8" stopOpacity="0.71" />
					<stop offset="1" stopColor="#CE93D8" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);

	const renderInfoItem = (icon, title, value) => {
		return (
			<Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 1 : 4} alignItems={'center'}>
				{icon}
				<Stack direction={'column'} spacing={'2px'} alignItems={isMobile ? 'center' : undefined}>
					<Typography variant={'body2'} color={'text.secondary'}>
						{title}
					</Typography>
					<Typography variant={'h5'}>{value}</Typography>
				</Stack>
			</Stack>
		);
	};

	if (loading) {
		return <Skeleton variant="rectangular" height={409} />;
	}

	return (
		<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
			<CardHeader title={'Last updates'} titleTypographyProps={{ variant: 'h6' }} />
			<CardContent sx={{ height: '100%' }}>
				<Stack
					ml={isMobile ? undefined : 3}
					direction={isMobile ? 'row' : 'column'}
					justifyContent={isMobile ? 'center' : 'space-between'}
					height={isMobile ? undefined : '100%'}
					flexWrap={isMobile ? 'wrap' : undefined}
					spacing={isMobile ? 4 : undefined}
					useFlexGap={true}
				>
					{renderInfoItem(
						pricingDateIcon,
						'Last pricing date',
						data?.overviewData?.latestPriceDate ? onlyDateFormatter(data.overviewData?.latestPriceDate) : '-'
					)}
					{renderInfoItem(
						usersIcon,
						'Number of users',
						data?.overviewData?.activity ? data?.overviewData?.activity + 12 : 3 + data.overviewData?.name?.length
					)}
					{renderInfoItem(
						priceIcon,
						'Last price',
						data?.priceData ? format('$,.2f')(data?.priceData[data?.priceData.length - 1]?.market) : '-'
					)}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default LastUpdates;

import styled from 'styled-components';
import { Link } from '@mui/material';
import InputField from '../../../../components/FormComponents/InputField';

export const LinkTypography = styled(Link).attrs((props) => ({
	variant: 'body2',
}))`
	word-wrap: break-word;
`;

export const StyledTextArea = styled(InputField).attrs(({ rows }) => ({
	rows: rows ?? 8,
	multiline: true,
	fullWidth: true,
	size: 'small',
	inputProps: {
		style: { fontSize: 14 },
	},
}))``;

export const StyledInput = styled(InputField).attrs({
	size: 'small',
	inputProps: {
		style: { fontSize: 14 },
	},
})``;

import CompanySlide from '../../components/CompanySlide';
import FeatureCard from '../../components/FeatureCard';

export const getSlides = (isSignup, isSmall, isVideoAllowed = true) => [
	<CompanySlide isCompact={isSignup} cardAmount={isSmall ? 1 : undefined} />,
	<FeatureCard
		title="Built-in calculator"
		content="Quickly calculate transaction block sizes based on the latest estimated trading data."
		media={`/features/Calculator.${isVideoAllowed ? 'mov' : 'png'}`}
	/>,
	<FeatureCard
		title="Access to Market and Company Datasets"
		content="Explore the latest proprietary trading data."
		media="/img/Trends.png"
	/>,
	<FeatureCard
		title="Comprehensive estimated price and valuation historical"
		content="Analyze historical price movements."
		media={`/features/Trading.${isVideoAllowed ? 'mov' : 'png'}`}
	/>,
	<FeatureCard
		title="Stay on top of the latest news and trends in the secondary market"
		content="Keep track of funding rounds and other relevant events."
		media="/img/Rounds.png"
	/>,
];

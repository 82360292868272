import { format, timeFormat, timeParse } from 'd3';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { randomUniform } from 'd3-random';

export * from './apiCalls';
export * from './apiClient';
export * from './endpoints';
export * from './storage';

export const dataMissingLabel = 'Coming soon';

export const formatValuation = (s, digits = 0) => {
	return typeof s === 'number' ? format(`.${digits}s`)(s).replace(/G/, 'B') : dataMissingLabel;
};

export const dateFormatter = timeFormat('%d %b %Y, %H:%M');
export const onlyDateFormatter = timeFormat('%d %b %Y');
export const dayMonthFormatter = timeFormat('%b %d');
export const shortDateFormatter = timeFormat('%x, %I:%M %p');
export const timeFormatter = timeFormat('%I:%M %p');
export const airtableDateParse = timeParse('%Y-%m-%d');
export const inputDateFormatter = timeFormat('%Y-%m-%d');
export const blockSizeFormatter = format('$,.0f');

export const hoursAgo = (date) => {
	const diff = new Date() - date;
	const hours = Math.floor(diff / 1000 / 60 / 60);

	//if the difference is less than an hour, show minutes
	if (hours < 1) {
		const minutes = Math.floor(diff / 1000 / 60);
		return `${minutes}m ago`;
	}

	return `${hours}h ago`;
};

export const getDefaultErrorMessage = (err, msg) => {
	const responseText = msg ?? err.response?.data ?? err.message ?? "ask Veli what's up";
	toast.error(`Error: ${responseText}`);
};

export const getSystemErrorMessage = (err, msg) => {
	const responseText = msg ?? err.response?.data ?? err.message ?? "ask Veli what's up";
	console.error(`Error: ${responseText}`);
};

export const getShortUserType = (userType) => {
	if (!userType) return '';
	return userType === 'An Individual Shareholder' ? 'IS' : userType === 'An Accredited Investor' ? 'AI' : 'IF';
};

export const getShortUserStage = (userStage) => {
	if (!userStage) return '';
	return userStage === 'Retail' ? 'R' : 'I';
};

export const getShortUserStageWithLevel = (userStage) => {
	if (!userStage) return '';
	return userStage === 'Retail' ? 'R' : userStage === 'Inst1' ? 'I1' : userStage === 'Inst2' ? 'I2' : 'I3';
};

export const getShortTriad = (triad) => {
	if (!triad) return '';

	return triad.map((item) => item.substring(0, 2)).join(',');
};

export const getCorrectStringFieldFromAirtableObject = (obj, fieldName, isFullString = true) => {
	if (obj && obj.fields && obj.fields[fieldName]) {
		const fieldValue = obj.fields[fieldName];

		if (typeof fieldValue === 'string' && fieldValue.length > 0) {
			if (isFullString) {
				return fieldValue;
			} else {
				return fieldValue[0];
			}
		}

		if (_.isArray(fieldValue) && fieldValue.length > 0 && fieldValue[0]) {
			if (isFullString) {
				return fieldValue[0];
			} else {
				return fieldValue[0][0];
			}
		}
	}

	return '';
};

export const convertNumericalQuantityToNumber = (numericalQuantity, value) => {
	switch (numericalQuantity) {
		case 'Thousands':
			return value * 1000;
		case 'Million':
			return value * 1000000;
		case 'Billion':
			return value * 1000000000;
		default:
			return value;
	}
};

export const convertNumberToNumericalQuantity = (numericalQuantity, value) => {
	switch (numericalQuantity) {
		case 'Thousands':
			return value / 1000;
		case 'Million':
			return value / 1000000;
		case 'Billion':
			return value / 1000000000000;
		default:
			return value;
	}
};

export const isValidNumber = (n) => !isNaN(parseFloat(n)) && isFinite(n);

export const formatUrl = (url) => {
	try {
		const parsedUrl = new URL(url);

		if (parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:') {
			return url;
		} else {
			return 'https://' + url;
		}
	} catch (error) {
		return url;
	}
};

export const beautifyUrl = (url) => {
	try {
		const parsedUrl = new URL(url);

		const beautifiedHost = parsedUrl.host.startsWith('www.') ? parsedUrl.host : 'www.' + parsedUrl.host;
		const beautifiedPath = parsedUrl.pathname.endsWith('/') ? parsedUrl.pathname.slice(0, -1) : parsedUrl.pathname;

		return beautifiedHost + beautifiedPath + parsedUrl.search + parsedUrl.hash;
	} catch (error) {
		return url;
	}
};

export const shortenFileName = (filename, maxChars = 10) => {
	if (filename.length <= maxChars) {
		return filename;
	}

	// Extract the extension (resolution) from the filename
	const dotIndex = filename.lastIndexOf('.');
	const extension = dotIndex !== -1 ? filename.substring(dotIndex) : '';

	// Calculate the number of characters to keep from the beginning of the filename
	const prefixChars = Math.floor((maxChars - 4) / 2);

	// Extract the characters from the beginning of the filename
	const prefix = filename.substring(0, prefixChars);

	// If there's no extension, include the last character in the shortened version
	const lastCharacter = dotIndex === -1 ? filename.charAt(filename.length - 1) : filename.charAt(dotIndex - 1);

	// Create the shortened filename with 3 dots in the middle and include the last character or extension
	return `${prefix}...${lastCharacter}${extension}`;
};

export const shortenString = (text = '', maxChars = 10) => {
	if (text.length <= maxChars) {
		return text;
	}

	return `${text.substring(0, maxChars)}...`;
};

export const extractTextFromJSX = (element) => {
	if (typeof element === 'string') {
		return element;
	}
	if (Array.isArray(element)) {
		return element.map(extractTextFromJSX).join('');
	}
	if (element.props && element.props.children) {
		return extractTextFromJSX(element.props.children);
	}
	return '';
};

export const searchInJSXElement = (jsxElement, searchString) => {
	// Convert JSX element to text content
	const elementText = extractTextFromJSX(jsxElement);

	// Make search case insensitive by converting both strings to lowercase
	const searchStringLower = searchString.toLowerCase();
	const elementTextLower = elementText.toLowerCase();

	// Search for the lowercase search string
	return elementTextLower.includes(searchStringLower);
};

export const getNestedFormValue = (obj, path) => {
	return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const compareStringArrays = (array1, array2) => {
	// Check if the lengths are the same
	if (array1.length !== array2.length) {
		return false;
	}

	// Sort both arrays
	const sortedArray1 = array1.slice().sort();
	const sortedArray2 = array2.slice().sort();

	// Compare each element in the sorted arrays
	for (let i = 0; i < sortedArray1.length; i++) {
		if (sortedArray1[i] !== sortedArray2[i]) {
			return false;
		}
	}

	return true;
};

export const generateUniqueKey = () => `id_${randomUniform(1, 100000)()}`;

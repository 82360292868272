import PageTitle from '../../components/PageTitle';
import ThankYouBlock from '../../components/ThankYouBlock';
import Grid from '@mui/material/Grid2';

const MessageSuccessView = () => {
	return (
		<>
			<PageTitle
				breadcrumbs={[
					{
						title: 'Admin',
						link: '/admin',
					},
					{
						title: 'Message',
					},
				]}
			/>
			<Grid size={6} marginTop={'60px'} marginBottom={'60px'}>
				<ThankYouBlock
					title={'Message(s) sent successfully'}
					description={'Put a penny in the jar for every message sent!'}
					buttonText={'home'}
					buttonLink={'/'}
					iconName={'message'}
				/>
			</Grid>
		</>
	);
};

export default MessageSuccessView;

import { Backdrop, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Loader from '../Loader';

const LoadingIndicator = ({ isVisible }) => {
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	return (
		<Backdrop
			sx={{ background: defaultTheme.palette.background.default, zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={isVisible}
			transitionDuration={{ exit: 700 }}
		>
			<Stack direction={'column'} alignItems={isMobile ? 'center' : 'flex-end'}>
				<Loader />
				<Typography variant="body1" sx={{ color: defaultTheme.palette.text.primary }}>
					Charging the unicorn...
				</Typography>
			</Stack>
		</Backdrop>
	);
};

export default LoadingIndicator;

const getJWT = async () => {
	let token = null;

	try {
		token = localStorage.getItem('JWT');
	} catch (e) {
		console.log('getJWT.e', e);
	}

	return token;
};

const setJWT = async (token) => {
	try {
		await localStorage.setItem('JWT', token);
	} catch (e) {
		console.log('getJWT.e', e);
	}
};

const deleteJWT = async () => {
	try {
		await localStorage.removeItem('JWT');
	} catch (e) {
		console.log('deleteJWT.e', e);
	}
};

export { getJWT, setJWT, deleteJWT };

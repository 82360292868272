import * as Yup from 'yup';

export const initialValues = {
	securities: '',
	securitiesOptions: '',
	transferability: '',
	transactionTimeline: '',
	securitiesMixSpecify: '',
	// securitiesExercisedOption: '',
	securitiesUnexercisedOption: '',
};

export const schema = Yup.object({
	securities: Yup.string().required('Required'),
	transferability: Yup.string().required('Required'),
	transactionTimeline: Yup.string().required('Required'),
	securitiesMixSpecify: Yup.string().when('securities', {
		is: (securities) => securities === 'A mix',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	securitiesOptions: Yup.string().when('securities', {
		is: (securities) => securities === 'Options',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
	// securitiesExercisedOption: Yup.string().when('securities', {
	// 	is: (securities) => securities === 'Exercised options',
	// 	then: (schema) => schema.required('Required'),
	// 	otherwise: (schema) => schema.optional(),
	// }),
	securitiesUnexercisedOption: Yup.string().when('securities', {
		is: (securities) => securities === 'Unexercised option',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.optional(),
	}),
});

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';

export const NotFoundContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding-top: 48px;

	@media (max-width: 600px) {
		padding-top: 24px;
	}
`;

export const Puller = styled('div')(({ theme }) => ({
	width: 30,
	height: 6,
	backgroundColor: grey[300],
	borderRadius: 3,
	position: 'absolute',
	top: 8,
	left: 'calc(50% - 15px)',
	...theme.applyStyles('dark', {
		backgroundColor: theme.palette.background.default,
	}),
}));

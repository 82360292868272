import { FormControl, InputLabel, Select as MUISelect } from '@mui/material';

const Select = ({ showAsterisk = true, label, fullWidth, required, helperText, size, ...props }) => {
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 500,
			},
		},
	};

	return (
		<FormControl fullWidth={fullWidth} required={required} size={size}>
			{label && (
				<InputLabel required={showAsterisk && required} id={label}>
					{label}
				</InputLabel>
			)}
			<MUISelect
				labelId={label}
				label={label}
				required={required}
				MenuProps={MenuProps}
				variant={'outlined'}
				{...props}
			/>
		</FormControl>
	);
};

export default Select;

export default (
	<div>
		<h2>Direct Share Acquisition with ShareWell</h2>
		<div>
			<h3>Direct Share Ownership and Cap Table Access:</h3>
			<p>
				Investors participating in a <b>direct share acquisition</b> through ShareWell gain direct ownership of shares
				in a privately held company. This avenue provides investors with a tangible stake in the company and the
				associated benefits of direct ownership.
			</p>
			<h4>Key Features of Direct Share Acquisition:</h4>
			<ol>
				<li>
					<b>ShareWell Facilitated Transaction:</b>
					<ul>
						<li>
							ShareWell acts as a facilitator, connecting investors with private companies offering direct share
							opportunities. This brokered transaction ensures a streamlined and secure process for investors.
						</li>
					</ul>
				</li>
				<li>
					<b>Direct Share Ownership:</b>
					<ul>
						<li>
							Investors acquire direct ownership of shares in the target company through ShareWell. This direct stake
							provides them with rights and privileges associated with ownership, including potential dividends and
							voting rights.
						</li>
					</ul>
				</li>
				<li>
					<b>Cap Table Access:</b>
					<ul>
						<li>
							Cap table access is granted to investors, allowing them to monitor their position within the company's
							capitalization table. This transparency provides insights into the company's ownership structure and the
							investor's relative standing.
						</li>
					</ul>
				</li>
			</ol>
		</div>
		<div>
			<h3>Considerations for Investors:</h3>
			<ol>
				<li>
					<b>Higher Transaction Minimum:</b>
					<ul>
						<li>
							Direct share acquisitions often come with a higher transaction minimum. This minimum investment threshold
							is designed to ensure that investors commit a substantial amount, aligning with the nature of direct
							ownership and reinforcing a significant investment commitment.
						</li>
					</ul>
				</li>
				<li>
					<b>Ongoing Interaction Management:</b>
					<ul>
						<li>
							Following the completion of the direct share acquisition, investors are responsible for managing ongoing
							interactions with the company. This includes staying informed about company developments, participating in
							shareholder meetings, and potentially engaging in voting or decision-making processes.
						</li>
					</ul>
				</li>
				<li>
					<b>Strategic Decision-Making:</b>
					<ul>
						<li>
							Direct share ownership through ShareWell entails active involvement in the company's strategic decisions.
							Investors may have the opportunity to participate in discussions related to major corporate actions,
							governance matters, and other key initiatives.
						</li>
					</ul>
				</li>
			</ol>
		</div>
		<div>
			<h3>Conclusion:</h3>
			<p>
				Direct share acquisition through ShareWell provides investors with a unique opportunity to directly own shares
				in private companies. This approach emphasizes a commitment to a substantial investment, with ShareWell
				facilitating a secure and efficient transaction process. Investors, once engaged in a direct share transaction,
				play a proactive role in managing ongoing interactions with the company, enjoying the benefits and
				responsibilities of direct ownership.
			</p>
		</div>
	</div>
);

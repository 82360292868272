export const endpoints = {
	websocket: `${process.env.REACT_APP_WEBSOCKET_SERVER_URL}`,
	login: `${process.env.REACT_APP_SERVER_URL}/api/v2/authenticate`,
	emailExists: `${process.env.REACT_APP_SERVER_URL}/api/v2/emailExists`,
	getUserHomepage: `${process.env.REACT_APP_SERVER_URL}/api/v2/getUserHomepage`,
	logInterest: `${process.env.REACT_APP_SERVER_URL}/api/v2/logInquiry`,
	logShadowInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/logShadowInquiry`,
	logInstitutionalInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/logInstInquiry`,
	signup: `${process.env.REACT_APP_SERVER_URL}/api/v2/signup`,
	submitQuestionnaireForm: `${process.env.REACT_APP_SERVER_URL}/api/v2/submitQuestionnaire`,
	submitAccreditationForm: `${process.env.REACT_APP_SERVER_URL}/api/v2/accreditation`,
	uploadTransactionFiles: `${process.env.REACT_APP_SERVER_URL}/api/v2/handleUploads/?recId={recordId}`,
	handleUploads: `${process.env.REACT_APP_SERVER_URL}/api/v2/handleUploads/?recId={recordId}&object={object}&prop={prop}`,
	getInquiries: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/inquiries`,
	getAnonymousInquiries: `${process.env.REACT_APP_SERVER_URL}/api/v2/inquiries`,
	getInquiryDetails: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/inquiryDetails`,
	getInquisitions: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/inquisitions`,
	getInquisitionDetails: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/inquisitionDetails`,
	duplicateInquisition: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/duplicateInquisition`,
	deleteInquisition: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/deleteInquisition`,
	updateRating: `${process.env.REACT_APP_SERVER_URL}/api/v2/updateRating`, //used to update the rating, a POST request, receives the inquiryId (or inquisitionId) and the rating (a number 1-10)
	sendMessage: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/sendMessage`,
	getAdminOverviewData: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/overviewData?companyId={companyId}`,
	updateInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/updateInquiry`,
	disableTarget: `${process.env.REACT_APP_SERVER_URL}/api/v2/disableTarget`,
	patchInstInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/patchInstInquiry`,
	patchInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/patchInquiry`,
	cancelInstInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/cancelInstInquiry?inquiryId={inquiryId}`,
	enableInstInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/cancelInstInquiry?inquiryId={inquiryId}&enable=true`,
	cancelInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/cancelInquiry?inquiryId={inquiryId}`,
	enableInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/cancelInquiry?inquiryId={inquiryId}&enable=true`,
	deleteInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/deleteInquiry?inquiryId={inquiryId}`,
	addManualInstInquiry: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/addManualInstInquiry`,
	getUsers: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/getUsers?retarget=false`,
	getUserDetails: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/getUserDetails?userId={userId}`,
	updateUserFields: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/updateUserFields`,
	createShadowUser: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/createShadowUser`,
	retargetUsers: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/retargetUsers`,
	sendTemplatedEmailUsers: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/sendTemplatedUsers`,
	sendTemplatedEmail: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/sendTemplatedEmail`,
	sendTemplatedEmailAccred: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/sendTemplatedAccreds`,
	impersonateUsers: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/impersonate/?userId={userId}`,
	grantAccess: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/grantAccess/?userId={userId}`,
	deleteUser: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/deleteUser/?userId={userId}`,
	uploadComplianceReport: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/uploadComplianceReport/?recId={recordId}`,
	sendSignature: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/signSellerTicket?inquiryId={id}&referral={referral}`,
	sendSignatureDryRun: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/signSellerTicket?inquiryId={id}&dryRun=true&referral={referral}`,
	cancelSignature: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/cancelSignatureRequest?signatureRequestId={id}`,
	copyUploads: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/copyUploads?inquiryId={id}`,
	expressInterest: `${process.env.REACT_APP_SERVER_URL}/api/v2/expressInterest`,
	getInstitutionalReport: `${process.env.REACT_APP_SERVER_URL}/api/v2/institutionalReport`,
	sendUserMessage: `${process.env.REACT_APP_SERVER_URL}/api/v2/sendUserMessage`,
	sendUrbiEtOrbi: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/urbietorbi`,
	getActivityLogs: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/activityLogs`,
	getTasks: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/tasks`,
	getTaskById: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/tasks/?id={taskId}`,
	updateAdminRating: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/updateRating`,
	getSignatures: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/signatures`,
	respondBid: `${process.env.REACT_APP_SERVER_URL}/api/v2/respondBid`,
	respondOffer: `${process.env.REACT_APP_SERVER_URL}/api/v2/respondOffer`,

	getMasterBids: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/masterBids`,
	getMasterBidById: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/masterBids/?id={id}`,
	getUsersForBid: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/getBidUsers/?companyId={companyId}`,
	inviteBids: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/bids`,

	getMasterOffers: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/masteroffers`,
	getMasterOfferById: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/masteroffers/?id={id}`,
	getUsersForOffer: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/getOfferUsers/?companyId={companyId}`,
	inviteOffers: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/offers`,

	//investors
	getInvestors: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/investors`,
	getInvestorsById: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/investors/?id={investorId}`,
	getInvestorsByCompanyId: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/companyInvestors/?companyId={companyId}`,
	getAccreditedInvestors: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/accredited`,
	getAccreditedInvestorById: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/accredited/?id={investorId}`,

	//companies
	getAllCompanies: `${process.env.REACT_APP_SERVER_URL}/api/v2/getCompanies`,
	getAllCompaniesNoToken: `${process.env.REACT_APP_SERVER_URL}/api/v2/public/getCompanies`,
	getAllCompanyNamesNoToken: `${process.env.REACT_APP_SERVER_URL}/api/v2/public/getCompanies?namesOnly=true`,
	getAllCompaniesByAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/getCompaniesAdmin`,
	companyDetails: {
		pricesUrl: `${process.env.REACT_APP_SERVER_URL}/api/v2/priceHistory/?companyId={companyId}`,
		fundingUrl: `${process.env.REACT_APP_SERVER_URL}/api/v2/funding/?companyId={companyId}`,
		overviewUrl: `${process.env.REACT_APP_SERVER_URL}/api/v2/company/?companyId={companyId}`,
		// bubbleUrl: `${process.env.REACT_APP_SERVER_URL}/api/v2/transactions/?companyId={companyId}`,
		investorsUrl: `${process.env.REACT_APP_SERVER_URL}/api/v2/companyInvestors/?companyId={companyId}`,
	},
	followCompany: `${process.env.REACT_APP_SERVER_URL}/api/v2/followCompanies`,
	manageCompanyByIdAndAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/manageCompany/?id={id}`,
	createCompanyByAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/manageCompany`,
	getCompanyPriceHistoryByIdAndAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/getPriceHistory/?companyId={companyId}`,
	createCompanyPriceHistoryByAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/managePriceHistory`,
	duplicateLastPriceHistoryByAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/duplicateLastPriceHistory?companyId={companyId}`,
	manageCompanyPriceHistoryByIdAndAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/managePriceHistory/?id={id}`,
	getCompanyFundingByIdAndAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/getFunding/?companyId={companyId}`,
	createCompanyFundingByAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/manageFunding`,
	manageCompanyFundingByIdAndAdmin: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/manageFunding/?id={id}`,

	//chat widget
	getChatMessages: `${process.env.REACT_APP_SERVER_URL}/api/v2/getChatMessages`,
	sendChatMessage: `${process.env.REACT_APP_SERVER_URL}/api/v2/sendChatMessage`,
	sendChatAttachment: `${process.env.REACT_APP_SERVER_URL}/api/v2/sendChatAttachment`,
	markChatRead: `${process.env.REACT_APP_SERVER_URL}/api/v2/markChatRead`,
	markChatsResolved: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/markChatsResolved`,

	//chats
	getChats: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/getChats`,
	getChatById: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/getUserChats?userId={userId}`,
	replyToChat: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/replyToChat`,
	getDealchats: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/dealChats`,

	//settings - associate
	associates: `${process.env.REACT_APP_SERVER_URL}/api/v2/associates`,
	entities: `${process.env.REACT_APP_SERVER_URL}/api/v2/entities`,

	refreshCompanyData: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/refreshCompanyCache`,
	refreshInquiriesCache: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/refreshInquiryCache`,
	refreshUsersCache: `${process.env.REACT_APP_SERVER_URL}/api/v2/admin/refreshUserCache`,

	referralParam: 'referral',
};

import * as Yup from 'yup';

export const initialValues = {
	compliance: '',
	corporationType: '',
	crd: '',
	uploadDocuments: [],
};

export const schema = Yup.object({
	corporationType: Yup.string().required('Required'),
	crd: Yup.string().when('compliance', {
		is: (compliance) => compliance === 'No (Broker Dealer)',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	uploadDocuments: Yup.array().when('compliance', {
		is: (compliance) => compliance !== 'No (Broker Dealer)',
		then: (schema) => schema.min(1, 'Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
});

import { FormControl, FormHelperText, InputLabel, Select as MUISelect } from '@mui/material';
import { useField } from 'formik';

const Select = ({ showAsterisk = true, label, fullWidth, required, helperText, ...props }) => {
	const [field, meta] = useField(props);
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 500,
			},
		},
	};

	return (
		<FormControl fullWidth={fullWidth} required={required} error={!!(meta.touched && meta.error)}>
			{label && (
				<InputLabel required={showAsterisk && required} id={label}>
					{label}
				</InputLabel>
			)}
			<MUISelect
				labelId={label}
				label={label}
				required={required}
				MenuProps={MenuProps}
				variant={'outlined'}
				{...field}
				{...props}
			/>
			{meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
			{helperText && <FormHelperText error={false}>{meta.error}</FormHelperText>}
		</FormControl>
	);
};

export default Select;

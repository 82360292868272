import {
	Avatar,
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DoneAll, MoreVert } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { StyledInput } from './styles';
import { Form, Formik } from 'formik';
import { apiClient, endpoints } from '../../../../../../utils';
import { schema, initialValues } from './constants';
import DeleteDialog from '../DeleteDialog';
import { Label, valueColumns } from '../../../../../../styles/drawerComponents';
import { strings } from '../../../../../../utils/strings';

const TeamMemberDrawer = ({ teamMembers }) => {
	const { id } = useParams();
	const isNew = useMemo(() => {
		return id === 'new';
	}, [id]);
	const navigate = useNavigate();
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
	const [teamMember, setTeamMember] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [isDeleteOpen, setDeleteOpen] = useState(false);

	const onClose = useCallback(
		(updateObjectId) => {
			const state = { preventScrollReset: true };

			if (typeof updateObjectId === 'string') {
				state.updateObjectId = updateObjectId;
			}
			navigate('/settings/team' + window.location.search, {
				state: state,
			});

			setTeamMember(null);
		},
		[navigate]
	);

	const onDeleteSuccess = useCallback(() => {
		navigate('/settings/team' + window.location.search, { state: { preventScrollReset: true, deleteId: id } });
		setTeamMember(null);
	}, [navigate, id]);

	useEffect(() => {
		if (!isNew) {
			if (teamMembers) {
				setTeamMember(teamMembers.find((item) => item.id === id));
				setLoading(false);
			} else {
				apiClient.get(endpoints.associates).then((res) => {
					setTeamMember(res.data);
					setLoading(false);
				});
			}
		} else {
			setTeamMember(null);
			setLoading(false);
		}
	}, [id, isNew, teamMembers]);

	const handleSubmit = async (values) => {
		try {
			if (!isNew) {
				//update
				await apiClient.put(`${endpoints.associates}?id=${id}`, values);
				toast.success(strings.successMessages.teamMember.updated);
				onClose(id);
			} else {
				//create new
				await apiClient.post(endpoints.associates, values);
				toast.success(strings.successMessages.teamMember.saved);
				onClose('new');
			}
		} catch (err) {
			if (err?.response?.data) toast.error(err.response.data);
			else toast.error(strings.errorMessages.teamMember.save);
		}
	};

	return (
		<Drawer anchor={'right'} open={Boolean(id)} elevation={1} onClose={onClose}>
			<Box width={isMobile ? '100vw' : 600} height={'100%'}>
				{isLoading && <LinearProgress />}
				{!isLoading && (
					<Formik
						enableReinitialize={true}
						initialValues={{ ...initialValues, ...(teamMember ? teamMember.fields : {}) }}
						validationSchema={schema}
						validateOnBlur={true}
						onSubmit={handleSubmit}
					>
						{({ setFieldValue, values, dirty, resetForm }) => {
							return (
								<Form autoComplete="off" style={{ height: '100%' }}>
									<Stack height={'100%'}>
										<Stack>
											<Stack direction={'row'} spacing={2} p={3} pb={2} alignItems={'center'}>
												{!isNew && (
													<Box pt={'4px'}>
														<Avatar
															sx={{ width: isMobile ? 40 : 60, height: isMobile ? 40 : 60 }}
															src="/broken-image.jpg"
														/>
													</Box>
												)}
												<Stack flex={1}>
													<Typography variant={'h5'} sx={{ wordBreak: 'break-word' }}>
														{!isNew ? values['Full Name'] : 'New team member'}
													</Typography>
													{!isNew && (
														<Typography variant={'body1'} color={'text.secondary'} sx={{ wordBreak: 'break-word' }}>
															{values.Email}
														</Typography>
													)}
												</Stack>
												{!isNew && (
													<IconButton
														size="small"
														onClick={(e) => {
															setMenuAnchorEl(e.currentTarget);
														}}
													>
														<MoreVert sx={{ color: 'text.secondary' }} />
													</IconButton>
												)}
											</Stack>
											<Divider />
										</Stack>
										<Box overflow={'auto'} flex={1} p={3}>
											<Grid container spacing={[3, '4px']}>
												<Label>Full name</Label>
												<Grid size={valueColumns}>
													<StyledInput
														name="Full Name"
														onChange={(e) => {
															setFieldValue('Full Name', e.currentTarget.value);
														}}
														fullWidth
													/>
												</Grid>
												<Label>Email</Label>
												<Grid size={valueColumns}>
													<StyledInput
														name="Email"
														onChange={(e) => {
															setFieldValue('Email', e.currentTarget.value);
														}}
														fullWidth
														disabled={!isNew}
													/>
												</Grid>
												<Label>Role</Label>
												<Grid size={valueColumns}>
													<StyledInput
														name="Role"
														onChange={(e) => {
															setFieldValue('Role', e.currentTarget.value);
														}}
														fullWidth
													/>
												</Grid>
											</Grid>
										</Box>

										<Stack
											sx={{ borderTop: 1, borderColor: 'divider' }}
											justifyContent={isMobile ? undefined : 'flex-end'}
											p={2}
											direction={'row'}
											spacing={2}
										>
											<Button
												size={isMobile ? 'large' : 'small'}
												variant={'text'}
												color="inherit"
												onClick={() => {
													resetForm();
													onClose();
												}}
												fullWidth={isMobile}
											>
												Cancel
											</Button>
											<Button
												size={isMobile ? 'large' : 'small'}
												variant={'contained'}
												startIcon={<DoneAll />}
												type={'submit'}
												disabled={!dirty}
												sx={{ flexShrink: 0 }}
											>
												Save changes
											</Button>
										</Stack>
									</Stack>
								</Form>
							);
						}}
					</Formik>
				)}
			</Box>
			<Menu
				anchorEl={menuAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={Boolean(menuAnchorEl)}
				onClose={() => {
					setMenuAnchorEl(null);
				}}
			>
				<MenuItem
					sx={{ color: defaultTheme.palette.error.main }}
					onClick={() => {
						setDeleteOpen(true);
						setMenuAnchorEl(null);
					}}
				>
					Delete
				</MenuItem>
			</Menu>
			<DeleteDialog
				object={teamMember}
				onSuccess={onDeleteSuccess}
				open={isDeleteOpen}
				handleClose={() => {
					setDeleteOpen(false);
					setMenuAnchorEl(null);
				}}
			/>
		</Drawer>
	);
};

export default TeamMemberDrawer;

import styled from 'styled-components';
import { FormGroup } from '@mui/material';

export const InputButtonWrapper = styled(FormGroup).attrs({
	row: true,
})(({ $error }) => {
	return {
		'& .MuiTextField-root': {
			flex: 1,
		},
		'& .MuiInputBase-root': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},
		'& .MuiButtonBase-root': {
			width: 134,
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			marginBottom: $error ? '23px' : 0,
		},
	};
});

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Onboarding from '../../components/Onboarding';
import { useAuthContext } from '../../context';

const OnboardingView = () => {
	const { recId } = useParams();
	const [locked, setLocked] = useState(false);
	const [editing, setEditing] = useState(false);

	const [defaultValues, setDefaultValues] = useState(null);
	const { inquiries } = useAuthContext();
	const [inquiryData, setInquiryData] = useState({ companyName: '', actionValue: '', shareNum: 0, pps: 0 });

	useEffect(() => {
		if (recId) {
			inquiries.forEach((inquiry) => {
				inquiry.activityLogs?.forEach((activityLog) => {
					if (activityLog.fields['Type'] === 'OnboardingPersonal' && activityLog.id === recId) {
						setInquiryData({
							companyName: inquiry.fields['Company Name'][0],
							actionValue: inquiry.fields.Action,
							shareNum: inquiry.fields.Shares,
							pps: inquiry.fields.PPS,
						});

						if (activityLog.fields['Questionnaire']) {
							if (activityLog.fields['Submitted At']) {
								setEditing(true);
							}

							if (activityLog.fields['Locked']) {
								setLocked(true);
							}
							const formData = JSON.parse(activityLog.fields['Questionnaire']);

							if (formData) {
								setDefaultValues({
									...formData,
									uploadDocuments: activityLog.fields['Uploads'] ?? [],
									birthDate: new Date(formData.birthDate),
								});
							}
						}
					}
				});
			});
		}
	}, [recId, inquiries]);

	return <Onboarding defaultValues={defaultValues} locked={locked} editing={editing} inquiryData={inquiryData} />;
};

export default OnboardingView;

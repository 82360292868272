import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useState } from 'react';
import { ZoomIn } from '@mui/icons-material';
import { DocumentCard, HoverBackdrop } from './formNDAY.styles';
import { darkTheme } from '../../styles/theme';

const FormNDA = () => {
	const [isHovered, setHovered] = useState(false);
	const [isDialogOpen, setDialogOpen] = useState(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const fileContent = (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
			<Viewer
				fileUrl="data/nda.pdf"
				defaultScale={SpecialZoomLevel.PageWidth}
				renderLoader={(percentages) => <CircularProgress variant="determinate" value={Math.round(percentages)} />}
			/>
		</Worker>
	);

	return (
		<DocumentCard
			onPointerEnter={!isMobile ? () => setHovered(true) : undefined}
			onPointerLeave={!isMobile ? () => setHovered(false) : undefined}
			onClick={
				isMobile
					? () => {
							setDialogOpen(!isDialogOpen);
						}
					: undefined
			}
			elevation={4}
		>
			{fileContent}
			<HoverBackdrop
				open={isHovered}
				onClick={() => {
					setDialogOpen(true);
					setHovered(false);
				}}
			>
				<ZoomIn sx={{ fontSize: 60, color: darkTheme.palette.action.active }} />
			</HoverBackdrop>
			<Dialog
				open={isDialogOpen}
				onClose={() => setDialogOpen(false)}
				scroll={'paper'}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
				maxWidth={'md'}
				fullWidth={true}
				PaperProps={{
					sx: {
						height: 'calc(100% - 64px)',
					},
				}}
			>
				<DialogTitle>Non-Disclosure Agreement</DialogTitle>
				<DialogContent dividers={true} sx={{ padding: 0, colorScheme: 'light' }}>
					{fileContent}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)}>Ok</Button>
				</DialogActions>
			</Dialog>
		</DocumentCard>
	);
};

export default FormNDA;

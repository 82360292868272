import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

const InfoBlock = ({ title, children, ...props }) => {
	return (
		<Stack spacing={'20px'} sx={{ paddingBottom: 4, borderBottom: '1px solid', borderColor: 'divider' }} {...props}>
			<Typography variant={'h6'} color={'text.primary'}>
				{title}
			</Typography>
			<Box>
				<Grid container columnSpacing={3} rowSpacing={'20px'}>
					{children}
				</Grid>
			</Box>
		</Stack>
	);
};

export default InfoBlock;

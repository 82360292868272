export default (
	<div>
		<h2>Why Right of First Refusal Matters:</h2>
		<ul>
			<li>
				<b>Controlled Ownership:</b>
				The right of first refusal enables the company to maintain control over its ownership structure by having a say
				in who becomes a shareholder.
			</li>

			<li>
				<b>Prevention of Undesired Transfers:</b>
				It prevents shares from being sold to parties that may not align with the company's values or strategic goals.
			</li>
		</ul>
		<div>
			<h3>Consultation and Legal Guidance:</h3>
			<p>
				Navigating the company approval process and the right of first refusal often requires consultation with legal
				professionals. Shareholders and companies alike benefit from legal guidance to ensure that transactions adhere
				to relevant laws and agreements, fostering a transparent and legally sound process.
			</p>
		</div>
	</div>
);

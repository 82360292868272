import styled from '@emotion/styled';
import { Backdrop, Paper } from '@mui/material';

export const DocumentCard = styled(Paper)`
	width: 200px;
	height: 283px;
	border-radius: 4px;
	position: relative;
	z-index: 0;
`;

export const HoverBackdrop = styled(Backdrop)`
	border-radius: 4px;
	position: absolute;
	cursor: pointer;
`;

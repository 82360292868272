import {
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	Menu,
	MenuItem,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DoneAll, MoreVert } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { StyledInput } from './styles';
import { Form, Formik } from 'formik';
import { apiClient, endpoints } from '../../../../../../utils';
import { schema, initialValues } from './constants';
import DeleteDialog from '../DeleteDialog';
import { StyledTextArea } from '../../../../../AdminInquiriesView/components/InquiryDrawer/styles';
import FileUploadMultiple from '../../../../../../components/FormComponents/FileUploadMultiple';
import LoadingButton from '@mui/lab/LoadingButton';
import { Label, valueColumns } from '../../../../../../styles/drawerComponents';
import { strings } from '../../../../../../utils/strings';

const EntityDrawer = ({ entities }) => {
	const { id } = useParams();
	const isNew = useMemo(() => {
		return id === 'new';
	}, [id]);
	const navigate = useNavigate();
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));
	const [object, setObject] = useState(null);
	const [isLoading, setLoading] = useState(true);
	const [menuAnchorEl, setMenuAnchorEl] = useState(null);
	const [isDeleteOpen, setDeleteOpen] = useState(false);

	const onClose = useCallback(
		(updateObjectId) => {
			const state = { preventScrollReset: true };

			if (typeof updateObjectId === 'string') {
				state.updateObjectId = updateObjectId;
			}
			navigate('/settings/entities' + window.location.search, {
				state: state,
			});

			setObject(null);
		},
		[navigate]
	);

	const onDeleteSuccess = useCallback(() => {
		navigate('/settings/entities' + window.location.search, { state: { preventScrollReset: true, deleteId: id } });
		setObject(null);
	}, [navigate]);

	useEffect(() => {
		if (!isNew) {
			if (entities) {
				setObject(entities.find((item) => item.id === id));
				setLoading(false);
			} else {
				apiClient.get(endpoints.entities).then((res) => {
					setObject(res.data);
					setLoading(false);
				});
			}
		} else {
			setObject(null);
			setLoading(false);
		}
	}, [id, isNew, entities]);

	const handleSubmit = async (values) => {
		try {
			if (!isNew) {
				//update
				await apiClient.put(`${endpoints.entities}?id=${id}`, values);
				if (values.COI && values.COI.length > 0) {
					await handleSetUpload({ id, ...values });
				}
				toast.success(strings.successMessages.entity.updated);
				onClose(id);
			} else {
				//create new
				const res = await apiClient.post(endpoints.entities, values);
				if (values.COI && values.COI.length > 0) {
					await handleSetUpload({ id: res.data[0].id, ...values });
				}
				toast.success(strings.successMessages.entity.saved);
				onClose('new');
			}
		} catch (err) {
			toast.error(strings.errorMessages.entity.update);
		}
	};

	const handleSetUpload = async (values) => {
		const { COI, id } = values;
		const formData = new FormData();

		for (const file of COI) {
			if (file instanceof Blob) {
				formData.append('files', file);
			} else {
				const blob = await fetch(file.url).then((r) => r.blob());
				const parts = [blob];
				const newFile = new File(parts, file.filename, { url: file.url, ...file });
				formData.append('files', newFile);
			}
		}

		try {
			await apiClient.post(
				endpoints.handleUploads.replace('{recordId}', id).replace('{object}', 'Entities').replace('{prop}', 'COI'),
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				}
			);
		} catch (err) {
			toast.error(strings.errorMessages.compliance.saveFiles);
		}
	};

	return (
		<Drawer anchor={'right'} open={Boolean(id)} elevation={1} onClose={onClose}>
			<Box width={isMobile ? '100vw' : 600} height={'100%'}>
				{isLoading && <LinearProgress />}
				{!isLoading && (
					<Formik
						enableReinitialize={true}
						initialValues={{ ...initialValues, ...(object ? object.fields : {}) }}
						validationSchema={schema}
						validateOnBlur={true}
						onSubmit={handleSubmit}
					>
						{({ setFieldValue, values, dirty, resetForm, errors, touched, isSubmitting }) => {
							return (
								<Form autoComplete="off" style={{ height: '100%' }}>
									<Stack height={'100%'}>
										<Stack>
											<Stack direction={'row'} spacing={2} p={3} pb={2} alignItems={'center'}>
												<Typography variant={'h5'} sx={{ wordBreak: 'break-word' }} flex={1}>
													{!isNew ? values['Name'] : 'New entity'}
												</Typography>
												{!isNew && (
													<IconButton
														size="small"
														onClick={(e) => {
															setMenuAnchorEl(e.currentTarget);
														}}
													>
														<MoreVert sx={{ color: 'text.secondary' }} />
													</IconButton>
												)}
											</Stack>
											<Divider />
										</Stack>
										<Box overflow={'auto'} flex={1} p={3}>
											<Grid container spacing={[3, '4px']}>
												<Label>Name</Label>
												<Grid size={valueColumns}>
													<StyledInput
														name="Name"
														onChange={(e) => {
															setFieldValue('Name', e.currentTarget.value);
														}}
														fullWidth
													/>
												</Grid>
												<Label>Address</Label>
												<Grid size={valueColumns}>
													<StyledTextArea name={'Address'} att={6} rows={2} />
												</Grid>
												<Label>Signatory name</Label>
												<Grid size={valueColumns}>
													<StyledInput
														name="Signatory Name"
														onChange={(e) => {
															setFieldValue('Signatory Name', e.currentTarget.value);
														}}
														fullWidth
													/>
												</Grid>
												<Label>Signatory email</Label>
												<Grid size={valueColumns}>
													<StyledInput
														name="Signatory Email"
														onChange={(e) => {
															setFieldValue('Signatory Email', e.currentTarget.value);
														}}
														fullWidth
													/>
												</Grid>
												<Label>Signatory title</Label>
												<Grid size={valueColumns}>
													<StyledInput
														name="Signatory Title"
														onChange={(e) => {
															setFieldValue('Signatory Title', e.currentTarget.value);
														}}
														fullWidth
													/>
												</Grid>
												<Label>COI</Label>
												<Grid size={valueColumns}>
													<FileUploadMultiple
														name={'COI'}
														errors={errors}
														touched={touched}
														disabled={isSubmitting}
														setFieldValue={setFieldValue}
														values={values}
													/>
												</Grid>
											</Grid>
										</Box>

										<Stack
											sx={{ borderTop: 1, borderColor: 'divider' }}
											justifyContent={isMobile ? undefined : 'flex-end'}
											p={2}
											direction={'row'}
											spacing={2}
										>
											<Button
												size={isMobile ? 'large' : 'small'}
												variant={'text'}
												color="inherit"
												onClick={() => {
													resetForm();
													onClose();
												}}
												fullWidth={isMobile}
											>
												Cancel
											</Button>
											<LoadingButton
												variant="contained"
												type="submit"
												size={isMobile ? 'large' : 'small'}
												loading={isSubmitting}
												disabled={!dirty || isSubmitting}
												startIcon={<DoneAll />}
												loadingPosition={'start'}
												sx={{ flexShrink: 0 }}
											>
												{isSubmitting ? 'Saving...' : 'Save changes'}
											</LoadingButton>
										</Stack>
									</Stack>
								</Form>
							);
						}}
					</Formik>
				)}
			</Box>
			<Menu
				anchorEl={menuAnchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={Boolean(menuAnchorEl)}
				onClose={() => {
					setMenuAnchorEl(null);
				}}
			>
				<MenuItem
					sx={{ color: defaultTheme.palette.error.main }}
					onClick={() => {
						setDeleteOpen(true);
						setMenuAnchorEl(null);
					}}
				>
					Delete
				</MenuItem>
			</Menu>
			<DeleteDialog
				object={object}
				onSuccess={onDeleteSuccess}
				open={isDeleteOpen}
				handleClose={() => {
					setDeleteOpen(false);
					setMenuAnchorEl(null);
				}}
			/>
		</Drawer>
	);
};

export default EntityDrawer;

export default (
	<div>
		<h2>Why Providing Your SSN is Necessary:</h2>
		<div>
			<h3>Identity Verification:</h3>
			<ul>
				<li>
					Your Social Security Number (SSN) is a crucial piece of information used to verify your identity. This step is
					essential to ensure that the individual engaging in the transaction is who they claim to be.
				</li>
			</ul>
		</div>
		<div>
			<h3>Legal Compliance - Anti-Terrorism and Anti-Money Laundering (AML) Laws:</h3>
			<ul>
				<li>
					<b>Fighting Terrorism Funding:</b> Providing your SSN is a legal requirement mandated by US laws aimed at
					preventing the funding of terrorism. This regulatory measure is in place to enhance national security by
					tracking and mitigating potential risks associated with financial transactions.
				</li>
				<li>
					<b>Combating Money Laundering:</b> Additionally, the collection of SSNs supports efforts to combat money
					laundering activities. By associating individuals with their financial transactions, authorities can monitor
					and investigate suspicious activities more effectively.
				</li>
			</ul>
		</div>
		<div>
			<h3>Governmental Compliance:</h3>
			<ul>
				<li>
					ShareWell operates in compliance with US laws and regulations. The collection of SSNs is a standard practice
					in the financial industry to align with legal obligations and ensure the integrity of the financial system.
				</li>
			</ul>
		</div>
		<div>
			<h3>Protecting Investors and the Platform:</h3>
			<ul>
				<li>
					Verifying the identity of participants in financial transactions, including the collection of SSNs, helps
					protect both investors and the platform. It adds a layer of security, reducing the risk of fraudulent
					activities and ensuring a more trustworthy and compliant investment environment.
				</li>
			</ul>
		</div>
		<div>
			<h3>Conclusion:</h3>
			<p>
				Providing your SSN is a critical step in complying with US laws related to identity verification, anti-terrorism
				measures, and anti-money laundering efforts. By adhering to these regulatory requirements, ShareWell aims to
				create a secure and transparent investment platform while contributing to broader national security objectives.
			</p>
		</div>
	</div>
);

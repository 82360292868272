export default (
	<div>
		<h2>How Do I Value My Private Company Shares?</h2>
		<p>
			Valuing private company shares can be challenging due to the lack of real-time price transparency and the
			subjective nature of private market valuations. However, several indicators and resources can help you arrive at a
			reasonable estimate.
		</p>
		<div>
			<h3>Sources for Valuation Clues:</h3>
			<ol>
				<li>
					<b>Recent Trading Activity on Secondary Markets:</b>
					<ul>
						<li>
							Explore recent activity of your company's private shares on a secondary marketplace like ShareWell. This
							can provide valuable insights into the perceived value of the shares in the current market.
						</li>
					</ul>
				</li>
				<li>
					<b>Valuations from Recent Funding Rounds:</b>
					<ul>
						<li>
							Examine valuations from your company's recent funding rounds. This information can offer an indication of
							how investors value your company and its growth potential.
						</li>
					</ul>
				</li>
				<li>
					<b>Comparison with Competitors:</b>
					<ul>
						<li>
							Compare your company to competitors with publicly available valuations. While not directly comparable,
							understanding how similar companies are valued in the public market can provide a benchmark for assessing
							your company's value.
						</li>
					</ul>
				</li>
			</ol>
		</div>
		<div>
			<h3>Leveraging ShareWell Insights:</h3>
			<ul>
				<li>
					A ShareWell Account Manager can assist you in determining the value of your private company shares. ShareWell,
					with its extensive experience, incorporates a proprietary trading and valuation data system, providing
					valuable insights to help price your shares effectively.
				</li>
				<li>
					ShareWell Account Managers utilize their expertise and the platform's data to share insights tailored to your
					specific situation. They can provide a comprehensive understanding of the factors influencing your company's
					valuation and guide you through the valuation process.
				</li>
			</ul>
			<p>
				It's important to note that private company valuations are inherently subjective, and multiple factors
				contribute to determining the fair value of your shares. Seeking professional guidance and utilizing available
				market data can enhance your ability to arrive at a well-informed and reasonable valuation.
			</p>
		</div>
	</div>
);

import styled from 'styled-components';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const InstitutionalInfoFieldWrapper = styled(Stack).attrs({
	spacing: '4px',
	direction: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
})((props) => {
	const theme = useTheme();

	return {
		paddingLeft: 12,
		paddingRight: 12,
		backgroundColor: props.$even ? theme.palette.grey['900'] : 'transparent',
		minHeight: props.minHeight ?? '3.5em',
		borderRadius: theme.shape.borderRadius,
	};
});

import * as Yup from 'yup';

export const initialValues = {
	trust: 'No',
	bank: 'No',
	employeeBenefit: 'No',
	corporationLLC: 'No',
	minIncome: 'No',
	investmentExcessFive: 'No',
	familyOffice: 'No',
	familyClient: 'No',
	representations: false,
};

export const schema = Yup.object({
	trust: Yup.string().required('Required'),
	bank: Yup.string().required('Required'),
	employeeBenefit: Yup.string().required('Required'),
	corporationLLC: Yup.string().required('Required'),
	minIncome: Yup.string().required('Required'),
	investmentExcessFive: Yup.string().required('Required'),
	familyOffice: Yup.string().required('Required'),
});

import { TextField } from '@mui/material';
import { useField } from 'formik';

const InputField = (props) => {
	const { showAsterisk = true, type, label, required, variant, helperText, inputProps, ...otherProps } = props;
	const [field, meta] = useField(otherProps);

	return (
		<TextField
			error={!!(meta.touched && meta.error)}
			helperText={meta.touched && meta.error ? meta.error : helperText}
			type={type || 'text'}
			required={required}
			label={label}
			variant={variant || 'outlined'}
			InputLabelProps={{
				required: showAsterisk && required,
			}}
			{...field}
			onChange={(e) => {
				if (type === 'tel') {
					const regex = /^[0-9\b]+$/;
					if (e.target.value === '' || regex.test(e.target.value)) {
						field.onChange(e);
					}
				} else {
					field.onChange(e);
				}
			}}
			inputProps={{
				readOnly: props.readOnly,
				...inputProps,
			}}
			{...otherProps}
		/>
	);
};

export default InputField;

import { createContext, useContext, useEffect, useState } from 'react';
import { apiClient, endpoints } from '../utils';
import { useLoaderContext } from './LoaderContext';
import { useAuthContext } from './AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';

const PrivateAppContext = createContext({
	current: null,
	setCurrent: () => {},
	companies: [],
	setCompanies: () => {},
});

export const PrivateAppProvider = ({ children }) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const [current, setCurrent] = useState(null);
	const [companies, setCompanies] = useState([]);

	const { closeLoader } = useLoaderContext();
	const { authenticated } = useAuthContext();

	useEffect(() => {
		if (authenticated) {
			const referer = searchParams.get('referer');

			if (referer) {
				navigate(referer);
			}

			loadCompanies()
				.then((res) => {
					setCompanies(res);
				})
				.finally(() => {
					closeLoader();
				});
		}
	}, [authenticated]);

	const loadCompanies = async () => {
		try {
			const res = await apiClient.get(endpoints.getAllCompanies);
			return res.data ?? [];
		} catch (err) {
			return [];
		}
	};

	return (
		<PrivateAppContext.Provider
			value={{
				current,
				setCurrent,
				companies,
				setCompanies,
			}}
		>
			{children}
		</PrivateAppContext.Provider>
	);
};

export const usePrivateAppContext = () => useContext(PrivateAppContext);

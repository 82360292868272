import { Card, CardContent, CardHeader, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'd3';
import { buyerSellerOptions } from '../../utils/constants';
import { useAuthContext, usePrivateAppContext } from '../../context';

const InquiryPreview = ({ companyName, actionValue, shareNum, pps }) => {
	const { user } = useAuthContext();
	const { current } = usePrivateAppContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const renderBottom = () => {
		if (user?.isLevel3 && current?.selectedInquiries && current?.selectedInquiries.length > 0) {
			return (
				<>
					<Divider variant={'fullWidth'} />
					<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
						<Typography variant={'h6'} color={'text.secondary'}>
							Total block size
						</Typography>
						<Typography variant={'h6'} color={'primary.main'}>
							{format('$,.2f')(
								current?.selectedInquiries.reduce((acc, item) => acc + item.fields['Block Size Ticket'], 0)
							)}
						</Typography>
					</Stack>
				</>
			);
		}
		return (
			<>
				<Divider variant={'fullWidth'} />
				<Stack spacing={1}>
					<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
						<Typography variant={'h6'} color={'text.secondary'}>
							Total*
						</Typography>
						<Typography variant={'h6'} color={'primary.main'}>
							{format('$,.2f')(pps * shareNum)}
						</Typography>
					</Stack>
					<Typography variant={'caption'} color={'text.secondary'} width={'70%'}>
						*This tool is a simple calculator intended only for use in estimating block size where the total is an
						approximation. Please consult with your Client Success Manager for further details.
					</Typography>
				</Stack>
			</>
		);
	};

	return (
		<Card>
			<CardHeader title={companyName} />
			{user?.isLevel3 && current?.selectedInquiries && current?.selectedInquiries.length > 0 && (
				<CardContent sx={{ paddingTop: isMobile ? 0 : undefined }}>
					<Stack spacing={3}>
						<Stack spacing={'12px'}>
							<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
								<Typography variant={'body1'} color={'text.secondary'}>
									Selected Blocks
								</Typography>
								<Typography variant={'body1'} color={'text.primary'}>
									{current?.selectedInquiries.length ?? 0}
								</Typography>
							</Stack>
						</Stack>
						{renderBottom()}
					</Stack>
				</CardContent>
			)}
			{!user?.instie && (
				<CardContent sx={{ paddingTop: isMobile ? 0 : undefined }}>
					<Stack spacing={3}>
						<Stack spacing={'12px'}>
							<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
								<Typography variant={'body1'} color={'text.secondary'}>
									Action
								</Typography>
								<Typography variant={'body1'} color={'text.primary'}>
									{buyerSellerOptions.find((opt) => opt.value === actionValue)?.label ?? '-'}
								</Typography>
							</Stack>
							<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
								<Typography variant={'body1'} color={'text.secondary'}>
									Shares
								</Typography>
								<Typography variant={'body1'} color={'text.primary'}>
									{user?.instie ? shareNum : format(',.0f')(shareNum)}
								</Typography>
							</Stack>
							<Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'}>
								<Typography variant={'body1'} color={'text.secondary'}>
									Price per share
								</Typography>
								<Typography variant={'body1'} color={'text.primary'}>
									{format('$.2f')(pps)}
								</Typography>
							</Stack>
						</Stack>
						{renderBottom()}
					</Stack>
				</CardContent>
			)}
		</Card>
	);
};

export default InquiryPreview;

export default (
	<div>
		<h2>How to Buy Shares in a Private Company:</h2>
		<p>
			Investing in shares of a private company involves a distinct process compared to buying publicly traded stocks.
			While there is no legal requirement to use a broker for private company investments, many investors, especially
			individual investors, find it advantageous to leverage private marketplaces like Forge Markets.
		</p>
		<div>
			<h3>Advantages of Using a Private Marketplace:</h3>
			<ol>
				<li>
					<b>Centralized Network:</b>{' '}
					<ul>
						<li>
							Private marketplaces, such as Forge Markets, provide a centralized network of shareholder/sellers. This
							eliminates the need for investors to independently source sellers with shares in their desired private
							company.
						</li>
					</ul>
				</li>
				<li>
					<b>Expertise in Private Transfers:</b>{' '}
					<ul>
						<li>
							Private marketplaces often offer expertise in private company transfer processes. This includes navigating
							the intricacies of applicable securities laws, understanding transaction documents, and facilitating the
							seamless execution of transactions.
						</li>
					</ul>
				</li>
				<li>
					<b>Efficiency and Convenience:</b>{' '}
					<ul>
						<li>
							Utilizing a private marketplace streamlines the buying process, offering efficiency and convenience.
							Investors can benefit from a structured platform that facilitates transactions and connects buyers with
							willing sellers.
						</li>
					</ul>
				</li>
				<li>
					<b>Transaction Security:</b>{' '}
					<ul>
						<li>
							Private marketplaces prioritize transaction security. They implement protocols and verification processes
							to ensure the legitimacy of transactions, providing a level of assurance to both buyers and sellers.
						</li>
					</ul>
				</li>
			</ol>
		</div>
		<div>
			<h3>Disadvantages of Using a Broker:</h3>
			<ol>
				<li>
					<b>Costs:</b>{' '}
					<ul>
						<li>
							One notable disadvantage of using a broker, such as Forge Markets, is the associated costs. Brokers
							typically charge fees for their services. Investors should weigh the advantages against these costs to
							determine the overall value proposition.
						</li>
					</ul>
				</li>
			</ol>
		</div>
		<div>
			<h3>Alternative: Buying Without a Broker:</h3>
			<p>
				While private marketplaces offer advantages, some investors may choose to bypass a broker to save on fees.
				However, this alternative requires a more hands-on approach, including independently sourcing sellers,
				conducting due diligence, and navigating the transaction process without the support of a dedicated platform.
			</p>
		</div>
		<div>
			<h3>Considerations for Investors:</h3>
			<ol>
				<li>
					<b>Due Diligence:</b>{' '}
					<ul>
						<li>
							Whether using a broker or not, thorough due diligence on the private company is crucial. This includes
							assessing the company's financial health, growth prospects, and any potential risks associated with the
							investment.
						</li>
					</ul>
				</li>
				<li>
					<b>Legal Compliance:</b>{' '}
					<ul>
						<li>
							Adhering to applicable securities laws is essential. Investors should ensure that their investment
							activities align with regulatory requirements to avoid legal complications.
						</li>
					</ul>
				</li>
			</ol>
		</div>
		<div>
			<h3>Conclusion:</h3>{' '}
			<p>
				Investing in shares of a private company offers unique opportunities and challenges. Leveraging a private
				marketplace can provide a structured and secure avenue for investors, although the decision to use a broker
				should be made after considering the associated costs and benefits.
			</p>
		</div>
	</div>
);

import styled from '@emotion/styled';
import Grid from '@mui/material/Grid2';

export const MainContainer = styled(Grid)`
	height: 100vh;
`;

export const LeftSide = styled(Grid)`
	padding-top: 56px;
	padding-bottom: 60px;
	display: flex;
	flex-direction: column;
`;

export const CenterWrapper = styled(Grid)`
	justify-content: center;
	height: 100%;
	align-content: center;
`;

import { Autocomplete, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useMemo, useState } from 'react';
import { useAuthContext } from '../../../../context';
import InquiryRecordItem from '../../../../components/InquiryRecordItem';
import { NotFoundContainer } from '../../../../components/CompanyList/companyList.styles';

const InquiriesTab = ({ inquiries, handleLogInterest }) => {
	const { user } = useAuthContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [selectedInquiries, setSelectedInquiries] = useState([]);
	const [isBuyingLoading, setBuyingLoading] = useState(false);
	const [typeFilters, setTypeFilters] = useState([]);
	const filteredInquiries = useMemo(() => {
		if (typeFilters && typeFilters.length) {
			return inquiries.filter((inquiryToFilter) => {
				return typeFilters.map((type) => type.value).find((type) => inquiryToFilter.fields.Action === type);
			});
		}

		return inquiries ?? [];
	}, [inquiries, typeFilters]);

	const buyInquiries = () => {
		setBuyingLoading(true);
		handleLogInterest(selectedInquiries);
		setSelectedInquiries([]);
		setBuyingLoading(false);
	};

	const onSelectOne = (event, inquiry) => {
		if (event.target.checked) {
			setSelectedInquiries((prevState) => [...prevState, inquiry]);
		} else {
			setSelectedInquiries((prevState) => prevState.filter((item) => item.fields.RecId !== inquiry.fields.RecId));
		}
	};

	return (
		<Stack spacing={3}>
			{inquiries.length === 0 && (
				<NotFoundContainer>
					<Stack spacing={3} alignItems={'center'}>
						<img src={'/illustrations/dark/notFound.svg'} style={{ height: 160 }} alt="Not found" />
						<Typography variant={'body2'} textAlign={'center'}>
							No activities yet
						</Typography>
					</Stack>
				</NotFoundContainer>
			)}
			{inquiries.length > 0 && (
				<Stack spacing={'14px'}>
					<Stack
						justifyContent={'space-between'}
						alignItems={isMobile ? undefined : 'center'}
						spacing={1}
						direction={isMobile ? 'column' : 'row'}
					>
						<Autocomplete
							multiple
							size={isMobile ? 'medium' : 'small'}
							disablePortal
							isOptionEqualToValue={(option, value) => {
								return option.value === value.value;
							}}
							options={[
								{
									value: 'buy',
									label: 'Bids',
								},
								{
									value: 'sell',
									label: 'Offers',
								},
							]}
							sx={{ width: isMobile ? undefined : 260 }}
							onChange={(_, value) => {
								setTypeFilters(value);
							}}
							renderInput={(params) => <TextField {...params} label="Type" />}
						/>
						{user?.isLevel3 && (
							<LoadingButton
								variant={'contained'}
								size={'medium'}
								disabled={selectedInquiries.length === 0}
								onClick={buyInquiries}
								loading={isBuyingLoading}
								endIcon={<></>}
								loadingPosition={'end'}
								data-cy={'log-interest-button'}
							>
								{`Express interest${selectedInquiries.length > 0 ? ` (${selectedInquiries.length})` : ''}`}
							</LoadingButton>
						)}
					</Stack>
					<Stack spacing={'12px'}>
						{filteredInquiries.map((inquiry) => (
							<InquiryRecordItem
								selectable={user?.isLevel3}
								key={inquiry.fields.RecId}
								inquiry={inquiry}
								isSelected={Boolean(selectedInquiries.find((item) => item.fields.RecId === inquiry.fields.RecId))}
								handleCheck={(ev) => onSelectOne(ev, inquiry)}
								highlightPriorities={false}
							/>
						))}
						{!Boolean(filteredInquiries.length) && (
							<NotFoundContainer>
								<Stack spacing={3} alignItems={'center'}>
									<img src={'/illustrations/dark/notFound.svg'} style={{ height: 160 }} alt="Not found" />
									<Typography variant={'body2'} textAlign={'center'}>
										We couldn't find what you searched for. Change your search criteria.
									</Typography>
								</Stack>
							</NotFoundContainer>
						)}
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};

export default React.memo(InquiriesTab);

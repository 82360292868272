import styled, { css, keyframes } from 'styled-components';
import { Box, Fab, Paper, Stack, useTheme } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useChatWidgetContext } from '../../context';

const pulseKeyframes = keyframes`
    0% {
        transform: scale(1);
    }
    36% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;

export const Toggler = styled(Fab).attrs({
	color: 'secondary',
	variant: 'extended',
})`
	${({ $bounce }) => css`
		position: fixed !important;
		bottom: 32px;
		right: 56px;
		padding: 0 18px;
		height: 50px;
		font-size: 15px;

		svg {
			font-size: 22px;
			margin-right: 12px;
		}

		animation: ${$bounce
			? css`
					${pulseKeyframes} 1.2s infinite ease-in-out
			  `
			: 'none'};

		@media (max-width: 600px) {
			bottom: 24px;
			right: 24px;
		}
	`}
`;

export const MessageBadgePaper = styled(Paper).attrs({
	elevation: 24,
})`
	${() => {
		const defaultTheme = useTheme();

		return css`
			position: absolute;
			right: 0;
			top: -36px;
			width: max-content;
			border-radius: 40px !important;
			box-shadow: ${defaultTheme.shadows[7]};
			animation: ${pulseKeyframes} 1.2s infinite ease-in-out;
		`;
	}}
`;

export const MessageBadge = styled(Stack).attrs({
	direction: 'row',
	alignItems: 'center',
})(() => {
	return {
		padding: '8px 14px 8px 12px',
		fontSize: 14,
		textTransform: 'none',
	};
});

export const MessageBadgeDot = styled(Stack).attrs({
	alignItems: 'center',
	justifyContent: 'center',
})(() => {
	const defaultTheme = useTheme();

	return {
		flexShrink: 0,
		padding: '0 6px',
		marginRight: 12,
		fontWeight: 500,
		fontSize: '0.75rem',
		borderRadius: 10,
		backgroundColor: defaultTheme.palette.error.main,
	};
});

export const CustomPopover = styled.div(() => {
	return {
		position: 'fixed',
		right: 32,
		bottom: 100,
		zIndex: 1101,

		'@media (max-width: 600px)': {
			right: 0,
			top: 0,
			bottom: 80,
		},
	};
});

export const WidgetPaper = styled(Paper).attrs({
	elevation: 5,
})(() => {
	const { chatWidgetWidth, chatWidgetMinHeight, chatWidgetMaxHeight } = useChatWidgetContext();

	return {
		display: 'flex',
		flexDirection: 'column',
		width: chatWidgetWidth,
		minHeight: chatWidgetMinHeight,
		maxHeight: chatWidgetMaxHeight,
		transition: 'all 0.2s ease',

		'@media screen and (max-height: 840px)': {
			minHeight: '100%',
		},

		'&.MuiPaper-root': {
			borderRadius: 12,
			overflow: 'hidden',
		},
	};
});

export const WidgetTabPanel = styled(TabPanel)((props) => {
	return {
		display: props.$visible ? 'flex' : 'none',
		flexDirection: 'column',
		padding: '0px !important',
		overflowY: 'auto',
		flex: 1,
	};
});

export const HeaderPaper = styled(Paper).attrs({
	elevation: 12,
	square: true,
})(() => {
	return {
		padding: '18px 18px 20px',
		width: '100%',
		zIndex: 1,

		'&.MuiPaper-root': {
			boxShadow: '0px 2px 16px 1px rgba(0, 0, 0, 0.12), 0px 7px 10px 1px rgba(0, 0, 0, 0.14)',
		},
	};
});

export const FooterPaper = styled(Paper).attrs({
	elevation: 14,
	square: true,
})(() => {
	return {
		padding: '12px 12px 12px 10px',
		width: '100%',
	};
});

export const ChatBoxWrapper = styled(Box)(() => {
	return {
		padding: 16,
		flexGrow: 1,
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column-reverse',
	};
});

export const TabListPaper = styled(Paper).attrs({
	elevation: 14,
	square: true,
})((props) => {
	return {
		boxShadow: props.$boxShadow,
		width: '100%',
	};
});

import React from 'react';
import { format, timeFormat } from 'd3';
import {
	Card,
	CardContent,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { NotFoundContainer } from '../../../../components/CompanyList/companyList.styles';

const FundingRounds = ({ fundingData }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const getPostMoneyValuationCellContent = (current, next) => {
		//safeguard against null values
		if (!current) return null;

		let isMore = true;

		if (current && next && current < next) {
			isMore = false;
		}

		return (
			<Stack direction={'row'} spacing={1}>
				{isMore ? <TrendingUp sx={{ color: 'success.main' }} /> : <TrendingDown sx={{ color: 'error.main' }} />}
				<Typography variant={'body1'} color={isMore ? 'success.main' : 'error.main'}>
					{format('.2~s')(current).replace('G', 'bn')}
				</Typography>
			</Stack>
		);
	};

	if (Array.isArray(fundingData) && fundingData.length > 0) {
		return (
			<Card>
				<CardContent sx={{ overflowX: isMobile ? 'auto' : undefined }}>
					<Table sx={{ width: isMobile ? 600 : '100%' }}>
						<TableHead>
							<TableRow>
								<TableCell sx={{ color: 'text.secondary' }}>Security</TableCell>
								<TableCell sx={{ color: 'text.secondary' }}>Price/Share</TableCell>
								<TableCell sx={{ color: 'text.secondary' }}>Post-money valuation</TableCell>
								{/* <TableCell sx={{ color: 'text.secondary' }}>Shares Authorized</TableCell> */}
								<TableCell sx={{ color: 'text.secondary' }}>Date</TableCell>
								{/* <TableCell sx={{ color: 'text.secondary' }}>Note</TableCell> */}
							</TableRow>
						</TableHead>
						<TableBody>
							{fundingData.map((item, i) => (
								<TableRow key={i}>
									<TableCell>{item.security}</TableCell>
									<TableCell>{item.priceShare ? format('$.2f')(item.priceShare) : 'N/A'}</TableCell>
									<TableCell>
										{getPostMoneyValuationCellContent(item.postMoneyValuation, fundingData[i + 1]?.postMoneyValuation)}
									</TableCell>
									<TableCell>{timeFormat('%d %b, %Y')(item.date)}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</CardContent>
			</Card>
		);
	} else {
		return (
			<NotFoundContainer>
				<Stack spacing={3} alignItems={'center'}>
					<img src={'/illustrations/dark/notFound.svg'} style={{ height: 160 }} alt="Not found" />
					<Typography variant={'body2'} textAlign={'center'}>
						No funding data available
					</Typography>
				</Stack>
			</NotFoundContainer>
		);
	}
};

export default React.memo(FundingRounds);

import React, { useEffect, useRef } from 'react';
import { select } from 'd3';
import { useResizeObserver } from '../../../hooks';
import { Stack, Typography, useTheme } from '@mui/material';

function TradingActivity({ data, dimensions }) {
	const defaultTheme = useTheme();
	const svgRef = useRef();
	const wrapperRef = useRef();
	const wrapDim = useResizeObserver(wrapperRef);

	let { height, width } = dimensions;

	const ratio = data[0] / (data[0] + data[1]);

	useEffect(() => {
		const svg = select(svgRef.current);
		svg.selectAll('*').remove();

		svg.attr('height', height).attr('width', width);

		const mainGroup = svg.append('g').attr('transform', `translate(0,5)`);
		mainGroup
			.append('rect')
			.attr('height', height)
			.attr('width', ratio * width)
			.attr('fill', defaultTheme.palette.success.main)
			.attr('opacity', 0.7);

		mainGroup
			.append('rect')
			.attr('height', height)
			.attr('x', ratio * width + 2)
			.attr('width', width - ratio * width)
			.attr('fill', defaultTheme.palette.error.main)
			.attr('opacity', 0.7);
	}, [wrapDim, defaultTheme.palette.primary.main, height, ratio, width]);

	return (
		<Stack direction={'row'} alignItems={'baseline'} spacing={1} ref={wrapperRef}>
			<Typography variant={'body1'}>Buy {data[0]}%</Typography>
			<svg ref={svgRef}></svg>
			<Typography variant={'body1'}>Sell {data[1]}%</Typography>
		</Stack>
	);
}

export default TradingActivity;

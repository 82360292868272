import styled from 'styled-components';
import { Box, TextField } from '@mui/material';

export const ChatInput = styled(TextField).attrs({
	variant: 'filled',
	type: 'text',
	fullWidth: true,
	hiddenLabel: true,
	multiline: true,
	maxRows: 3,
	size: 'small',
})(() => {
	return {
		'& .MuiInputBase-root': {
			borderRadius: 20,
			'&::before': {
				content: 'unset',
			},
			'&::after': {
				content: 'unset',
			},
		},
	};
});

export const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export const FilePreviewContainer = styled(Box)(() => {
	return {
		position: 'absolute',
		top: -164,
		left: 0,
	};
});

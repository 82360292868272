import { Button, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EmptyContainer } from './referralPageView.styles';
import { useAuthContext } from '../../context';
import { useState } from 'react';
import { endpoints } from '../../utils';

const ReferralPageView = () => {
	const { user } = useAuthContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const baseUrl = window.location.origin;
	const referralLink = `${baseUrl}/?${endpoints.referralParam}=${user?.id}`;
	const [isCopied, setCopied] = useState(false);

	const copy = async () => {
		await navigator.clipboard.writeText(referralLink);
		setCopied(true);
	};

	return (
		<EmptyContainer>
			<Stack spacing={5} alignItems={'center'}>
				<img
					src={'/illustrations/dark/export.svg'}
					style={{ height: isMobile ? 150 : 183 }}
					alt="Share your referral"
				/>
				<Stack spacing={1} alignItems={'center'}>
					<Typography variant={'h5'}>Share your referral</Typography>
					<Typography variant={'body2'} textAlign={isMobile ? 'center' : undefined}>
						Help us grow the community on our platform by sharing this link with your colleagues.
					</Typography>
				</Stack>
				<TextField
					label="Referral link"
					value={referralLink}
					fullWidth
					variant="outlined"
					inputProps={{ readOnly: true }}
					InputProps={{
						endAdornment: (
							<Button
								size={isMobile ? 'large' : 'medium'}
								variant={'contained'}
								color={isCopied ? 'success' : 'primary'}
								sx={{ width: 84 }}
								onClick={copy}
							>
								{isCopied ? 'Copied' : 'Copy'}
							</Button>
						),
					}}
				/>
			</Stack>
		</EmptyContainer>
	);
};

export default ReferralPageView;

import { Uploader } from './styles';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useAuthContext, useModalContext, usePrivateAppContext } from '../../context';
import CompanyCard from '../CompanyCard';
import { Apartment, DeleteOutlined } from '@mui/icons-material';

const CompanyUploader = (props) => {
	const { selectedCompany, setSelectedCompany } = props;
	const { user } = useAuthContext();
	const [isHover, setIsHover] = useState(false);
	const { openModal, closeModal } = useModalContext();
	const { companies } = usePrivateAppContext();

	const onCompanyClick = (id) => {
		setSelectedCompany(companies.find((company) => company['Record Id'] === id));
		closeModal();
	};

	if (selectedCompany) {
		return (
			<Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
				<Box width={270}>
					<CompanyCard
						key={selectedCompany['Record Id']}
						id={selectedCompany['Record Id']}
						companyName={selectedCompany['Legal Name']}
						sectorIcon={Apartment}
						sectorName={selectedCompany.Sectors}
						valuation={selectedCompany['Post-Money Valuation']}
						elevation={3}
					/>
				</Box>
				<IconButton onClick={() => setSelectedCompany(undefined)} color={'error'}>
					<DeleteOutlined />
				</IconButton>
			</Stack>
		);
	}

	return (
		<Uploader
			onClick={() =>
				openModal({
					companies: companies,
					type: 'companies',
					onCompanyClick: onCompanyClick,
					favoriteCompanyIds: user?.fields.Follows,
				})
			}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
			elevation={isHover ? 1 : 0}
		>
			<Typography variant={'body1'} color={'text.secondary'}>
				Choose company...
			</Typography>
		</Uploader>
	);
};

export default CompanyUploader;

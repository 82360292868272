import * as Yup from 'yup';

export const initialValues = {
	stillInCompany: '',
	position: '',
	whenDidYouLeave: '',
	uploadDocuments: [],
	aonOrMin: '',
	minQty: '',
	gtcOrExpirationDate: '',
	expirationDate: '',
	companyInsider: '',
	companyInsiderEmployed: '',
	companyInsiderEmployedExplanation: '',
	companyInsiderControl: '',
	companyInsiderControlExplanation: '',
	companyInsiderPolitical: '',
	companyInsiderPoliticalExplanation: '',
	companyAffiliate: '',
	companyAffiliateEmployed: '',
	companyAffiliateEmployedExplanation: '',
	companyAffiliateControl: '',
	companyAffiliateControlExplanation: '',
	companyAffiliatePolitical: '',
	companyAffiliatePoliticalExplanation: '',
};

export const companyInsiderYes = 'Yes, I am a company insider';
export const companyAffiliateYes = 'Yes, I am affiliate';

export const schema = Yup.object({
	stillInCompany: Yup.string().required('Required'),
	position: Yup.string().when('stillInCompany', {
		is: (stillInCompany) => stillInCompany === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	whenDidYouLeave: Yup.string().when('stillInCompany', {
		is: (stillInCompany) => stillInCompany === 'No',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),

	/* Uploads */
	uploadDocuments: Yup.array().min(1, 'Required'),

	aonOrMin: Yup.string().required('Required'),
	minQty: Yup.string().when('aonOrMin', {
		is: (aonOrMin) => aonOrMin === 'Min',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	gtcOrExpirationDate: Yup.string().required('Required'),
	expirationDate: Yup.string().when('gtcOrExpirationDate', {
		is: (gtcOrExpirationDate) => gtcOrExpirationDate === 'Expiration Date',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),

	// companyInsider
	companyInsider: Yup.string().required('Required'),
	companyInsiderEmployed: Yup.string().when('companyInsider', {
		is: (companyInsider) => companyInsider === companyInsiderYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderEmployedExplanation: Yup.string().when('companyInsiderEmployed', {
		is: (companyInsiderEmployed) => companyInsiderEmployed === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderControl: Yup.string().when('companyInsider', {
		is: (companyInsider) => companyInsider === companyInsiderYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderControlExplanation: Yup.string().when('companyInsiderControl', {
		is: (companyInsiderControl) => companyInsiderControl === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderPolitical: Yup.string().when('companyInsider', {
		is: (companyInsider) => companyInsider === companyInsiderYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyInsiderPoliticalExplanation: Yup.string().when('companyInsiderPolitical', {
		is: (companyInsiderPolitical) => companyInsiderPolitical === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),

	// companyAffiliate
	companyAffiliate: Yup.string().required('Required'),
	companyAffiliateEmployed: Yup.string().when('companyAffiliate', {
		is: (companyAffiliate) => companyAffiliate === companyAffiliateYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliateEmployedExplanation: Yup.string().when('companyAffiliateEmployed', {
		is: (companyAffiliateEmployed) => companyAffiliateEmployed === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliateControl: Yup.string().when('companyAffiliate', {
		is: (companyAffiliate) => companyAffiliate === companyAffiliateYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliateControlExplanation: Yup.string().when('companyAffiliateControl', {
		is: (companyAffiliateControl) => companyAffiliateControl === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliatePolitical: Yup.string().when('companyAffiliate', {
		is: (companyAffiliate) => companyAffiliate === companyAffiliateYes,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	companyAffiliatePoliticalExplanation: Yup.string().when('companyAffiliatePolitical', {
		is: (companyAffiliatePolitical) => companyAffiliatePolitical === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
});

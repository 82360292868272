export default (
	<div>
		<h2>Risks and Special Considerations for Direct Share Acquisition:</h2>
		<ol>
			<li>
				<b>Negotiation Risks:</b>
				<ul>
					<li>
						Direct Negotiation Between Buyer and Seller: Direct Share Acquisitions involve negotiations directly between
						the buyer and seller, facilitated by ShareWell. There is a risk that the parties may not come to an
						agreement on transaction terms, potentially leading to a deal not being finalized.
					</li>
				</ul>
			</li>
			<li>
				<b>Seller's Decision Risk:</b>
				<ul>
					<li>
						Seller's Discretion to Proceed: Similar to all investment offerings, there is a risk that the seller may
						decide not to proceed with the sale even after agreeing to the terms. This discretionary element introduces
						uncertainty regarding the commitment of the seller to finalize the transaction.
					</li>
				</ul>
			</li>
			<li>
				<b>Right of First Refusal (ROFR) Risk:</b>
				<ul>
					<li>
						Higher ROFR Risk for New Buyers: Companies may have a Right of First Refusal (ROFR) in place, and there may
						be a higher risk of the company exercising this right if the buyer is new to the company's cap table. This
						could potentially impact the buyer's ability to acquire the desired shares.
					</li>
				</ul>
			</li>
			<li>
				<b>Transfer and Processing Fees:</b>
				<ul>
					<li>
						Company-imposed Fees: Companies may charge transfer or processing fees associated with share transactions.
						Buyers should be aware of any additional costs that the company may impose, impacting the overall cost of
						the transaction.
					</li>
				</ul>
			</li>
			<li>
				<b>Non-Anonymity of Transactions:</b>
				<ul>
					<li>
						Limited Anonymity: Unlike transactions on public markets, direct transactions through ShareWell do not
						provide complete anonymity. Both the buyer and seller are known parties in the transaction, and their
						identities are disclosed to each other and the company.
					</li>
				</ul>
			</li>
			<li>
				<b>Company Due Diligence on Buyers:</b>
				<ul>
					<li>
						Information Requirements: The company may request additional information about the buyer before allowing
						them to purchase shares. Buyers should be prepared to provide relevant details as part of the due diligence
						process initiated by the company.
					</li>
				</ul>
			</li>
		</ol>
		<h3>Conclusion:</h3>
		<p>
			Direct Share Acquisitions offer a unique avenue for investors but come with specific risks and considerations.
			Negotiation uncertainties, the seller's discretion, potential ROFR implications, company-imposed fees, limited
			anonymity, and buyer due diligence requirements are factors that investors should carefully assess when engaging
			in direct transactions. ShareWell aims to facilitate a transparent process, and investors are encouraged to
			conduct thorough due diligence and seek professional advice when necessary.
		</p>
	</div>
);

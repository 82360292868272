const pageParamName = 'page';
const fieldParamName = 'field';
const sortParamName = 'sort';

export const decodeFilterModel = (searchParams, columns, arrayKeys = []) => {
	const items = [];
	const allowedKeys = columns.map((column) => column.field);
	searchParams.forEach((value, key) => {
		if (key !== pageParamName && key !== fieldParamName && key !== sortParamName && allowedKeys.includes(key)) {
			let parsedValue = value;
			if (arrayKeys.includes(key)) {
				parsedValue = value.split(',');
			}
			const operator = columns.find((column) => column.field === key)?.filterOperators?.[0]?.value;
			items.push({ field: key, value: parsedValue, operator: operator });
		}
	});
	return { items };
};

// Function to decode the sort model, including only field and sort parameters
export const decodeSortModel = (searchParams, defaultSort) => {
	const sortModel = [];
	sortModel.push({
		field: searchParams.get(fieldParamName) ?? defaultSort.field,
		sort: searchParams.get(sortParamName) ?? defaultSort.sort,
	});

	return sortModel;
};

// Function to decode the pagination model, including only the page parameter
export const decodePaginationModel = (searchParams, defaultPaginationModel) => {
	let paginationModel = defaultPaginationModel; // Default to page 1 if not specified
	searchParams.forEach((value, key) => {
		if (key === pageParamName) {
			paginationModel[pageParamName] = parseInt(value, 10) - 1; // Convert the page value to an integer
		}
	});

	return paginationModel;
};

// Transform filter model items to key-value pairs
export const transformFilterModelToObject = (filterModel) => {
	return filterModel.items.reduce((acc, filter) => {
		if (filter.value !== undefined && filter.value !== null) {
			acc[filter.field] = Array.isArray(filter.value) ? filter.value.join(',') : filter.value;
		}
		return acc;
	}, {});
};

export const transformModelsToObject = (filterModel, paginationModel, sortModel) => {
	const result = {};

	// Transform filterModel
	if (filterModel?.items) {
		filterModel.items.forEach((filter) => {
			if (filter.value !== undefined && filter.value !== null && filter.value !== [] && filter.value !== '') {
				result[filter.field] = Array.isArray(filter.value) ? filter.value.join(',') : filter.value;
			}
		});
	}

	// Transform paginationModel
	if (paginationModel?.[pageParamName] !== undefined) {
		result[pageParamName] = paginationModel[pageParamName] + 1;
	}

	// Transform sortModel
	if (sortModel?.length) {
		sortModel.forEach((sort) => {
			if (sort[fieldParamName]) {
				result[fieldParamName] = sort[fieldParamName];
			}
			if (sort[sortParamName]) {
				result[sortParamName] = sort[sortParamName];
			}
		});
	}

	return result;
};

export const updateTableSettingObj = (user, tableName, settingField, settingValue) => {
	// Create a deep copy of the user object
	const updatedUser = JSON.parse(JSON.stringify(user));

	// Initialize Settings and tableSettings in the copied user object if they don't exist
	if (!updatedUser.fields) {
		updatedUser.fields = {};
	}
	if (!updatedUser.fields.Settings) {
		updatedUser.fields.Settings = { tableSettings: {} };
	}
	if (!updatedUser.fields.Settings.tableSettings) {
		updatedUser.fields.Settings.tableSettings = {};
	}

	// Create a deep copy of the tableSettings object from the updatedUser
	const updatedTableSettings = JSON.parse(JSON.stringify(updatedUser.fields.Settings.tableSettings));

	// Initialize the specific table settings if it doesn't exist
	if (!updatedTableSettings[tableName]) {
		updatedTableSettings[tableName] = {};
	}

	// Update the specific setting for the table in the copied object
	updatedTableSettings[tableName][settingField] = settingValue;

	// Update the Settings object with the modified tableSettings
	updatedUser.fields.Settings.tableSettings = updatedTableSettings;

	// Return the entire Settings object
	return { Settings: updatedUser.fields.Settings };
};

export const clearTableSettingObj = (user, tableName) => {
	// Create a deep copy of the user object
	const updatedUser = JSON.parse(JSON.stringify(user));

	// Initialize Settings and tableSettings in the copied user object if they don't exist
	if (!updatedUser.fields) {
		updatedUser.fields = {};
	}
	if (!updatedUser.fields.Settings) {
		updatedUser.fields.Settings = { tableSettings: {} };
	}
	if (!updatedUser.fields.Settings.tableSettings) {
		updatedUser.fields.Settings.tableSettings = {};
	}

	// Create a deep copy of the tableSettings object from the updatedUser
	const updatedTableSettings = JSON.parse(JSON.stringify(updatedUser.fields.Settings.tableSettings));
	updatedTableSettings[tableName] = {};
	// Update the Settings object with the modified tableSettings
	updatedUser.fields.Settings.tableSettings = updatedTableSettings;

	// Return the entire Settings object
	return { Settings: updatedUser.fields.Settings };
};

export const compareFilterModels = (model1, model2) => {
	const cleanModel = (model) =>
		model.items
			.map(({ field, value }) => ({ field, value })) // Keep only 'field' and 'value'
			.filter(({ value }) => value !== undefined && value !== null && value !== '') // Exclude filters with no value
			.sort((a, b) => a.field.localeCompare(b.field)); // Sort by field for consistent comparison

	const cleanModel1 = cleanModel(model1);
	const cleanModel2 = cleanModel(model2);

	return JSON.stringify(cleanModel1) === JSON.stringify(cleanModel2);
};

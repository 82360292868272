import { Autocomplete, Box, debounce, TextField, Typography } from '@mui/material';
import { useField } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import Grid from '@mui/material/Grid2';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBXXBpLbTxZDNFeGOcWZ0gkX6YYnn4aWCo';

const loadScript = (src, position, id) => {
	if (!position) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	script.src = src;
	position.appendChild(script);
};

const autocompleteService = { current: null };

const GoogleMapSelect = ({
	showAsterisk = true,
	handleChange,
	setFieldValue,
	name,
	label,
	value: valueProp,
	required,
	fullWidth,
	helperText,
	...props
}) => {
	const [, meta, helpers] = useField(name);
	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);
	const loaded = useRef(false);

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
				document.querySelector('head'),
				'google-maps'
			);
		}

		loaded.current = true;
	}

	const fetch = useMemo(
		() =>
			debounce((request, callback) => {
				autocompleteService.current.getPlacePredictions(request, callback);
			}, 400),
		[]
	);

	useEffect(() => {
		setInputValue(valueProp);
	}, [valueProp]);

	useEffect(() => {
		let active = true;

		if (!autocompleteService.current && window.google) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService();
		}
		if (!autocompleteService.current) {
			return undefined;
		}

		if (inputValue === '') {
			setOptions([]);
			return undefined;
		}

		fetch({ input: inputValue }, (results) => {
			if (active) {
				let newOptions = [];

				if (results) {
					newOptions = [...newOptions, ...results];
				}

				setOptions(newOptions);
			}
		});

		return () => {
			active = false;
		};
	}, [value, inputValue, fetch]);

	return (
		<Autocomplete
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
			filterOptions={(x) => x}
			isOptionEqualToValue={(option, value) => option.description === value.description}
			options={options}
			autoComplete
			includeInputInList
			value={value}
			inputValue={inputValue}
			noOptionsText="No locations"
			onChange={(event, newValue) => {
				setOptions(options);
				setValue(newValue);
				setFieldValue(name, newValue ? newValue.description : '');
			}}
			onInputChange={(event, newInputValue) => {
				if (event) {
					setInputValue(newInputValue);
				}
			}}
			onBlur={() => helpers.setTouched(true)}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						name={name}
						error={Boolean(meta.touched && meta.error)}
						helperText={meta.touched && meta.error ? meta.error : helperText}
						slotProps={{
							inputLabel: {
								required: showAsterisk && required,
							},
						}}
						label={label}
						required={required}
						fullWidth={fullWidth}
					/>
				);
			}}
			renderOption={(props, option) => {
				const matches = option.structured_formatting?.main_text_matched_substrings || [];

				const parts = parse(
					option.structured_formatting?.main_text,
					matches.map((match) => [match.offset, match.offset + match.length])
				);

				return (
					<li {...props} data-cy="gmap-option">
						<Grid container alignItems="center">
							<Grid sx={{ display: 'flex', width: 44 }}>
								<LocationOnIcon sx={{ color: 'text.secondary' }} />
							</Grid>
							<Grid sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
								{parts.map((part, index) => (
									<Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
										{part.text ?? option}
									</Box>
								))}

								<Typography variant="body2" color="text.secondary">
									{option.structured_formatting?.secondary_text}
								</Typography>
							</Grid>
						</Grid>
					</li>
				);
			}}
			{...props}
		/>
	);
};

export default GoogleMapSelect;

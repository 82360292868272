export default (
	<div>
		<h2>How to Value Private Company Shares?</h2>
		<p>
			Valuing private company shares is a nuanced process, combining elements of art and science. Private companies
			often lack the transparency of public companies, making valuation more intricate. While trade data isn't as
			readily available, several approaches can help arrive at a reasonable valuation:
		</p>
		<ol>
			<li>
				<b>Private Market Trading Activity:</b>
				<ul>
					<li>
						Private marketplaces, such as ShareWell, provide insights into private market trading activity. Observing
						transactions on secondary marketplaces can offer indications of the perceived value of shares in similar
						private companies.
					</li>
				</ul>
			</li>
			<li>
				<b>Recent Funding Valuations:</b>
				<ul>
					<li>
						The valuation of recent funding rounds can serve as a benchmark. Analyzing the terms of these rounds,
						including the pre-money and post-money valuations, provides a snapshot of how the market values the company.
					</li>
				</ul>
			</li>
			<li>
				<b>Comparable Company Valuations:</b>
				<ul>
					<li>
						Drawing parallels with comparable companies can be insightful. Analyze valuation histories and financial
						metrics of companies in the same industry or with similar business models to gauge the relative value of the
						target company.
					</li>
				</ul>
			</li>
			<li>
				<b>ShareWell's Proprietary Valuation Insights:</b>
				<ul>
					<li>
						ShareWell, as a private marketplace, offers proprietary valuation insights. These insights may encompass a
						combination of factors, including recent transaction data, market trends, and industry analyses,
						contributing to a more comprehensive view of the company's value.
					</li>
				</ul>
			</li>
			<li>
				<b>Consultation with ShareWell Account Manager:</b>
				<ul>
					<li>
						A ShareWell Account Manager plays a crucial role in helping investors navigate the valuation process. They
						can assist in gathering and interpreting relevant information, offering guidance on factors influencing
						valuation, and ensuring a well-informed assessment.
					</li>
				</ul>
			</li>
		</ol>
		<h3>Considerations for Valuation:</h3>
		<ul>
			<li>
				<b>Limited Transparency:</b>
				<ul>
					<li>
						Acknowledge the inherent limited transparency in private company information. The valuation process requires
						a careful examination of available data and an understanding of the factors influencing the company's value.
					</li>
				</ul>
			</li>
			<li>
				<b>Holistic Approach:</b>
				<ul>
					<li>
						Valuation is not solely based on financial metrics. A holistic approach, considering qualitative factors,
						market trends, and the company's growth prospects, contributes to a more comprehensive valuation.
					</li>
				</ul>
			</li>
		</ul>
		<div>
			<h3>Conclusion:</h3>
			<p>
				Valuing private company shares demands a combination of research, analysis, and industry knowledge. Leveraging
				information from private market trading activity, recent funding rounds, comparable companies, and ShareWell's
				proprietary insights provides a foundation for making informed valuation assessments. Collaborating with a
				ShareWell Account Manager enhances the valuation process, ensuring that investors have access to comprehensive
				and up-to-date information for their decision-making.
			</p>
		</div>
	</div>
);

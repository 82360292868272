export default (
	<div>
		<h2>What is the difference between Direct Ownership and investing in a fund?</h2>
		<p>
			Direct Ownership, Enhanced Control, and Visibility: When directly acquiring blocks of shares in pre-IPO companies,
			investors may benefit from a heightened level of control and visibility over their investments. Unlike investing
			in a fund, where decisions are made collectively by fund managers, direct ownership allows investors to directly
			influence the strategic direction of their portfolio. This level of control empowers investors to align their
			investments with their specific goals and preferences.
		</p>
		<div>
			<h3>Cost-Effectiveness and Reduced Fee Structures:</h3>
			<p>
				Directly acquiring blocks of shares often translates into reduced fee structures compared to investing in a
				fund. Traditional funds typically incur management fees, performance fees (carry), and other associated costs.
				By bypassing these layers of fees, investors potentially retain a larger portion of their investment capital,
				which could ensure that a greater share of resources is actively working towards potential returns.
			</p>
		</div>
		<div>
			<h3>Long-Term Expense Considerations:</h3>
			<p>
				Investing in someone else's fund may involve long-term expenses that can erode overall returns. Management fees,
				carried interest (carry), and other fund-related costs can accumulate over time, impacting the profitability of
				the investment. In contrast, direct ownership minimizes these ongoing expenses, providing investors with a
				cost-effective approach to maximize the long-term value of their portfolio.
			</p>
		</div>
		<div>
			<h3>Potential Disadvantages:</h3>
			<p>
				While direct ownership offers numerous advantages, it's important to acknowledge potential disadvantages, such
				as the need for a more hands-on approach to portfolio management. Unlike funds where professional managers
				handle the day-to-day decisions, direct investors bear the responsibility of staying informed and actively
				managing their portfolio. This requires a commitment of time and expertise, which may be a consideration for
				some investors.
			</p>
		</div>
		<p>
			In conclusion, the choice between direct ownership and investing in a fund involves weighing the benefits of
			control, visibility, and cost-effectiveness against the potential disadvantages of increased management
			responsibility. It's a decision that should align with each investor's individual preferences, risk tolerance, and
			investment objectives.
		</p>
	</div>
);

import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
	InputAdornment,
	Menu,
	MenuItem,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Form, Formik } from 'formik';
import { initialValues, schema } from './inquiryForm.constants';
import { toast } from 'react-toastify';
import { apiClient, convertNumericalQuantityToNumber, endpoints } from '../../../../utils';
import PageTitle from '../../../../components/PageTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import ThankYouBlock from '../../../../components/ThankYouBlock';
import InputField from '../../../../components/FormComponents/InputField';
import { ExpandMore, HelpOutline } from '@mui/icons-material';
import { useAuthContext, usePrivateAppContext } from '../../../../context';
import { useNavigate, useParams } from 'react-router-dom';
import { InputButtonWrapper } from './style';
import { buyerSellerOptions } from '../../../../utils/constants';
import { constants } from '../../../../utils/constants';
import InquiryPreview from '../../../../components/InquiryPreview';
import { strings } from '../../../../utils/strings';

const InquiryInstitutionalPage = (props) => {
	const { inquiryId } = useParams();
	const { questionnaire, companyData, isReadOnly = false, selectedInquiries } = props;
	const { inquiries, setInquiries, user } = useAuthContext();
	const { current } = usePrivateAppContext();
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [isButtonLoading, setButtonLoading] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	const [numericalQuantitiesMenuAnchorEl, setNumericalQuantitiesMenuAnchorEl] = useState(null);
	const isNumericalQuantitiesMenuOpen = Boolean(numericalQuantitiesMenuAnchorEl);

	const openNumericalQuantitiesMenu = (event) => {
		setNumericalQuantitiesMenuAnchorEl(event.currentTarget);
	};

	const closeNumericalQuantitiesMenu = () => {
		setNumericalQuantitiesMenuAnchorEl(null);
	};

	useEffect(() => {
		if (window.location.pathname.includes('success') && !isSuccess) {
			navigate(window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')));
		}
	}, [isSuccess, navigate]);

	// if there's selectedInquiries in the app context, we need to initialize the form value blockSizeSizeMin to the sum of all the inquiries (Block Size Ticket)
	useEffect(() => {
		if (current?.selectedInquiries && current?.selectedInquiries.length > 0) {
			const sum = current?.selectedInquiries.reduce((acc, item) => acc + item.fields['Block Size Ticket'], 0);
			initialValues.blockSizeSizeMin = sum / 1000000;

			//also, set blockSizeSharesMin to the sum of all the inquiries (QTY)
			initialValues.blockSizeSharesMin = current?.selectedInquiries.reduce((acc, item) => {
				let qty = item.fields['QTY'] || 0;
				return acc + qty;
			}, 0);
		}
	}, [current?.selectedInquiries]);

	const handleSubmit = async (values) => {
		setButtonLoading(true);
		const questionnaireParams = { ...values };

		if (questionnaireParams.priceType === 'Valuation') {
			questionnaireParams.priceNumber = convertNumericalQuantityToNumber(
				questionnaireParams.priceNumberType,
				questionnaireParams.priceNumber
			);
		}
		if (questionnaireParams.blockSizeSizeMin) {
			questionnaireParams.blockSizeSizeMin = convertNumericalQuantityToNumber(
				questionnaireParams.blockSizeSizeMinType,
				questionnaireParams.blockSizeSizeMin
			);
		}

		if (questionnaireParams.blockSizeSizeMax) {
			questionnaireParams.blockSizeSizeMax = convertNumericalQuantityToNumber(
				questionnaireParams.blockSizeSizeMaxType,
				questionnaireParams.blockSizeSizeMax
			);
		}

		const questionnairePayload = JSON.stringify(questionnaireParams);

		const payload = {
			action: questionnaireParams.action,
			companyName: companyData.companyName,
			companyId: companyData.companyId,
			inquiryIds: selectedInquiries.map((item) => item.fields.RecId),
			inquiryId: inquiryId,
			questionnaire: questionnairePayload,

			blockSizeFrom: questionnaireParams.blockSizeSizeMin,
			blockSizeTo: questionnaireParams.blockSizeSizeMax,
			numberOfSharesFrom: questionnaireParams.blockSizeSharesMin,
			numberOfSharesTo: questionnaireParams.blockSizeSharesMax,
			sharePrice: questionnaire.priceType !== 'Valuation' ? questionnaireParams.priceNumber : null,
			valuation: questionnaire.priceType === 'Valuation' ? questionnaireParams.priceNumber : null,

			marketVisibility: questionnaireParams.marketVisibility,
			structure: questionnaireParams.structure,
			securityType: questionnaireParams.securityType,

			referrer: window.location.origin,
		};

		try {
			let res;

			if (inquiryId) {
				res = await apiClient.post(endpoints.patchInstInquiry, {
					inquiryId: inquiryId,
					updatedFields: payload,
				});
			} else {
				res = await apiClient.post(endpoints.logInstitutionalInquiry, payload);
			}

			if (res.status === 200) {
				setSuccess(true);

				if (!window.location.pathname.includes('success')) {
					navigate(window.location.pathname + '/success');
				}

				if (!inquiryId) {
					setInquiries([res.data, ...inquiries]);
				}
			}
		} catch (err) {
			toast.error(strings.errorMessages.form.submit);
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<>
			<PageTitle
				isOnlyBreadcrumbs={true}
				breadcrumbs={
					isReadOnly
						? [
								{
									title: 'Home',
									link: '/home',
								},
								{
									title: 'Inquiry',
								},
						  ]
						: [
								{
									title: 'Companies',
									link: '/companies',
								},
								{
									title: companyData.companyName,
									link: `/company/${companyData.companyId}`,
								},
								{
									title: 'Inquiry',
								},
						  ]
				}
			/>
			<Formik
				enableReinitialize={true}
				initialValues={{
					...initialValues,
					...questionnaire,
				}}
				onSubmit={handleSubmit}
				validationSchema={schema}
			>
				{({ setFieldValue, isSubmitting, values, errors, touched }) => {
					const inquiryPreview = (
						<InquiryPreview
							companyName={companyData.companyName}
							pps={values.priceNumber}
							actionValue={values.action}
							shareNum={
								values.blockSizeMin && values.blockSizeMax ? `${values.blockSizeMin} - ${values.blockSizeMax}` : '-'
							}
						/>
					);

					return (
						<Grid container spacing={3}>
							{!user?.isLevel3 && !isMobile && <Grid size={5} />}
							{user?.isLevel3 && !isMobile && (
								<Grid size={5}>
									{inquiryPreview}
								</Grid>
							)}
							{!isMobile && (
								<Grid size={1}>
									<Divider orientation={'vertical'} sx={{ width: 'calc(100% / 2)' }} />
								</Grid>
							)}
							{isSuccess ? (
								<Grid size={isMobile ? 12 : 6} marginTop={'60px'} marginBottom={'60px'}>
									<ThankYouBlock
										title={'Thank you for your inquiry!'}
										description={'You will receive email with detailed information.'}
										buttonText={'home'}
										buttonLink={'/'}
									/>
								</Grid>
							) : (
								<Grid size={isMobile ? 12 : 6}>
									<Stack spacing={isMobile ? 3 : 6}>
										{!isReadOnly && (
											<Stack spacing={'6px'}>
												<Stack direction={'row'} spacing={1} alignItems={'center'}>
													<Typography variant="h5">{`${
														inquiryId ? 'Edit' : 'Add a new'
													} Bid or Offer indication`}</Typography>
													<Tooltip
														title={'This is a non-binding indication of potential interest'}
														placement="top"
														arrow
													>
														<HelpOutline color={'disabled'} sx={{ fontSize: 20 }} />
													</Tooltip>
												</Stack>
												<Typography variant="body2" color={'text.secondary'}>
													Let us know a few more details and we will take it from here.
												</Typography>
											</Stack>
										)}
										{user?.isLevel3 && isMobile && inquiryPreview}
										<Form>
											<Stack spacing={isMobile ? 3 : 6}>
												<Box>
													<Grid container spacing={[3, '20px']}>
														<Grid size={12}>
															<FormControl component="fieldset" error={touched.action && Boolean(errors.action)}>
																<FormLabel component="legend">
																	Please indicate whether this is going to be a Buy (a Bid to purchase) or a Sell (an
																	Offer to sell)
																</FormLabel>
																<RadioGroup
																	required
																	name={'action'}
																	value={values.action}
																	onChange={(event) => {
																		setFieldValue('action', event.currentTarget.value);
																	}}
																>
																	{buyerSellerOptions.map((item) => (
																		<FormControlLabel
																			key={item.value}
																			value={item.value}
																			label={item.label}
																			control={<Radio disabled={isSubmitting || isReadOnly} />}
																			disabled={isSubmitting || isReadOnly}
																		/>
																	))}
																</RadioGroup>
																{touched.action && errors.action && <FormHelperText>{errors.action}</FormHelperText>}
															</FormControl>
														</Grid>
														<Grid size={12}>
															<FormControl
																component="fieldset"
																error={touched.marketVisibility && Boolean(errors.marketVisibility)}
															>
																<Box>
																	<FormLabel>
																		Let us know the level of market visibility you are looking for or wish to avoid
																	</FormLabel>
																	<Tooltip
																		title={
																			'In either case we will NOT be re representing your bid outside of ShareWell'
																		}
																		placement="top"
																		arrow
																	>
																		<HelpOutline
																			color={'disabled'}
																			sx={{ fontSize: 20, ml: '6px', verticalAlign: 'text-bottom' }}
																		/>
																	</Tooltip>
																</Box>
																<RadioGroup
																	required
																	name={'marketVisibility'}
																	value={values.marketVisibility}
																	onChange={(event) => {
																		setFieldValue('marketVisibility', event.currentTarget.value);
																	}}
																>
																	<FormControlLabel
																		label={
																			<Box>
																				Live market
																				<Tooltip
																					title={
																						'Live Market is where Bids and Offers are displayed for those that have access on ShareWell'
																					}
																					placement="top"
																					arrow
																				>
																					<HelpOutline
																						color={'disabled'}
																						sx={{ fontSize: 20, ml: '6px', verticalAlign: 'text-top' }}
																					/>
																				</Tooltip>
																			</Box>
																		}
																		value={'Live market'}
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																	<FormControlLabel
																		label={
																			<Box>
																				Darkpool
																				<Tooltip
																					title="Dark Pool is setting on ShareWell where only your Account Manager (and supporting staff) have access to your Bid or Offer. This can be selected where the client does not wish the indication to be seen by counter parties and or where regulations such as SEC Rule 15c2-1 apply."
																					placement="top"
																					arrow
																				>
																					<HelpOutline
																						color={'disabled'}
																						sx={{ fontSize: 20, ml: '6px', verticalAlign: 'text-top' }}
																					/>
																				</Tooltip>
																			</Box>
																		}
																		value={'Darkpool'}
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																</RadioGroup>
																{touched.marketVisibility && errors.marketVisibility && (
																	<FormHelperText>{errors.marketVisibility}</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid size={12}>
															<FormControl
																fullWidth
																component="fieldset"
																error={touched.priceType && Boolean(errors.priceType)}
															>
																<Box>
																	<FormLabel>Please indicate the Per Share Price</FormLabel>
																	<Tooltip
																		title={'This represents in the case of a Bid or Offer the pre-fee price per share'}
																		placement="top"
																		arrow
																	>
																		<HelpOutline
																			color={'disabled'}
																			sx={{ fontSize: 20, ml: '6px', verticalAlign: 'text-bottom' }}
																		/>
																	</Tooltip>
																</Box>
																<RadioGroup
																	required
																	name={'priceType'}
																	value={values.priceType}
																	onChange={(event) => {
																		setFieldValue('priceType', event.currentTarget.value);
																	}}
																>
																	{constants.INQUIRY.PRICE_TYPE.map((item) => (
																		<FormControlLabel
																			key={item}
																			value={item}
																			label={item}
																			control={<Radio disabled={isSubmitting || isReadOnly} />}
																			disabled={isSubmitting || isReadOnly || item === 'Valuation'}
																		/>
																	))}
																</RadioGroup>
																{touched.priceType && errors.priceType && (
																	<FormHelperText>{errors.priceType}</FormHelperText>
																)}
																{values.priceType === 'Valuation' ? (
																	<InputButtonWrapper
																		$error={Boolean(touched.blockSizeSizeMin && errors.blockSizeSizeMin)}
																	>
																		<InputField
																			name="priceNumber"
																			type={'number'}
																			inputProps={{ min: 0, step: 'any' }}
																			disabled={isSubmitting || isReadOnly || values.priceType !== 'Valuation'}
																			required
																			showAsterisk={false}
																			InputProps={{
																				startAdornment: <InputAdornment position="start">$</InputAdornment>,
																			}}
																		/>
																		<Button
																			variant={'outlined'}
																			name={'priceNumberType'}
																			endIcon={<ExpandMore />}
																			onClick={openNumericalQuantitiesMenu}
																			disabled={isSubmitting || isReadOnly || values.priceType !== 'Valuation'}
																		>
																			{values.priceNumberType}
																		</Button>
																	</InputButtonWrapper>
																) : (
																	<InputField
																		fullWidth
																		name="priceNumber"
																		type={'number'}
																		inputProps={{ min: 0, step: 'any' }}
																		disabled={isSubmitting || isReadOnly || !Boolean(values.priceType)}
																		required
																		showAsterisk={false}
																		InputProps={{
																			startAdornment: <InputAdornment position="start">$</InputAdornment>,
																		}}
																	/>
																)}
															</FormControl>
														</Grid>
														<Grid size={12}>
															<FormControl
																fullWidth
																component="fieldset"
																error={touched.blockSizeType && Boolean(errors.blockSizeType)}
															>
																<FormLabel>
																	Please specify the investment range in block size or number of shares
																</FormLabel>
																<RadioGroup
																	required
																	name={'blockSizeType'}
																	value={values.blockSizeType}
																	onChange={(event) => {
																		setFieldValue('blockSizeType', event.currentTarget.value);
																	}}
																>
																	<FormControlLabel
																		key={'Size'}
																		value={'Size'}
																		label={
																			<Box>
																				Size
																				<Tooltip
																					title="Minimum represents the smallest individual block of shares you prefer to transact. Maximum represents the total size of the bid or offer. Sometimes those numbers will be the same depending on preferences."
																					placement="top"
																					arrow
																				>
																					<HelpOutline
																						color={'disabled'}
																						sx={{ fontSize: 20, ml: '6px', verticalAlign: 'text-top' }}
																					/>
																				</Tooltip>
																			</Box>
																		}
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>

																	<Stack
																		spacing={2}
																		direction={isMobile ? 'column' : 'row'}
																		pt={1}
																		pb={1}
																		alignItems={'flex-start'}
																	>
																		<InputButtonWrapper
																			$error={Boolean(touched.blockSizeSizeMin && errors.blockSizeSizeMin)}
																		>
																			<InputField
																				name="blockSizeSizeMin"
																				type={'number'}
																				inputProps={{ min: 0, step: 'any' }}
																				label={'Min'}
																				disabled={isSubmitting || isReadOnly || values.blockSizeType !== 'Size'}
																				required
																				showAsterisk={false}
																				InputProps={{
																					startAdornment: <InputAdornment position="start">$</InputAdornment>,
																				}}
																			/>
																			<Button
																				variant={'outlined'}
																				name={'blockSizeSizeMinType'}
																				endIcon={<ExpandMore />}
																				onClick={openNumericalQuantitiesMenu}
																				disabled={isSubmitting || isReadOnly || values.blockSizeType !== 'Size'}
																			>
																				{values.blockSizeSizeMinType}
																			</Button>
																		</InputButtonWrapper>
																		<InputButtonWrapper
																			$error={Boolean(touched.blockSizeSizeMax && errors.blockSizeSizeMax)}
																		>
																			<InputField
																				name="blockSizeSizeMax"
																				type={'number'}
																				inputProps={{ min: 0, step: 'any' }}
																				label={'Max'}
																				disabled={isSubmitting || isReadOnly || values.blockSizeType !== 'Size'}
																				required
																				showAsterisk={false}
																				InputProps={{
																					startAdornment: <InputAdornment position="start">$</InputAdornment>,
																				}}
																			/>
																			<Button
																				variant={'outlined'}
																				endIcon={<ExpandMore />}
																				name={'blockSizeSizeMaxType'}
																				onClick={openNumericalQuantitiesMenu}
																				disabled={isSubmitting || isReadOnly || values.blockSizeType !== 'Size'}
																			>
																				{values.blockSizeSizeMaxType}
																			</Button>
																		</InputButtonWrapper>
																	</Stack>

																	<FormControlLabel
																		key={'Shares'}
																		value={'Shares'}
																		label={
																			<Box>
																				Shares
																				<Tooltip
																					title="Minimum represents the smallest share count you prefer to transact. Maximum represents the total share count you prefer. Sometimes those numbers will be the same depending on preferences."
																					placement="top"
																					arrow
																				>
																					<HelpOutline
																						color={'disabled'}
																						sx={{ fontSize: 20, ml: '6px', verticalAlign: 'text-top' }}
																					/>
																				</Tooltip>
																			</Box>
																		}
																		control={<Radio disabled={isSubmitting || isReadOnly} />}
																		disabled={isSubmitting || isReadOnly}
																	/>
																	<Stack spacing={2} direction={'row'} pt={1} alignItems={'flex-start'}>
																		<InputField
																			name="blockSizeSharesMin"
																			fullWidth
																			type={'number'}
																			inputProps={{ min: 0 }}
																			label={'Min'}
																			disabled={isSubmitting || isReadOnly || values.blockSizeType !== 'Shares'}
																			required
																			showAsterisk={false}
																		/>
																		<InputField
																			name="blockSizeSharesMax"
																			fullWidth
																			type={'number'}
																			inputProps={{ min: 0 }}
																			label={'Max'}
																			disabled={isSubmitting || isReadOnly || values.blockSizeType !== 'Shares'}
																			required
																			showAsterisk={false}
																		/>
																	</Stack>
																</RadioGroup>
																{touched.blockSizeType && errors.blockSizeType && (
																	<FormHelperText>{errors.blockSizeType}</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid size={12}>
															<FormControl component="fieldset" error={touched.structure && Boolean(errors.structure)}>
																<FormLabel component="legend">Please indicate which structure(s) apply</FormLabel>
																<FormGroup required name={'structure'}>
																	{constants.INQUIRY.STRUCTURE.map((item) => (
																		<FormControlLabel
																			key={item}
																			label={item}
																			control={
																				<Checkbox
																					disabled={isSubmitting || isReadOnly}
																					checked={values.structure.includes(item)}
																					onChange={(e) => {
																						if (e.target.checked) {
																							setFieldValue('structure', [...values.structure, item]);
																						} else {
																							setFieldValue(
																								'structure',
																								values.structure.filter((itemToFilter) => itemToFilter !== item)
																							);
																						}
																					}}
																				/>
																			}
																			disabled={isSubmitting || isReadOnly}
																		/>
																	))}
																</FormGroup>
																{touched.structure && errors.structure && (
																	<FormHelperText>{errors.structure}</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid size={12}>
															<FormControl
																component="fieldset"
																error={touched.securityType && Boolean(errors.securityType)}
															>
																<FormLabel component="legend">
																	Please indicate the security type(s) you prefer
																</FormLabel>
																<FormGroup required name={'securityType'}>
																	{constants.INQUIRY.SECURITY_TYPE.map((item) => (
																		<FormControlLabel
																			key={item}
																			label={item}
																			control={
																				<Checkbox
																					disabled={isSubmitting || isReadOnly}
																					checked={values.securityType.includes(item)}
																					onChange={(e) => {
																						if (e.target.checked) {
																							setFieldValue('securityType', [...values.securityType, item]);
																						} else {
																							setFieldValue(
																								'securityType',
																								values.securityType.filter((itemToFilter) => itemToFilter !== item)
																							);
																						}
																					}}
																				/>
																			}
																			disabled={isSubmitting || isReadOnly}
																		/>
																	))}
																</FormGroup>
																{touched.securityType && errors.securityType && (
																	<FormHelperText>{errors.securityType}</FormHelperText>
																)}
															</FormControl>
														</Grid>
													</Grid>
												</Box>

												{!isReadOnly && (
													<LoadingButton
														variant="contained"
														type="submit"
														sx={{ width: isMobile ? '100%' : '50%' }}
														size={'large'}
														loading={isButtonLoading}
														endIcon={<></>}
														loadingPosition={'end'}
													>
														{`${inquiryId ? 'Update' : 'Send'} Inquiry`}
													</LoadingButton>
												)}
											</Stack>

											<Menu
												anchorEl={numericalQuantitiesMenuAnchorEl}
												open={isNumericalQuantitiesMenuOpen}
												onClose={closeNumericalQuantitiesMenu}
											>
												{constants.INQUIRY.NUMERICAL_QUANTITIES.map((item) => {
													return (
														<MenuItem
															key={item}
															onClick={() => {
																setFieldValue(numericalQuantitiesMenuAnchorEl.name, item);
																closeNumericalQuantitiesMenu();
															}}
														>
															{item}
														</MenuItem>
													);
												})}
											</Menu>
										</Form>
									</Stack>
								</Grid>
							)}
						</Grid>
					);
				}}
			</Formik>
		</>
	);
};

export default InquiryInstitutionalPage;

import { Divider, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format, mean, min, max } from 'd3';
import { InstitutionalInfoFieldWrapper } from '../style';
import React from 'react';
import { HelpOutline } from '@mui/icons-material';

const InquiriesInfo = ({ inquiries }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const buyInquiries = inquiries.filter((item) => item.fields.Action === 'buy');
	const sellInquiries = inquiries.filter((item) => item.fields.Action === 'sell');

	const getBlockSize = (inquiries) => {
		const blockSize = inquiries.reduce((acc, item) => acc + item.fields['Block Size Ticket'], 0);
		return blockSize ? format('$,.0f')(blockSize) : 'N/A';
	};

	const getAverageBlockSize = (inquirires) => {
		const avg = mean(inquirires, (d) => d.fields['Block Size Ticket']);
		return avg ? format('$,.0f')(avg) : 'N/A';
	};

	const getAvg = (inquiries) => {
		const avg = mean(inquiries, (d) => d.fields['Price']);
		return avg ? format('$,.2f')(avg) : 'N/A';
	};

	const getMin = (inquiries) => {
		const minPrice = min(inquiries, (d) => d.fields['Price']);
		return minPrice ? format('$,.2f')(minPrice) : 'N/A';
	};

	const getMax = (inquiries) => {
		const maxPrice = max(inquiries, (d) => d.fields['Price']);
		return maxPrice ? format('$,.2f')(maxPrice) : 'N/A';
	};

	const renderField = (label, value, isEven, tooltip) => {
		return (
			<InstitutionalInfoFieldWrapper $even={isEven} minHeight={'2.5em'}>
				<Stack direction={'row'} alignItems={'center'} spacing={1}>
					<Typography variant={'body1'} color={'text.secondary'} alignItems={'end'}>
						{label}
					</Typography>
					{tooltip && (
						<Tooltip placement="top" title={tooltip}>
							<HelpOutline sx={{ fontSize: '16px', color: 'text.disabled' }} />
						</Tooltip>
					)}
				</Stack>
				<Typography variant={'body1'}>{value}</Typography>
			</InstitutionalInfoFieldWrapper>
		);
	};

	const renderFields = (inquirires) => {
		return (
			<>
				{renderField('Inquiries #', inquirires.length, true, 'Number of inquiries / individual blocks live')}
				{renderField('Total Block Size $', getBlockSize(inquirires), false, 'Aggregate of all live blocks')}
				{renderField('Average Block Size $', getAverageBlockSize(inquirires), true, 'Average size block size')}
				{renderField(
					'Average Ticket (PPS) $',
					getAvg(inquirires),
					false,
					'Average price per share (PPS) across all live blocks'
				)}
				{renderField('Min Ticket (PPS) $', getMin(inquirires), true, 'Minimum price per share (PPS) of all tickets')}
				{renderField('Max Ticket (PPS) $', getMax(inquirires), false, 'Maximum price per share (PPS) of all tickets')}
			</>
		);
	};

	return (
		<Stack spacing={isMobile ? 3 : '40px'} direction={isMobile ? 'column' : 'row'}>
			<Stack spacing={0} direction={'column'} flex={1}>
				<Typography variant={'h5'} marginLeft={'12px'} marginBottom={'16px'}>
					Bids
				</Typography>
				{renderFields(buyInquiries)}
			</Stack>
			<Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />
			<Stack spacing={0} direction={'column'} flex={1}>
				<Typography variant={'h5'} marginLeft={'12px'} marginBottom={'16px'}>
					Offers
				</Typography>
				{renderFields(sellInquiries)}
			</Stack>
		</Stack>
	);
};

export default InquiriesInfo;

import * as Yup from 'yup';

export const initialValues = {
	Name: '',
	Address: '',
	'Signatory Name': '',
	'Signatory Email': '',
	'Signatory Title': '',
	COI: [],
};

export const schema = Yup.object({
	Name: Yup.string().required('Required'),
	Address: Yup.string().required('Required'),
	'Signatory Name': Yup.string().required('Required'),
	'Signatory Email': Yup.string().required('Required'),
	'Signatory Title': Yup.string().required('Required'),
	COI: Yup.array().min(1, 'Required'),
});

import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { HelpOutline } from '@mui/icons-material';
import InputField from '../FormComponents/InputField';
import Checkbox from '../FormComponents/Checkbox';
import CountrySelect from '../FormComponents/CountrySelect';
import { timeFormat } from 'd3';
import InfoBlock from '../InfoBlock';
import FileUploadInput from '../FormComponents/FileUploadInput';
import GoogleMapSelect from '../FormComponents/GoogleMapSelect';

const FormFields = ({ values, locked, handleChange, isSubmitting, setFieldValue, countries, errors, touched }) => {
	const renderExplanationInput = (name) => (
		<Grid size={12}>
			<InputField
				label="Provide a written explanation"
				multiline
				fullWidth
				rows={4}
				name={name}
				disabled={locked || isSubmitting}
				required
				showAsterisk={false}
			/>
		</Grid>
	);

	return (
		<>
			<Grid size={12}>
				<InfoBlock title={'General information'}>
					{/* Middle Names */}
					<Grid size={12}>
						<InputField
							label={'Middle Names'}
							name="middleNames"
							fullWidth
							disabled={!values.hasMiddleNames || locked || isSubmitting}
							required={values.hasMiddleNames}
							showAsterisk={false}
						/>
					</Grid>
					<Grid size={12} mt={-2}>
						<Checkbox
							name="hasMiddleNames"
							label="I don't have middle names"
							checked={!values.hasMiddleNames}
							onChange={(e) => {
								setFieldValue('hasMiddleNames', !e.currentTarget.checked);
								e.currentTarget.checked && setFieldValue('middleNames', '', false);
							}}
							disabled={locked || isSubmitting}
						/>
					</Grid>
					{/* Phone Number */}
					<Grid size={12}>
						<InputField
							label={'Phone Number'}
							required
							showAsterisk={false}
							name="phoneNumber"
							type="tel"
							pattern="[0-9]*"
							fullWidth
							disabled={locked || isSubmitting}
						/>
					</Grid>
					{/* SSN (or Tax ID) */}
					<Grid size={12}>
						<Stack spacing={1} direction={'row'} alignItems={'center'}>
							<InputField
								label={'SSN (or Tax ID)'}
								required
								showAsterisk={false}
								name="ssnTaxId"
								type="text"
								pattern="[0-9]*"
								fullWidth
								disabled={locked || isSubmitting}
							/>
							<Tooltip
								placement="top"
								title="A Social Security Number or Tax ID is required as part of our compliance obligations under SEC/FINRA rules governing the collection of personal (or entity) information relating to KYC/AML for a securities transaction."
							>
								<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
							</Tooltip>
						</Stack>
					</Grid>
					{/* Date of birth */}
					<Grid size={12}>
						<InputField
							label={'Date of birth'}
							required
							showAsterisk={false}
							name="birthDate"
							value={typeof values.birthDate === 'object' ? timeFormat('%Y-%m-%d')(values.birthDate) : values.birthDate}
							fullWidth
							disabled={locked || isSubmitting}
							type="date"
							InputLabelProps={{
								shrink: true,
								required: false,
							}}
							InputProps={{ inputProps: { max: new Date().toISOString().split('T')[0] } }}
						/>
					</Grid>
					{/* Are you a US citizen? */}
					<Grid size={12}>
						<FormControl component="fieldset" error={touched.usCitizen && Boolean(errors.usCitizen)}>
							<FormLabel id="us-citizen-label">Are you a US citizen?</FormLabel>
							<RadioGroup
								required
								name="usCitizen"
								value={values.usCitizen}
								onChange={(event) => {
									setFieldValue('usCitizen', event.currentTarget.value);
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.usCitizen && errors.usCitizen && <FormHelperText>{errors.usCitizen}</FormHelperText>}
						</FormControl>
					</Grid>
					{values.usCitizen === 'No' && (
						<Grid size={12}>
							<FormControl fullWidth>
								<CountrySelect
									showAsterisk={false}
									label="Your country"
									name="citizenship"
									value={values.citizenship}
									required
									aria-labelledby="citizenship-selector-label"
									options={countries}
									handleChange={handleChange}
									setFieldValue={setFieldValue}
									disabled={locked || isSubmitting}
									fullWidth
								/>
							</FormControl>
						</Grid>
					)}
					{/* What type of ID */}
					<Grid size={12}>
						<FormControl component="fieldset" error={touched.idPassport && Boolean(errors.idPassport)}>
							<FormLabel id="idPassport-label">Select identification document</FormLabel>
							<RadioGroup
								required
								name="idPassport"
								value={values.idPassport}
								onChange={(event) => {
									setFieldValue('idPassport', event.currentTarget.value);
								}}
							>
								<FormControlLabel
									value="ID card"
									label="ID card"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="Passport"
									label="Passport"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="Driver's License"
									label="Driver's License"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.idPassport && errors.idPassport && <FormHelperText>{errors.idPassport}</FormHelperText>}
						</FormControl>
					</Grid>
					{values.idPassport !== '' && (
						<Grid size={12}>
							<FileUploadInput
								label="Document photo"
								id="upload-documents"
								name="uploadDocuments"
								disabled={locked || isSubmitting}
								setFieldValue={setFieldValue}
								value={values.uploadDocuments}
								showAsterisk={false}
								fullWidth
								error={touched.uploadDocuments && Boolean(errors.uploadDocuments)}
								helperText={
									touched.uploadDocuments && Boolean(errors.uploadDocuments) ? errors.uploadDocuments : undefined
								}
							/>
						</Grid>
					)}
					{/* Are you currently associated with a Broker-Dealer? */}
					<Grid size={12}>
						<FormControl
							component="fieldset"
							error={touched.brokerDealerAssociated && Boolean(errors.brokerDealerAssociated)}
						>
							<Stack spacing={1} direction={'row'} alignItems={'center'}>
								<FormLabel id="broker-dealer-associated-label">
									Are you currently associated with a Broker-Dealer?
								</FormLabel>
								<Tooltip
									placement="top"
									title="A broker-dealer firm is in the business of buying and selling securities—stocks, bonds, mutual funds, and certain other investment products—on behalf of its customers (as broker), for its own account (as dealer), or both."
								>
									<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
								</Tooltip>
							</Stack>

							<RadioGroup
								required
								name="brokerDealerAssociated"
								value={values.brokerDealerAssociated}
								onChange={(event) => {
									setFieldValue('brokerDealerAssociated', event.currentTarget.value);
									if (event.currentTarget.value === 'No') {
										setFieldValue('brokerDealerAssociatedEmployed', '');
										setFieldValue('brokerDealerAssociatedControl', '');
										setFieldValue('brokerDealerAssociatedPolitical', '');
									}
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.brokerDealerAssociated && errors.brokerDealerAssociated && (
								<FormHelperText>{errors.brokerDealerAssociated}</FormHelperText>
							)}
						</FormControl>
					</Grid>

					{values.brokerDealerAssociated === 'Yes' && (
						// Are you or an immediate family/household member employed by or associated with the securities industry (e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or other associated person of a FINRA or MSRB member firm) or a financial services regulator?
						<Grid size={12}>
							<FormControl
								component="fieldset"
								error={touched.brokerDealerAssociatedEmployed && Boolean(errors.brokerDealerAssociatedEmployed)}
							>
								<FormLabel id="broker-dealer-associated-employed-label">
									Are you or an immediate family/household member employed by or associated with the securities industry
									(e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or
									other associated person of a FINRA or MSRB member firm) or a financial services regulator?
								</FormLabel>
								<RadioGroup
									required
									name="brokerDealerAssociatedEmployed"
									value={values.brokerDealerAssociatedEmployed}
									onChange={(event) => {
										setFieldValue('brokerDealerAssociatedEmployed', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
								</RadioGroup>
								{touched.brokerDealerAssociatedEmployed && errors.brokerDealerAssociatedEmployed && (
									<FormHelperText>{errors.brokerDealerAssociatedEmployed}</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{values.brokerDealerAssociatedEmployed === 'Yes' &&
						renderExplanationInput('brokerDealerAssociatedEmployedExplanation')}

					{values.brokerDealerAssociated === 'Yes' && (
						// Are you or an immediate family/household member a control person or affiliate of a publicly traded company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer, member of the board of directors, etc.)?
						<Grid size={12}>
							<FormControl
								component="fieldset"
								error={touched.brokerDealerAssociatedControl && Boolean(errors.brokerDealerAssociatedControl)}
							>
								<FormLabel id="broker-dealer-associated-control-label">
									Are you or an immediate family/household member a control person or affiliate of a publicly traded
									company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer,
									member of the board of directors, etc.)?
								</FormLabel>
								<RadioGroup
									required
									name="brokerDealerAssociatedControl"
									value={values.brokerDealerAssociatedControl}
									onChange={(event) => {
										setFieldValue('brokerDealerAssociatedControl', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
								</RadioGroup>
								{touched.brokerDealerAssociatedControl && errors.brokerDealerAssociatedControl && (
									<FormHelperText>{errors.brokerDealerAssociatedControl}</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{values.brokerDealerAssociatedControl === 'Yes' &&
						renderExplanationInput('brokerDealerAssociatedControlExplanation')}

					{values.brokerDealerAssociated === 'Yes' && (
						// Are you or an immediate family/household member a senior foreign political figure?
						<Grid size={12}>
							<FormControl
								component="fieldset"
								error={touched.brokerDealerAssociatedPolitical && Boolean(errors.brokerDealerAssociatedPolitical)}
							>
								<FormLabel id="broker-dealer-associated-political-label">
									Are you or an immediate family/household member a senior foreign political figure?
								</FormLabel>
								<RadioGroup
									required
									name="brokerDealerAssociatedPolitical"
									value={values.brokerDealerAssociatedPolitical}
									onChange={(event) => {
										setFieldValue('brokerDealerAssociatedPolitical', event.currentTarget.value);
									}}
								>
									<FormControlLabel
										value="Yes"
										label="Yes"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
									<FormControlLabel
										value="No"
										label="No"
										control={<Radio disabled={isSubmitting || locked} />}
										disabled={isSubmitting || locked}
									/>
								</RadioGroup>
								{touched.brokerDealerAssociatedPolitical && errors.brokerDealerAssociatedPolitical && (
									<FormHelperText>{errors.brokerDealerAssociatedPolitical}</FormHelperText>
								)}
							</FormControl>
						</Grid>
					)}
					{values.brokerDealerAssociatedPolitical === 'Yes' &&
						renderExplanationInput('brokerDealerAssociatedPoliticalExplanation')}
				</InfoBlock>
			</Grid>

			<Grid size={12}>
				<InfoBlock title={'Address information'}>
					{/* Current Mailing Address */}
					<Grid size={12}>
						<FormControl fullWidth>
							<GoogleMapSelect
								showAsterisk={false}
								label={'Mailing Address'}
								name="currentMailingAddress"
								value={values.currentMailingAddress}
								required
								aria-labelledby="mailing-address-label"
								handleChange={handleChange}
								setFieldValue={setFieldValue}
								disabled={locked || isSubmitting}
								fullWidth
							/>
						</FormControl>
					</Grid>

					{/* Have you been residing at this address less than 1 year? */}
					<Grid size={12}>
						<FormControl component="fieldset" error={touched.residingTime && Boolean(errors.residingTime)}>
							<FormLabel id="residing-time-label">Have you been residing at this address less than 1 year?</FormLabel>
							<RadioGroup
								required
								name="residingTime"
								value={values.residingTime}
								onChange={(event) => {
									setFieldValue('residingTime', event.currentTarget.value);

									if (event.currentTarget.value === 'No') {
										setFieldValue('formerAddress', '');
									}
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.residingTime && errors.residingTime && <FormHelperText>{errors.residingTime}</FormHelperText>}
						</FormControl>
					</Grid>
					{values.residingTime === 'Yes' && (
						<Grid size={12}>
							<FormControl fullWidth>
								<GoogleMapSelect
									showAsterisk={false}
									label={'Former Address'}
									name="formerAddress"
									value={values.formerAddress}
									required
									aria-labelledby="former-address-label"
									handleChange={handleChange}
									setFieldValue={setFieldValue}
									disabled={locked || isSubmitting}
									fullWidth
								/>
							</FormControl>
						</Grid>
					)}
				</InfoBlock>
			</Grid>

			<Grid size={12}>
				<InfoBlock title={'Attestations'}>
					{/* Attestations */}
					<Grid size={12}>
						<FormControl component="fieldset" error={touched.rule506 && Boolean(errors.rule506)}>
							<Stack spacing={1} direction={'row'} alignItems={'center'}>
								<FormLabel id="rule-506-label">
									Have you commited any bad acts as defined in Rule 506(d) of Regulation D?
								</FormLabel>
								<Tooltip
									placement="top"
									title={
										<span>
											{[
												'Under the final rule, disqualifying events include:',
												<br />,
												'• Certain criminal convictions',
												<br />,
												'• Certain court injunctions and restraining orders',
												<br />,
												'• Final orders of certain state and federal regulators',
												<br />,
												'• Certain SEC disciplinary orders',
												<br />,
												'• Certain SEC cease-and-desist orders',
												<br />,
												'• SEC stop orders and orders suspending the Regulation A exemption',
												<br />,
												'• Suspension or expulsion from membership in a self-regulatory organization (SRO), such as FINRA, or from association with an SRO member',
												<br />,
												'• U.S. Postal Service false representation orders"',
											]}
										</span>
									}
								>
									<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
								</Tooltip>
							</Stack>

							<RadioGroup
								required
								name="rule506"
								value={values.rule506}
								onChange={(event) => {
									setFieldValue('rule506', event.currentTarget.value);
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.rule506 && errors.rule506 && <FormHelperText>{errors.rule506}</FormHelperText>}
						</FormControl>
					</Grid>
					{values.rule506 === 'Yes' && renderExplanationInput('rule506Explanation')}
					<Grid size={12}>
						<FormControl component="fieldset" error={touched.criminalOffense && Boolean(errors.criminalOffense)}>
							<FormLabel id="criminal-offense-label">
								Have you ever been convicted of a federal, state, or municipal criminal offense?
							</FormLabel>
							<RadioGroup
								required
								name="criminalOffense"
								value={values.criminalOffense}
								onChange={(event) => {
									setFieldValue('criminalOffense', event.currentTarget.value);
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.criminalOffense && errors.criminalOffense && (
								<FormHelperText>{errors.criminalOffense}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					{values.criminalOffense === 'Yes' && renderExplanationInput('criminalOffenseExplanation')}
					<Grid size={12}>
						<FormControl
							component="fieldset"
							error={touched.pendingLegalLitigation && Boolean(errors.pendingLegalLitigation)}
						>
							<FormLabel id="pending-legal-label">
								Do you or your affiliates currently have any pending legal litigation against you?
							</FormLabel>
							<RadioGroup
								required
								name="pendingLegalLitigation"
								value={values.pendingLegalLitigation}
								onChange={(event) => {
									setFieldValue('pendingLegalLitigation', event.currentTarget.value);
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.pendingLegalLitigation && errors.pendingLegalLitigation && (
								<FormHelperText>{errors.pendingLegalLitigation}</FormHelperText>
							)}
						</FormControl>
					</Grid>
					{values.pendingLegalLitigation === 'Yes' && renderExplanationInput('pendingLegalLitigationExplanation')}
					<Grid size={12}>
						<FormControl component="fieldset" error={touched.judgments && Boolean(errors.judgments)}>
							<FormLabel id="judgments-label">Do you or your affiliates currently have any judgments?</FormLabel>
							<RadioGroup
								required
								name="judgments"
								value={values.judgments}
								onChange={(event) => {
									setFieldValue('judgments', event.currentTarget.value);
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.judgments && errors.judgments && <FormHelperText>{errors.judgments}</FormHelperText>}
						</FormControl>
					</Grid>
					{values.judgments === 'Yes' && renderExplanationInput('judgmentsExplanation')}
					<Grid size={12}>
						<FormControl component="fieldset" error={touched.foreclosure && Boolean(errors.foreclosure)}>
							<FormLabel id="foreclosure-label">
								Have you or your affiliates ever filed for bankruptcy, had a foreclosure, or granted a deed in lieu of
								foreclosure?
							</FormLabel>
							<RadioGroup
								required
								name="foreclosure"
								value={values.foreclosure}
								onChange={(event) => {
									setFieldValue('foreclosure', event.currentTarget.value);
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.foreclosure && errors.foreclosure && <FormHelperText>{errors.foreclosure}</FormHelperText>}
						</FormControl>
					</Grid>
					{values.foreclosure === 'Yes' && renderExplanationInput('foreclosureExplanation')}
					<Grid size={12}>
						<FormControl component="fieldset" error={touched.proceedings && Boolean(errors.proceedings)}>
							<FormLabel id="proceedings-label">
								Any investor lawsuits, stop orders, SEC/FINRA proceedings, state securities proceedings?
							</FormLabel>
							<RadioGroup
								required
								name="proceedings"
								value={values.proceedings}
								onChange={(event) => {
									setFieldValue('proceedings', event.currentTarget.value);
								}}
							>
								<FormControlLabel
									value="Yes"
									label="Yes"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
								<FormControlLabel
									value="No"
									label="No"
									control={<Radio disabled={isSubmitting || locked} />}
									disabled={isSubmitting || locked}
								/>
							</RadioGroup>
							{touched.proceedings && errors.proceedings && <FormHelperText>{errors.proceedings}</FormHelperText>}
						</FormControl>
					</Grid>
					{values.proceedings === 'Yes' && renderExplanationInput('proceedingsExplanation')}
				</InfoBlock>
			</Grid>
		</>
	);
};

export default FormFields;

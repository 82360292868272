import * as Yup from 'yup';

export const initialValues = {
	middleNames: '',
	hasMiddleNames: true,
	phoneNumber: '',
	ssnTaxId: '',
	currentMailingAddress: '',
	residingTime: '',
	formerAddress: '',
	usCitizen: '',
	citizenship: '',
	birthDate: '',
	brokerDealerAssociated: '',
	brokerDealerAssociatedEmployed: '',
	brokerDealerAssociatedEmployedExplanation: '',
	brokerDealerAssociatedControl: '',
	brokerDealerAssociatedControlExplanation: '',
	brokerDealerAssociatedPolitical: '',
	brokerDealerAssociatedPoliticalExplanation: '',
	rule506: '',
	rule506Explanation: '',
	criminalOffense: '',
	criminalOffenseExplanation: '',
	pendingLegalLitigation: '',
	pendingLegalLitigationExplanation: '',
	judgments: '',
	judgmentsExplanation: '',
	foreclosure: '',
	foreclosureExplanation: '',
	proceedings: '',
	proceedingsExplanation: '',
	idPassport: '',
	uploadDocuments: [],
};

export const schema = Yup.object({
	middleNames: Yup.string().when('hasMiddleNames', {
		is: (hasMiddleNames) => hasMiddleNames,
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	hasMiddleNames: Yup.boolean().notRequired(),
	phoneNumber: Yup.string().required('Required'),
	ssnTaxId: Yup.string().required('Required'),
	currentMailingAddress: Yup.string().required('Required'),
	residingTime: Yup.string().required('Required'),
	formerAddress: Yup.string().when('residingTime', {
		is: (residingTime) => residingTime === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	usCitizen: Yup.string().required('Required'),
	citizenship: Yup.string().when('usCitizen', {
		is: (usCitizen) => usCitizen === 'No',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	birthDate: Yup.string().required('Required'),

	// brokerDealerAssociated
	brokerDealerAssociated: Yup.string().required('Required'),
	brokerDealerAssociatedEmployed: Yup.string().when('brokerDealerAssociated', {
		is: (brokerDealerAssociated) => brokerDealerAssociated === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedEmployedExplanation: Yup.string().when('brokerDealerAssociatedEmployed', {
		is: (brokerDealerAssociatedEmployed) => brokerDealerAssociatedEmployed === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedControl: Yup.string().when('brokerDealerAssociated', {
		is: (brokerDealerAssociated) => brokerDealerAssociated === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedControlExplanation: Yup.string().when('brokerDealerAssociatedControl', {
		is: (brokerDealerAssociatedControl) => brokerDealerAssociatedControl === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedPolitical: Yup.string().when('brokerDealerAssociated', {
		is: (brokerDealerAssociated) => brokerDealerAssociated === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	brokerDealerAssociatedPoliticalExplanation: Yup.string().when('brokerDealerAssociatedPolitical', {
		is: (brokerDealerAssociatedPolitical) => brokerDealerAssociatedPolitical === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),

	/* Attestations */
	rule506: Yup.string().required('Required'),
	rule506Explanation: Yup.string().when('rule506', {
		is: (rule506) => rule506 === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	criminalOffense: Yup.string().required('Required'),
	criminalOffenseExplanation: Yup.string().when('criminalOffense', {
		is: (criminalOffense) => criminalOffense === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	pendingLegalLitigation: Yup.string().required('Required'),
	pendingLegalLitigationExplanation: Yup.string().when('pendingLegalLitigation', {
		is: (pendingLegalLitigation) => pendingLegalLitigation === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	judgments: Yup.string().required('Required'),
	judgmentsExplanation: Yup.string().when('judgments', {
		is: (judgments) => judgments === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	foreclosure: Yup.string().required('Required'),
	foreclosureExplanation: Yup.string().when('foreclosure', {
		is: (foreclosure) => foreclosure === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	proceedings: Yup.string().required('Required'),
	proceedingsExplanation: Yup.string().when('proceedings', {
		is: (proceedings) => proceedings === 'Yes',
		then: (schema) => schema.required('Required'),
		otherwise: (schema) => schema.notRequired(),
	}),
	idPassport: Yup.string().required('Required'),
});

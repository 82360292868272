import React from 'react';
import './style.css';
import { Typography, useTheme } from '@mui/material';

const CustomToggleButton = ({ leftLabel, rightLabel, callback, position = 'left' }) => {
	const defaultTheme = useTheme();

	return (
		<div
			className={`toggle-button ${position}`}
			style={{ border: `1px solid ${defaultTheme.palette.divider}` }}
			onClick={callback}
		>
			<div className="switch" style={{ background: defaultTheme.palette.primary.main }}></div>
			<div className="content">
				<span>
					<Typography variant={'button'} sx={{ color: position === 'left' ? 'primary.contrastText' : 'text.primary' }}>
						{leftLabel}
					</Typography>
				</span>
				<span>
					<Typography variant={'button'} sx={{ color: position === 'right' ? 'primary.contrastText' : 'text.primary' }}>
						{rightLabel}
					</Typography>
				</span>
			</div>
		</div>
	);
};

export default CustomToggleButton;

export default (
	<div>
		<h2>Who Can Buy Private Market Stock?</h2>
		<p>
			Private market stock is accessible to a diverse range of investors, including individual investors, venture
			capital funds, mutual funds, and more. However, to participate as a buyer in the United States, investors must
			meet the criteria of being deemed an "accredited investor" in accordance with federal securities laws.
		</p>
		<div>
			<h3>Qualifications for Accredited Investors:</h3>
			<p>
				Qualifying as an accredited investor involves meeting specific financial or professional thresholds. The most
				common criteria include:
			</p>
			<ol>
				<li>
					<b>Income Threshold:</b> Individuals must have earned income exceeding $200,000 in each of the past two years
					(or $300,000 combined with a spouse or partner), with a reasonable expectation of maintaining the same level
					of income in the current year.
				</li>
				<li>
					<b>Net Worth Threshold:</b> An individual (or combined with a spouse/partner) qualifies as an accredited
					investor if their net worth exceeds $1 million, excluding the value of their primary residence.
				</li>
			</ol>
		</div>
		<div>
			<h3>Additional Qualifications:</h3>{' '}
			<p>
				While the income and net worth criteria are common, there are additional ways to qualify as an accredited
				investor. Some examples include:
			</p>
			<ul>
				<li>
					<b>Professional Designations:</b> Individuals with specific professional designations, certifications, or
					experience in the financial industry may qualify as accredited investors.
				</li>
				<li>
					<b>Certain Entities:</b> Certain entities, such as trusts, partnerships, corporations, and charitable
					organizations, may also qualify as accredited investors based on their assets or total assets under
					management.
				</li>
			</ul>
		</div>
		<div>
			<h3>Why Accredited Investor Status Matters:</h3>
			<p>
				Accredited investor status is crucial for accessing private market opportunities. It signifies that an investor
				meets specific financial thresholds, indicating a certain level of financial sophistication and capacity to bear
				the risks associated with private market investments.
			</p>
		</div>
		<div>
			<h3>Consultation and Verification:</h3>
			<p>
				Investors seeking accredited status often consult with financial advisors or legal professionals to ensure
				compliance with the criteria. Verification processes are in place to confirm an investor's accredited status
				before participating in private market transactions.
			</p>
		</div>
		<div>
			<h3>Conclusion:</h3>
			<p>
				In summary, private market stock is available to a diverse range of investors, but participation as a buyer
				requires accreditation. Meeting the criteria for accredited investor status ensures that individuals and
				entities have the financial capacity and sophistication to engage in private market investments.
			</p>
		</div>
	</div>
);

import ActivityFeedCard from '../ActivityFeedCard';
import { Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { Check, HistoryToggleOff } from '@mui/icons-material';
import { useActivityFeedContext } from '../../../../context';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { apiClient, dateFormatter, endpoints, shortDateFormatter } from '../../../../utils';
import { format } from 'd3';

interface OfferActivityFeedTypeProps {
	offer: any;
	isSnackbar?: boolean;
}

const interestedLabel = 'Interested';
const appliedLabel = 'Applied';

const OfferActivityFeedType: FC<OfferActivityFeedTypeProps> = ({ offer, isSnackbar = false }) => {
	const navigate = useNavigate();
	const { setOpen: setActivityFeedOpen, updateFeed, clearLastNewFeed } = useActivityFeedContext();
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	const { id } = offer;
	const isApplied = useMemo(() => offer.answer === appliedLabel, [offer]);

	const onReply = async (interested: boolean) => {
		if (!offer.answered) {
			const res = await apiClient.post(endpoints.respondOffer, {
				id: id,
				answer: interested ? interestedLabel : 'Not Interested',
			});

			if (res.data) {
				updateFeed(res.data);
				clearLastNewFeed();
			}
		}

		if (interested) {
			navigate('/offer/' + id);
			setActivityFeedOpen(false);
		}
	};

	const getStructureString = (structure?: string[]): string => {
		const structureMap: Record<string, string> = {
			Direct: ' direct transfers',
			'Structured - Forwards': ' structured transactions',
			SPV: ' SPVs',
		};

		if (structure && structure.length > 0) {
			let structureString = ' for ';
			structure.forEach((structureItem, index) => {
				if (index > 0) {
					structureString += ', ';
				}
				structureString += structureMap[structureItem];
			});
			return structureString;
		}

		return '';
	};

	return (
		<ActivityFeedCard
			title={'New offer interest request'}
			date={shortDateFormatter(new Date(offer.createdAt))}
			isNew={!isSnackbar && !offer.answered && !offer.expired}
		>
			<Typography variant={'body2'}>
				We have {offer.type === 'Indicative' ? 'an indicative' : offer.type === 'Firm' ? 'a firm' : 'a'} offer for your{' '}
				<Link component={RouterLink} to={'/company/' + offer.company.id} underline={'hover'} target={'_blank'}>
					<b>{offer.company.name}</b>
				</Link>{' '}
				shares at a Gross Price of <b>{format('$,.2f')(offer.grossPrice)}</b> per share
				{getStructureString(offer.structure)}. Please review the following details, marked as live until{' '}
				<b>{dateFormatter(new Date(offer.validTo))}</b>.
			</Typography>

			<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} pt={1}>
				<Button
					variant={'text'}
					color={'primary'}
					size={isMobile ? 'medium' : 'small'}
					onClick={() => {
						onReply(true);
					}}
				>
					open offer details
				</Button>

				{isApplied && (
					<Stack direction={'row'} alignItems={'center'} spacing={1}>
						<Check color={'success'} fontSize={'small'} />
						<Typography color={'success.main'} sx={{ fontSize: 13, fontWeight: 500, textTransform: 'uppercase' }}>
							You’ve applied
						</Typography>
					</Stack>
				)}
				{offer.expired && !isApplied && (
					<Stack direction={'row'} alignItems={'center'} spacing={1}>
						<HistoryToggleOff color={'disabled'} fontSize={'small'} />
						<Typography color={'disabled.main'} sx={{ fontSize: 13, fontWeight: 500, textTransform: 'uppercase' }}>
							Expired
						</Typography>
					</Stack>
				)}
			</Stack>
		</ActivityFeedCard>
	);
};

export default OfferActivityFeedType;

export default (
	<div>
		<h2>Additional Costs Associated with Completing a Sale:</h2>
		<ol>
			<li>
				<b>Legal Opinion Costs:</b>
				<ul>
					<li>
						<b>Legal Opinion Requirement:</b> In connection with a sale, your company may require a legal opinion. This
						legal opinion is a formal statement issued by an attorney verifying the legality and validity of the
						transaction. Typically, the cost for obtaining a legal opinion ranges between $1000-$1500.
					</li>
					<li>
						<b>Referral to Attorneys:</b> ShareWell can provide referrals to attorneys who specialize in issuing these
						opinions, ensuring a smooth and compliant transaction process.
					</li>
				</ul>
			</li>
			<li>
				<b>Transfer Fees Imposed by the Company:</b>
				<ul>
					<li>
						<b>Transfer Fee Considerations:</b> Your company may charge a transfer fee to cover their administrative and
						processing costs associated with the sale of shares. Transfer fees can vary and typically range between
						$1000-$3000.
					</li>
				</ul>
			</li>
		</ol>
		<p>
			<b>Important Note:</b> Please be aware that these additional costs are not imposed by or paid to ShareWell. They
			are specific requirements or charges set by your company to facilitate the necessary legal processes and cover
			administrative expenses related to the sale.
		</p>
		<h3>Conclusion:</h3>
		<p>
			As part of the sale process, it's crucial for investors to be aware of potential additional costs imposed by the
			company. Legal opinion fees and transfer fees are common considerations, and ShareWell aims to provide
			transparency by informing investors about these potential costs upfront. Investors are encouraged to consult with
			the recommended attorneys for legal opinions and to understand and plan for any transfer fees that may apply.
		</p>
	</div>
);

export default (
	<div>
		<h2>What Are the Fees to Sell My Shares?</h2>
		<p>
			When working with a brokerage platform like SaxonWeber ShareWell, it's essential to be aware of the associated
			fees for selling your shares. The fee structure is designed to cover the services provided during the transaction
			process.
		</p>
		<div>
			<h3>Fee Details with ShareWell:</h3>
			<ul>
				<li>
					<b>Standard Fee:</b> You can typically expect to pay a fee of 5% when selling your shares through ShareWell.
					This fee covers the services rendered by ShareWell in facilitating the transaction.
				</li>
				<li>
					<b>Transaction Size Considerations:</b> It's important to note that fees might vary based on the transaction
					size. Transactions below the typical minimum of $100,000 might incur a higher fee. Your ShareWell Account
					Manager can provide more detailed information about fees, especially concerning transaction sizes.
				</li>
			</ul>
		</div>
		<div>
			<h3>Consulting with Your ShareWell Account Manager:</h3>
			<ul>
				<li>
					Your ShareWell Account Manager is your dedicated resource for understanding transaction sizes and associated
					fees in greater detail. They can discuss your specific situation, provide insights into the fee structure, and
					answer any questions you may have regarding the costs involved in selling your shares.
				</li>
			</ul>
		</div>
		<div>
			<p>
				It's advisable to have a thorough discussion with your ShareWell Account Manager to ensure a clear understanding
				of the fees associated with selling your shares. This transparency allows you to make informed decisions
				throughout the selling process.
			</p>
		</div>
	</div>
);

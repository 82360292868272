import axios from 'axios';
import { deleteJWT, getJWT, setJWT } from './';
import { toast } from 'react-toastify';
import { strings } from './strings';

const apiClient = axios.create();

apiClient.interceptors.request.use(
	async (request) => {
		const token = await getJWT();

		if (token) {
			request.headers.Authorization = `Bearer ${token}`;
		}

		return request;
	},
	(error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
	async (response) => {
		const token = response.data.accessToken ?? false;

		if (token) {
			await setJWT(token);
		}

		return response;
	},
	async (error) => {
		console.log('error: ', error);

		if (error?.response?.status === 500) {
			toast.error(strings.errorMessages.default, {
				position: 'top-center',
				theme: 'colored',
			});
		}

		if (error?.response?.status === 401 && window.location.pathname !== '/' && window.location.pathname !== '/signup') {
			await deleteJWT();
		}

		return Promise.reject(error);
	}
);

export { apiClient };

export default (
	<div>
		<h2>How Do I Find Out if My Company Allows Me to Sell My Shares?</h2>
		<p>
			Equity, such as stock options or shares of stock, often comprises a significant component of an employee's
			compensation package, particularly in startups and private companies. These entities may recognize the importance
			of providing employees with options to liquidate their equity for personal needs or portfolio diversification.
		</p>
		<div>
			<h3>Understanding Your Company's Policies:</h3>
			<ol>
				<li>
					<b>Employee Stock Option Plan:</b> Initiate your inquiry by thoroughly reviewing company documents,
					specifically your Stock Option Plan. This document typically outlines the rules, restrictions, and conditions
					concerning the sale of your shares. Ensure a clear understanding of any limitations or prerequisites specified
					in the plan.
				</li>
				<li>
					<b>Reviewing Company Documents: </b>Beyond the Stock Options Plan, scrutinize other relevant company documents
					that may provide insights into the rules surrounding the sale of shares. This may include shareholder
					agreements or internal policies influencing the liquidity of your equity.
				</li>
			</ol>
		</div>
		<div>
			<h3>Leveraging ShareWell Account Managers:</h3>
			<p>
				Consider engaging with a ShareWell Account Manager. These professionals are well-versed in navigating
				interactions between employees and their companies. They can provide tailored guidance, helping you understand
				your company's preferred transaction processes and ensuring compliance with existing regulations, including
				FINRA rules.
			</p>
		</div>
		<div>
			<h3>Navigating Nuances for Liquidity:</h3>
			<p>
				Recognize that nuances may exist in the rules and regulations governing the sale of your shares. Whether it's
				for personal needs or portfolio diversification, understanding these nuances is crucial. Professional assistance
				can be instrumental in navigating the complexities associated with equity liquidity while adhering to FINRA
				rules.
			</p>
		</div>
		<div>
			<p>
				In summary, take a comprehensive approach by reviewing company documents, and leveraging the expertise of
				ShareWell Account Managers. This ensures that you are well-informed and positioned to navigate the process of
				selling your shares in adherence to your company's policies and regulatory requirements.
			</p>
		</div>
	</div>
);

import styled from '@emotion/styled';
import { Paper, useTheme } from '@mui/material';

export const Uploader = styled(Paper)(() => {
	const defaultTheme = useTheme();

	return `
	display: flex;
	align-items: center;
	justify-content: center;
	height: 136px;
	width: 270px;
  border: 1px dashed ${defaultTheme.palette.divider};
  border-radius: 4px;
	transition: all 0.3s ease;

	&:hover {
		cursor: pointer;
	}
`;
});

import { Form, Formik } from 'formik';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardMedia,
	IconButton,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { AttachFile, DeleteOutlined, Send } from '@mui/icons-material';
import { apiClient, endpoints, getDefaultErrorMessage, getSystemErrorMessage } from '../../../../../../utils';
import InputField from '../../../../../../components/FormComponents/InputField';
import { useAudioContext, useAuthContext } from '../../../../../../context';
import { VisuallyHiddenInput } from '../../../../../../components/ChatWidget/components/ChatTab/ChatControls/style';
import React, { useEffect, useRef, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

const MessageField = ({ inquiryId }) => {
	const { inquiries, setInquiries } = useAuthContext();
	const { playSendSound: playSendMessageSound } = useAudioContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [selectedFiles, setSelectedFiles] = useState(null);
	const inputRef = useRef(null);
	const [fileUrl, setFileUrl] = useState(null);
	const [isButtonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		if (selectedFiles && selectedFiles.length) {
			const reader = new FileReader();

			reader.onload = function (e) {
				setFileUrl(e.target.result);
			};

			// Read the file as a data URL
			reader.readAsDataURL(selectedFiles[0]);
		}

		setFileUrl(null);
	}, [selectedFiles]);

	const uploadFiles = async () => {
		const formData = new FormData();

		for (const file of selectedFiles) {
			if (file instanceof Blob) {
				formData.append('files', file);
				formData.append('inquiryId', inquiryId);
			} else {
				const blob = await fetch(file.url).then((r) => r.blob());
				const parts = [blob];
				const newFile = new File(parts, file.filename, { url: file.url, ...file });
				formData.append('files', newFile);
				formData.append('inquiryId', inquiryId);
			}
		}

		return await apiClient.post(endpoints.sendChatAttachment, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	const handleSubmit = async (values, formikHelpers) => {
		const message = Boolean(values.message?.trim());
		const activities = [];

		try {
			setButtonLoading(true);

			if (message) {
				try {
					const res = await apiClient.post(endpoints.sendUserMessage, values);
					if (res.data) {
						activities.push(res.data);
						formikHelpers.resetForm();
					}
				} catch (e) {
					getDefaultErrorMessage(e, 'Error sending message. Try again later.');
					getSystemErrorMessage(e);
				}
			}

			if (selectedFiles) {
				try {
					const res = await uploadFiles();
					if (res.data) {
						activities.push(...res.data);
						onFileReset();
					}
				} catch (e) {
					getDefaultErrorMessage(e, 'Error uploading files. Try again later.');
					getSystemErrorMessage(e);
				}
			}

			if (activities.length) {
				playSendMessageSound();
				setInquiries(
					inquiries.map((inquiry) => {
						if (inquiry.id === inquiryId) {
							let newActivityLogs = [];
							if (inquiry.activityLogs) {
								newActivityLogs = [...activities, ...inquiry.activityLogs];
							} else {
								newActivityLogs = activities;
							}
							return {
								...inquiry,
								activityLogs: newActivityLogs,
							};
						} else {
							return inquiry;
						}
					})
				);
			}
		} finally {
			setButtonLoading(false);
		}
	};

	const onFileChange = (e) => {
		const files = Array.prototype.slice.call(e.target.files);
		setSelectedFiles(files);
	};

	const onFileReset = () => {
		setSelectedFiles(null);
	};

	return (
		<Stack direction={'row'} spacing={'18px'}>
			{!isMobile && <Avatar sx={{ bgcolor: 'primary.main', width: 32, height: 32 }}>Y</Avatar>}
			<Formik initialValues={{ message: '', inquiryId: inquiryId }} validateOnBlur={true} onSubmit={handleSubmit}>
				{({ values }) => {
					return (
						<Form autoComplete="off" style={{ width: '100%' }}>
							<Stack direction={'column'} spacing={'10px'}>
								<InputField
									name="message"
									placeholder="Additional information or clarifications needed? Drop your questions here."
									multiline
									maxRows={5}
									minRows={3}
									size={isMobile ? 'medium' : 'small'}
									sx={{ width: '100%' }}
									InputProps={{
										sx: { fontSize: 14 },
									}}
								/>
								<Stack direction={'row'} justifyContent={'space-between'} spacing={1}>
									{!selectedFiles ? (
										<Button
											component="label"
											variant="text"
											size={isMobile ? 'medium' : 'small'}
											startIcon={<AttachFile />}
											color={'inherit'}
											sx={{ height: 34, color: 'text.secondary' }}
										>
											Attach file
											<VisuallyHiddenInput ref={inputRef} type="file" onChange={onFileChange} />
										</Button>
									) : (
										<Card
											sx={{
												display: 'flex',
												alignItems: 'center',
												padding: '0 10px 0 0',
												backgroundColor: '#222222',
												width: '100%',
												maxWidth: 420,
											}}
										>
											<Stack spacing={2} direction={'row'} alignItems={'center'} flex={1} overflow={'hidden'}>
												<CardMedia
													component="img"
													sx={{
														height: 34,
														width: 34,
														borderRadius: '4px 0px 0px 4px',
													}}
													image={fileUrl ?? '/illustrations/dark/FileImageBackup.png'}
													onError={(e) => {
														e.target.src = '/illustrations/dark/FileImageBackup.png';
													}}
												/>
												<Typography color={'text.primary'} variant={'body2'} noWrap>
													{selectedFiles && selectedFiles.length && selectedFiles[0].name}
												</Typography>
											</Stack>

											<Box flexShrink={0}>
												<IconButton size={'small'} onClick={onFileReset} color={'error'}>
													<DeleteOutlined />
												</IconButton>
											</Box>
										</Card>
									)}
									<LoadingButton
										variant="contained"
										type="submit"
										size={isMobile ? 'medium' : 'small'}
										loading={isButtonLoading}
										endIcon={<Send />}
										loadingPosition={'end'}
										disabled={!Boolean(values.message?.trim()) && !selectedFiles}
									>
										send
									</LoadingButton>
								</Stack>
							</Stack>
						</Form>
					);
				}}
			</Formik>
		</Stack>
	);
};

export default MessageField;

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { apiClient, endpoints } from '../../../../../../utils';
import { toast } from 'react-toastify';
import { strings } from '../../../../../../utils/strings';

const DeleteDialog = (props) => {
	const { object, onSuccess } = props;
	const { open, handleClose } = props;

	const onDelete = async () => {
		if (object) {
			try {
				await apiClient.delete(`${endpoints.associates}?id=${object.id}`);
				toast.success(strings.successMessages.teamMember.deleted);
				onSuccess();
			} catch (err) {
				toast.error(strings.errorMessages.teamMember.delete);
			} finally {
				handleClose();
			}
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{'Delete team member?'}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to delete the team member <b>{object?.fields['Full Name']}</b>? This action cannot be
					undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color={'inherit'}>
					Cancel
				</Button>
				<Button onClick={onDelete} autoFocus color={'error'}>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;

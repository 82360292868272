import { BubbleWrapper, ChatMessage, FilePreview } from './style';
import { CardMedia, Stack, Typography, useTheme } from '@mui/material';
import { shortenFileName, timeFormatter } from '../../../../../utils';

const ChatBubble = ({ message, isAdmin, username }) => {
	const defaultTheme = useTheme();
	const fromUser = Boolean(message.fields['From User']);
	const file = message.fields.Uploads && message.fields.Uploads.length && message.fields.Uploads[0];

	return (
		<ChatMessage $fromUser={fromUser ^ isAdmin}>
			<Stack direction={'row'} spacing={'6px'} pl={'14px'}>
				<Typography variant={'body2'} color={'text.primary'}>
					{fromUser ? (isAdmin ? username ?? 'Client' : 'You') : isAdmin ? 'Admin' : 'ShareWell'}
				</Typography>
				<Typography variant={'body2'} color={'text.disabled'}>
					{timeFormatter(new Date(message.fields['Created At']))}
				</Typography>
			</Stack>
			<BubbleWrapper $fromUser={fromUser ^ isAdmin}>
				{message.fields.Message && (
					<Typography variant={'body2'} color={'text.primary'} sx={{ whiteSpace: 'pre-line' }}>
						{message.fields.Message}
					</Typography>
				)}
				{file && (
					<FilePreview>
						<a href={file.url} target={'_blank'} rel={'noreferrer'}>
							<CardMedia
								component="img"
								sx={{ height: 120, width: 120, borderRadius: defaultTheme.shape.borderRadius + 'px' }}
								image={file.url ?? '/illustrations/dark/FileImageBackup.png'}
								onError={(e) => {
									e.target.src = '/illustrations/dark/FileImageBackup.png';
								}}
							/>
						</a>
						<a href={file.url} target={'_blank'} rel={'noreferrer'}>
							<Typography textAlign={'center'} variant={'caption'} color={'white'}>
								{shortenFileName(file.filename ?? '', 30)}
							</Typography>
						</a>
					</FilePreview>
				)}
			</BubbleWrapper>
		</ChatMessage>
	);
};

export default ChatBubble;

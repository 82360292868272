import styled from 'styled-components';
import { Box, Stack } from '@mui/material';

export const ChatMessage = styled(Stack).attrs({
	direction: 'column',
	spacing: '4px',
})((props) => {
	return {
		maxWidth: '80%',
		width: 'fit-content',
		marginBottom: 16,
		marginTop: 16,
		marginLeft: props.$fromUser ? 'auto' : 'unset',
		alignItems: props.$fromUser ? 'flex-end' : 'flex-start',
	};
});

export const BubbleWrapper = styled(Box)((props) => {
	const greyBubbleColor = '#525252';
	const pinkBubbleColor = '#69586C';

	return {
		width: 'fit-content',
		padding: 14,
		borderRadius: 12,
		wordWrap: 'break-word',
		position: 'relative',
		backgroundColor: props.$fromUser ? pinkBubbleColor : greyBubbleColor,
		borderBottomRightRadius: props.$fromUser ? 1 : 10,
		borderBottomLeftRadius: props.$fromUser ? 10 : 1,
		boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.12)',

		'&::before': {
			content: '',
			position: 'absolute',
			top: '100%',
			right: props.$fromUser ? 0 : 'unset',
			left: props.$fromUser ? 'unset' : 0,
			width: 0,
			height: 0,
			borderStyle: 'solid',
			borderWidth: '0 10px 10px 0',
			borderColor: `transparent ${props.$fromUser ? 'transparent' : greyBubbleColor} ${
				props.$fromUser ? pinkBubbleColor : 'transparent'
			} transparent`,
		},
	};
});

export const FilePreview = styled(Stack).attrs({
	direction: 'column',
	alignItems: 'center',
	spacing: '4px',
	width: 'fit-content',
})(() => {
	return {};
});

export default (
	<div>
		<h2>Fees for Buying Private Company Shares through ShareWell</h2>
		<p>
			When engaging with a broker like ShareWell for the purchase of private company shares, it's important for
			investors to be aware of associated fees. ShareWell typically charges a fee, the details of which can be discussed
			with your ShareWell Account Manager.
		</p>
		<div>
			<h3>Key Considerations:</h3>
			<ol>
				<li>
					<b>Fee Structure:</b>
					<ul>
						<li>
							ShareWell implements a fee structure for their services. Investors are encouraged to engage with their
							ShareWell Account Manager to discuss the specific fee details associated with their transaction.
						</li>
					</ul>
				</li>
				<li>
					<b>Transaction Size Impact:</b>
					<ul>
						<li>
							Consideration should be given to the transaction size, as fees may vary based on the size of the
							investment. ShareWell aims to provide customized guidance to investors, ensuring transparency and clarity
							regarding the associated costs.
						</li>
					</ul>
				</li>
				<li>
					<b>Minimum Transaction Requirements:</b>
					<ul>
						<li>
							ShareWell may have minimum transaction requirements, and investors should be aware of these thresholds.
							Transactions falling below the specified minimum may be subject to different fee structures.
						</li>
					</ul>
				</li>
			</ol>
		</div>
		<div>
			<h3>Customized Guidance and Communication:</h3>
			<p>
				ShareWell prioritizes transparency in fee structures and aims to facilitate clear communication with investors.
				Engaging in open discussions with ShareWell Account Managers allows investors to gain a comprehensive
				understanding of the fees associated with their specific transactions.
			</p>
		</div>
		<div>
			<h3>Compliance with FINRA Rules:</h3>
			<p>
				All interactions and communications comply with FINRA rules, ensuring that investors receive accurate and
				suitable information. ShareWell adheres to industry regulations to provide a secure and compliant investment
				environment for accredited investors.
			</p>
		</div>
		<div>
			<h3>Considering Overall Investment Strategy:</h3>
			<p>
				Investors should evaluate the fees in the context of their overall investment strategy, considering factors such
				as potential returns, the value of the investment, and the level of service provided by ShareWell.
			</p>
		</div>
		<div>
			<h3>Conclusion:</h3>
			<p>
				While fees are a vital consideration, ShareWell offers services and expertise, along with the convenience of a
				private marketplace. Investors are encouraged to consult with their ShareWell Account Manager to gain a thorough
				understanding of the fees associated with their private company share transactions within the bounds of FINRA
				regulations.
			</p>
		</div>
	</div>
);

import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

const AudioContext = createContext({
	playSendSound: () => {},
	playReceiveSound: () => {},
});

export const AudioProvider = ({ children }) => {
	const [audioContext, setAudioContext] = useState(null);
	const [sendBuffer, setSendBuffer] = useState(null);
	const [receiveBuffer, setReceiveBuffer] = useState(null);

	// Load and decode an audio file
	const loadAudioBuffer = useCallback((context, url) => {
		return fetch(url)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`Failed to fetch audio file: ${response.statusText}`);
				}
				return response.arrayBuffer();
			})
			.then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
			.catch((error) => {
				console.error(`Error decoding audio data from ${url}:`, error);
				throw error; // Re-throw the error so it can be handled by the caller
			});
	}, []);

	const initializeAudioContext = useCallback(() => {
		if (!audioContext) {
			const AudioContextClass = window.AudioContext || window.webkitAudioContext;
			const context = new AudioContextClass();
			setAudioContext(context);

			loadAudioBuffer(context, '/sound/sendMessage.mp3')
				.then((buffer) => setSendBuffer(buffer))
				.catch((error) => console.error('Failed to load sendMessage audio buffer:', error));
			loadAudioBuffer(context, '/sound/receiveMessage.mp3')
				.then((buffer) => setReceiveBuffer(buffer))
				.catch((error) => console.error('Failed to load receiveMessage audio buffer:', error));

			return context;
		} else if (audioContext.state === 'suspended') {
			audioContext.resume();
		}
	}, [audioContext, loadAudioBuffer]);

	useEffect(() => {
		const handleUserGesture = () => {
			const context = initializeAudioContext();
			if (context) {
				document.removeEventListener('click', handleUserGesture);
				document.removeEventListener('keydown', handleUserGesture);
			}
		};

		document.addEventListener('click', handleUserGesture);
		document.addEventListener('keydown', handleUserGesture);

		return () => {
			document.removeEventListener('click', handleUserGesture);
			document.removeEventListener('keydown', handleUserGesture);
			if (audioContext) {
				audioContext.close();
			}
		};
	}, [audioContext, initializeAudioContext]);

	const playSound = useCallback(
		(buffer) => {
			if (audioContext && buffer) {
				const source = audioContext.createBufferSource();
				source.buffer = buffer;
				source.connect(audioContext.destination);
				source.start(0);
			}
		},
		[audioContext]
	);

	const playSendSound = useCallback(() => {
		playSound(sendBuffer);
	}, [playSound, sendBuffer]);

	const playReceiveSound = useCallback(() => {
		playSound(receiveBuffer);
	}, [playSound, receiveBuffer]);

	return <AudioContext.Provider value={{ playSendSound, playReceiveSound }}>{children}</AudioContext.Provider>;
};

export const useAudioContext = () => {
	return useContext(AudioContext);
};

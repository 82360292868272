export default (
	<div>
		<h2>What is Pre-IPO Investing?</h2>
		<p>
			Pre-IPO investing involves the act of investing in a company before it undergoes an initial public offering (IPO)
			and makes its shares available for public trading on a stock exchange. This form of investment presents an
			opportunity for investors to engage with a company at an early stage, anticipating potential growth and seeking
			significant returns. However, it comes with inherent risks, given the limited availability of information compared
			to publicly traded companies.
		</p>
		<div>
			<h3>Key Aspects of Pre-IPO Investing:</h3>
			<ol>
				<li>
					<b>Timing:</b> Pre-IPO investors enter the investment landscape before a company transitions to the public
					markets, aiming to capitalize on the potential appreciation of the company's value post-IPO.
				</li>
				<li>
					<b>Private Placements and Secondary Markets:</b> Investors often access pre-IPO opportunities through private
					placements or secondary market platforms. These platforms, like Forge Markets, facilitate the purchase of
					shares in a company before it officially becomes publicly traded.
				</li>
				<li>
					<b>High-Risk, High-Reward:</b> While pre-IPO investing offers the prospect of substantial returns, it is
					inherently high-risk. The lack of comprehensive information about the company's financials and future outlook
					compared to publicly traded counterparts contributes to the risk profile.
				</li>
			</ol>
		</div>
		<div>
			<h3>Investment Platforms and Opportunities:</h3>
			<ul>
				<li>
					<b>Private Placements:</b> Investors may directly participate in pre-IPO investments through private
					placements, securing shares directly from the company before it goes public.
				</li>
				<li>
					<b>Secondary Market Platforms:</b> Platforms like ShareWell connect investors with pre-IPO opportunities in
					the secondary market, enabling the purchase of shares from existing shareholders.
				</li>
			</ul>
		</div>
		<div>
			<h3>Considerations for Pre-IPO Investors:</h3>
			<ol>
				<li>
					<b>Limited Information:</b> Investors should be aware that pre-IPO companies often disclose less information
					than publicly traded ones. Thorough due diligence is essential.
				</li>
				<li>
					<b>Long-Term Perspective:</b> Pre-IPO investing often requires a long-term perspective, as liquidity events,
					such as an IPO or acquisition, may take time to materialize.
				</li>
				<li>
					<b>Accredited Investor Status:</b> Participation in pre-IPO opportunities may require investors to meet
					accredited investor criteria, demonstrating financial sophistication and capacity to bear the associated
					risks.
				</li>
			</ol>
		</div>
		<div>
			<h3>Conclusion:</h3>
			<p>
				Pre-IPO investing is a strategic approach for those seeking early access to promising companies. While the
				potential for significant returns exists, investors should approach pre-IPO opportunities with caution, conduct
				thorough research, and align their investment strategy with their risk tolerance and financial goals.
			</p>
		</div>
	</div>
);

import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { divide, multiply } from '../../../../math.utils';
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Paper,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import CustomToggleButton from '../../../../components/CustomToggleButton';

const Calculator = (props) => {
	const { title, onSubmit, sharesIssued } = props;
	const { action, setAction } = props;
	const { blockSize, setBlockSize } = props;
	const { sharePrice, setSharePrice } = props;
	const { shareNumber, setShareNumber } = props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [valuation, setValuation] = useState(sharesIssued ? sharePrice * sharesIssued : undefined);

	const toggleBuySell = () => {
		setAction(action === 'sell' ? 'buy' : 'sell');
	};

	const sharePriceChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value) {
			return;
		}

		setSharePrice(value);
		setBlockSize(sourceInfo.source === 'event' ? multiply(value, shareNumber) : blockSize);

		if (sourceInfo.source === 'event' && isFinite(sharesIssued)) {
			setValuation(multiply(sharesIssued, value));
		}
	};

	const valuationChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value) {
			return;
		}

		setValuation(value);

		if (sourceInfo.source === 'event') {
			setSharePrice(divide(sharesIssued, value));
		}
	};

	const numberSharesChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value) {
			return;
		}

		setShareNumber(value);
		setBlockSize(sourceInfo.source === 'event' ? multiply(value, sharePrice) : blockSize);
	};

	const blockSizeChanged = (values, sourceInfo) => {
		const value = values.floatValue;

		if (!value || sourceInfo.source !== 'event') {
			return;
		}

		setBlockSize(value);
		setShareNumber(sourceInfo.source === 'event' ? divide(value, sharePrice) : shareNumber);
	};

	return (
		<Card>
			{title && <CardHeader title={title} />}
			<CardContent sx={{ paddingTop: isMobile ? 0 : undefined }}>
				<Stack spacing={3} direction={'column'}>
					<Paper elevation={14} sx={{ boxShadow: 'none' }}>
						<Box paddingX={'14px'} paddingY={'10px'} borderRadius={4}>
							<Typography variant={'body2'} color={'text.primary'}>
								To access all bids and comprehensive market pricing you must start by logging a basic inquiry on our
								platform to create your trading dashboard.
							</Typography>
						</Box>
					</Paper>
					<Stack spacing={'6px'} direction={'column'}>
						<Typography variant={'body1'} color={'text.secondary'}>
							I'm interested in
						</Typography>
						<Box width={'100%'}>
							<CustomToggleButton
								leftLabel="Selling"
								rightLabel="Buying"
								callback={toggleBuySell}
								position={action === 'sell' ? 'left' : 'right'}
							/>
						</Box>
					</Stack>

					<Stack spacing={'6px'} direction={'column'}>
						<Typography variant={'body1'} color={'text.secondary'}>
							Price per share
						</Typography>
						<NumericFormat
							value={sharePrice}
							onValueChange={sharePriceChanged}
							thousandSeparator={true}
							decimalScale={2}
							prefix="$"
							inputMode="numeric"
							customInput={TextField}
							size={isMobile ? 'medium' : 'small'}
							data-cy={'calculator-pps'}
						/>
					</Stack>
					{action === 'buy' && (
						<Stack spacing={'6px'} direction={'column'}>
							<Typography variant={'body1'} color={'text.secondary'}>
								Valuation
							</Typography>
							<NumericFormat
								value={valuation}
								onValueChange={valuationChanged}
								thousandSeparator={true}
								decimalScale={2}
								prefix="$"
								inputMode="numeric"
								customInput={TextField}
								size={isMobile ? 'medium' : 'small'}
							/>
						</Stack>
					)}
					<Stack spacing={'6px'} direction={'column'}>
						<Typography variant={'body1'} color={'text.secondary'}>
							Shares
						</Typography>
						<NumericFormat
							value={shareNumber}
							onValueChange={numberSharesChanged}
							thousandSeparator={true}
							inputMode="numeric"
							customInput={TextField}
							size={isMobile ? 'medium' : 'small'}
							data-cy={'calculator-shares'}
						/>
					</Stack>
					<Stack spacing={'6px'} direction={'column'}>
						<Typography variant={'body1'} color={'text.secondary'}>
							Block size
						</Typography>
						<NumericFormat
							value={blockSize}
							onValueChange={blockSizeChanged}
							thousandSeparator={true}
							prefix="$"
							decimalScale={2}
							inputMode="numeric"
							customInput={TextField}
							size={isMobile ? 'medium' : 'small'}
						/>
					</Stack>
				</Stack>
			</CardContent>
			{onSubmit && (
				<CardActions sx={{ justifyContent: 'center' }}>
					<Stack spacing={2} direction={'column'}>
						<Button variant={'contained'} size={'large'} fullWidth onClick={onSubmit} data-cy={'inquiry-submit'}>
							Start Trading
						</Button>
						<Typography variant={'body2'} color={'text.secondary'}>
							Access to the ShareWell dashboard is on a non-committed basis
						</Typography>
					</Stack>
				</CardActions>
			)}
		</Card>
	);
};

export default Calculator;

import React from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import FundingRounds from './FundingRounds';

export function FundingRoundsTab({ fundingData }) {
	return (
		<Box>
			<Grid container spacing={3}>
				<Grid size={12}>
					<FundingRounds fundingData={fundingData} />
				</Grid>
			</Grid>
		</Box>
	);
}

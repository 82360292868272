import { Box, CardMedia, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

const FeatureCard = ({ title, media, content }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const defaultTheme = useTheme();

	return (
		<Box>
			<Stack spacing={isMobile ? '40px' : '60px'}>
				<Stack spacing={isMobile ? 2 : 3}>
					<Typography variant={isMobile ? 'h5' : 'h4'}>{title}</Typography>
					<Typography variant="body1">{content}</Typography>
				</Stack>
				{media && (
					<CardMedia
						component={media.includes('.mov') ? 'video' : 'img'}
						autoPlay
						loop
						muted
						image={media}
						src={media}
						alt=""
						sx={{ objectFit: 'contain', borderRadius: defaultTheme.shape.borderRadius, maxHeight: '40vh' }}
					/>
				)}
			</Stack>
		</Box>
	);
};

export default FeatureCard;

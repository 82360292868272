import React, { useRef } from 'react';
import { Slide, Tab } from '@mui/material';
import { Chat, Close, Help } from '@mui/icons-material';
import {
	CustomPopover,
	MessageBadge,
	MessageBadgeDot,
	MessageBadgePaper,
	TabListPaper,
	Toggler,
	WidgetPaper,
	WidgetTabPanel,
} from './style';
import { useChatWidgetContext } from '../../context';
import ChatTab from './components/ChatTab';
import { TabContext, TabList } from '@mui/lab';
import HelpTab from './components/HelpTab';

const ChatWidget = () => {
	const popoverRef = useRef(null);
	const { isChatWidgetOpen: open, setChatWidgetOpen: setOpen } = useChatWidgetContext();
	const { selectedTab, setSelectedTab } = useChatWidgetContext();
	const { newMessages, tabsVisible } = useChatWidgetContext();

	const handleToggleChat = () => {
		setOpen(!open);
	};

	return (
		<>
			<Toggler onClick={handleToggleChat}>
				{open ? <Close /> : <Chat />}
				Need Help?
				{Boolean(newMessages.length) && (
					<MessageBadgePaper>
						<MessageBadge>
							<MessageBadgeDot>{newMessages.length}</MessageBadgeDot>
							New Messages!
						</MessageBadge>
					</MessageBadgePaper>
				)}
			</Toggler>

			<Slide direction="left" in={open}>
				<CustomPopover ref={popoverRef}>
					<TabContext value={selectedTab}>
						<WidgetPaper>
							<WidgetTabPanel value="chat" $visible={selectedTab === 'chat'}>
								<ChatTab />
							</WidgetTabPanel>
							<WidgetTabPanel value="help" $visible={selectedTab === 'help'}>
								<HelpTab />
							</WidgetTabPanel>
							<Slide direction="up" in={tabsVisible} mountOnEnter unmountOnExit>
								<TabListPaper $boxShadow={selectedTab === 'chat' ? 'none' : undefined}>
									<TabList
										variant="fullWidth"
										onChange={(_, newValue) => {
											setSelectedTab(newValue);
										}}
									>
										<Tab label="Chat" value="chat" icon={<Chat />} iconPosition="start" sx={{ minHeight: 60 }} />
										<Tab label="Help" value="help" icon={<Help />} iconPosition="start" sx={{ minHeight: 60 }} />
									</TabList>
								</TabListPaper>
							</Slide>
						</WidgetPaper>
					</TabContext>
				</CustomPopover>
			</Slide>
		</>
	);
};

export default ChatWidget;

import styled from 'styled-components';
import { Paper, Stack, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Close } from '@mui/icons-material';

export const FilePreviewPaper = styled(Paper)(({ $isDeletable }) => {
	return {
		'&.MuiPaper-root': {
			padding: 8,
			backgroundColor: $isDeletable ? grey['700'] : undefined,
			backgroundImage: $isDeletable ? 'unset' : undefined,
			boxShadow: $isDeletable
				? '0px 12px 46px 8px rgba(0, 0, 0, 0.15), 0px 24px 38px 3px rgba(0, 0, 0, 0.18), 0px 4px 20px -7px rgba(0, 0, 0, 0.20)'
				: 'unset',
		},
	};
});

export const CloseButtonWrapper = styled(Stack)(() => {
	const defaultTheme = useTheme();

	return {
		position: 'absolute',
		top: -8,
		right: -8,
		direction: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 3,
		background: defaultTheme.palette.error.main,
		borderRadius: 50,

		'&:hover': {
			cursor: 'pointer',
		},
	};
});

export const CloseButtonIcon = styled(Close)(() => {
	return {
		'&.MuiSvgIcon-root': {
			fontSize: 18,
			color: 'white',
		},
	};
});

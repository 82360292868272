import { useActivityFeedContext } from '../../../../context';
import { useEffect, useState } from 'react';
import { Box, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import BidActivityFeedType from '../BidActivityFeedType';
import { useLocation } from 'react-router-dom';
import OfferActivityFeedType from '../OfferActivityFeedType';

const FeedSnackbar = () => {
	const { lastNewFeed, isOpen: isActivityFeedOpen } = useActivityFeedContext();
	const location = useLocation();
	const [isOpen, setOpen] = useState(false);
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	useEffect(() => {
		setOpen(
			Boolean(lastNewFeed) && !isActivityFeedOpen && !location.pathname.includes(lastNewFeed?.cardType?.toLowerCase())
		);
	}, [lastNewFeed]);

	const onClose = () => {
		setOpen(false);
	};

	const renderCard = () => {
		if (lastNewFeed?.cardType === 'Bid') {
			return <BidActivityFeedType bid={lastNewFeed} isSnackbar={true} />;
		} else if (lastNewFeed?.cardType === 'Offer') {
			return <OfferActivityFeedType offer={lastNewFeed} isSnackbar={true} />;
		} else {
			return <></>;
		}
	};

	return (
		<Snackbar open={isOpen} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
			<Box width={isMobile ? window.innerWidth - 8 : 410} maxWidth={410}>
				{lastNewFeed && renderCard()}
			</Box>
		</Snackbar>
	);
};

export default FeedSnackbar;

import { useLoaderContext } from '../../context';
import LandingPage from '../../components/LandingPage';
import MobileLandingPage from '../../components/MobileLandingPage';
import { useMediaQuery, useTheme } from '@mui/material';

const LandingPageView = () => {
	const { isLoading } = useLoaderContext();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	if (isLoading) {
		return <></>;
	}

	if (isMobile) {
		return <MobileLandingPage />;
	}

	return <LandingPage />;
};

export default LandingPageView;

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PriceChart from './PriceChart';
import './index.css';
import React from 'react';

const ChartsTab = ({ data }) => {
	const dimensions = {
		width: 900,
		height: 400,
		margin: { top: 30, right: 65, bottom: 20, left: 65 },
	};

	return (
		<Box>
			<Grid container spacing={3}>
				{data.priceData && data.priceData.length > 0 && (
					<Grid size={12}>
						<PriceChart priceData={data.priceData} overviewData={data.overviewData} dimensions={dimensions} />
					</Grid>
				)}
				{/* {data.bubbleData && data.bubbleData.length > 0 && (
					<Grid size={12}>
						<BlockBubbleChart bubbleData={data.bubbleData} dimensions={bubbleDimensions} />
					</Grid>
				)} */}
			</Grid>
		</Box>
	);
};

export default React.memo(ChartsTab);

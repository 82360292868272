import * as Yup from 'yup';

export const initialValues = {
	minIncome: 'No',
	netWorth: 'No',
	goodStanding: 'No',
	familyClient: 'No',
	representations: false,
};

export const schema = Yup.object({
	minIncome: Yup.string().required('Required'),
	netWorth: Yup.string().required('Required'),
	goodStanding: Yup.string().required('Required'),
	familyClient: Yup.string().required('Required'),
});

import { forwardRef } from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';

const InputField = forwardRef(({ showAsterisk = true, type, label, required, variant, validate, ...rest }, ref) => {
	const [field, meta] = useField({ ...rest, validate });

	return (
		<TextField
			error={!!(meta.touched && meta.error)}
			helperText={meta.touched && meta.error}
			type={type || 'text'}
			required={required}
			label={label}
			variant={variant || 'outlined'}
			{...field}
			{...rest}
			inputRef={ref}
			InputLabelProps={{
				required: showAsterisk && required,
			}}
		/>
	);
});

export default InputField;

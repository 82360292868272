import styled from 'styled-components';
import { Stack } from '@mui/material';

export const DetailsHeader = styled(Stack).attrs({
	spacing: '4px',
	direction: 'row',
	alignItems: 'center',
})(() => {
	return {
		width: 'fit-content',
		'&:hover': {
			cursor: 'pointer',
		},
	};
});

export default (
	<div>
		<h2>Accredited Investors: Definition for Entities</h2>
		<p>
			The SEC defines accredited investors for entities, encompassing a variety of organizational structures. An entity
			qualifies as an accredited investor if it falls into one of the following categories:
		</p>
		<ol>
			<li>
				<b>Certain Entities Described in IRS Code Section 501(c)(3):</b>
				<ul>
					<li>
						Corporations, partnerships, LLCs, or other entities described in Section 501(c)(3) of the IRS Code, not
						formed for the specific purpose of acquiring the offered securities, with total assets exceeding $5 million.
					</li>
				</ul>
			</li>
			<li>
				<b>Family Offices:</b>
				<ul>
					<li>
						A "family office" not formed for the purpose of acquiring specific securities, with assets under management
						exceeding $5 million. The family office's investment decisions must be directed by an individual with
						sufficient knowledge and experience in financial and business matters to evaluate the merits and risks of
						the prospective investment.
					</li>
				</ul>
			</li>
			<li>
				<b>Other Entities Not Listed Elsewhere:</b>
				<ul>
					<li>
						Any entity not falling into the aforementioned categories, not formed for the specific purpose of acquiring
						the offered securities, and owning investments in excess of $5 million.
					</li>
				</ul>
			</li>
			<li>
				<b>Entity with All Equity Owners as Accredited Investors:</b>
				<ul>
					<li>
						An entity, excluding trusts, in which all equity owners qualify as accredited investors under one or more
						categories outlined by the SEC.
					</li>
				</ul>
			</li>
			<li>
				<b>Broker or Dealer:</b>
				<ul>
					<li>Any broker or dealer in securities.</li>
				</ul>
			</li>
			<li>
				<b>Registered Investment Advisers:</b>
				<ul>
					<li>
						An investment adviser registered under section 203 of the Investment Advisers Act of 1940 or registered
						under the laws of a U.S. state.
					</li>
				</ul>
			</li>
			<li>
				<b>Investment Advisers with SEC Exemptions:</b>
				<ul>
					<li>
						An investment adviser relying on exemptions from SEC registration under sections 203(l) or 203(m) of the
						Investment Advisers Act of 1940.
					</li>
				</ul>
			</li>
			<li>
				<b>Accredited Investor Trusts:</b>
				<ul>
					<li>
						A trust with total assets exceeding $5 million, not formed for the purpose of acquiring specific securities,
						and whose investment is directed by an individual with sufficient knowledge and experience in financial and
						business matters.
					</li>
				</ul>
			</li>
			<li>
				<b>Revocable Grantor Trusts:</b>
				<ul>
					<li>
						A revocable grantor trust, amendable or revocable at any time by the grantors, where all grantors qualify as
						accredited investors.
					</li>
				</ul>
			</li>
		</ol>
		<h3>Conclusion:</h3>
		<p>
			Entities qualifying as accredited investors play a crucial role in private investment markets. The SEC's criteria
			ensure that a diverse range of entities, from certain nonprofits to family offices and investment advisers, meet
			specific financial and regulatory standards, allowing them to participate in investment opportunities with
			potentially higher risk and returns.
		</p>
	</div>
);

import { Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';

import { initialValues, schema } from './loginForm.constants';
import { useState } from 'react';
import { apiClient, endpoints } from '../../utils';
import InputField from '../FormComponents/InputField';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ThankYouBlock from '../ThankYouBlock';
import UnicornLogo from '../UnicornLogo';

const LoginForm = () => {
	const [authLoading, setAuthLoading] = useState(false);
	const [isSuccess, setSuccess] = useState(false);
	const [isAccess, setAccess] = useState(false);
	const [accessInfo, setAccessInfo] = useState('');
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();

	const handleLogin = async (values) => {
		setAuthLoading(true);
		const refererPath = window.location.pathname;

		const payload = {
			Email: values.email.toLowerCase(),
		};

		if (refererPath !== '/' && refererPath !== '/signin' && refererPath !== '/signup') {
			payload.referer = refererPath;
		}

		try {
			const res = await apiClient.post(endpoints.login, payload);
			if (res.status === 200) {
				setSuccess(true);
				setAccess(false);
			}
		} catch (err) {
			if (err?.response?.status === 403) {
				setSuccess(false);
				setAccess(true);
				setAccessInfo(err.response.data);
			}
		} finally {
			setAuthLoading(false);
		}
	};

	const handleSubmit = async (values) => {
		handleLogin(values);
	};

	if (isSuccess) {
		return (
			<ThankYouBlock
				title={'Thank you for signing in!'}
				description={'Check your email inbox for the sign-in link.'}
				buttonText={isMobile && 'go home'}
				buttonLink={isMobile && '/'}
			/>
		);
	}

	return (
		<Stack alignItems={'center'} spacing={3}>
			<Link to={'/'} component={RouterLink}>
				<UnicornLogo width={isMobile ? 150 : 160} height={isMobile ? 150 : 160} />
			</Link>
			<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
				<Form style={{ width: '100%' }}>
					<Stack spacing={1}>
						<Stack spacing={isMobile ? 3 : 4}>
							<Stack spacing={isMobile ? 1 : '6px'}>
								<Typography variant={'h5'} color={'text.primary'} textAlign={isMobile && 'center'}>
									Sign in to your account
								</Typography>
								<Typography variant={'body2'} color={'text.secondary'} textAlign={isMobile && 'center'}>
									Enter your email and we’ll send you a sign in link.
								</Typography>
							</Stack>
							<InputField
								error={isAccess}
								name={'email'}
								label={'Email'}
								variant={'outlined'}
								type="email"
								helperText={isAccess && accessInfo}
								data-cy={'login-email'}
							/>
						</Stack>
						<Stack spacing={isMobile ? '12px' : 2} alignItems={'center'}>
							<LoadingButton
								variant={'contained'}
								fullWidth
								type={'submit'}
								size={'large'}
								loading={authLoading}
								endIcon={<></>}
								loadingPosition={'end'}
								data-cy={'login-button'}
							>
								Sign in
							</LoadingButton>
							{isMobile ? (
								<Button
									fullWidth
									size={'large'}
									variant={'outlined'}
									onClick={() => navigate('/signup' + window.location.search)}
									data-cy={'create-account-button'}
								>
									Create account
								</Button>
							) : (
								<Link component={RouterLink} to={'/signup' + window.location.search}>
									Create account
								</Link>
							)}
						</Stack>
					</Stack>
				</Form>
			</Formik>
		</Stack>
	);
};

export default LoginForm;

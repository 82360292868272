import { LabelTypography } from './common.styles';
import React from 'react';
import Grid from '@mui/material/Grid2';

export const labelColumns = 4;
export const valueColumns = 12 - labelColumns;

export const Label = ({ children, required }) => (
	<Grid size={labelColumns}>
		<LabelTypography $required={required}>{children}</LabelTypography>
	</Grid>
);

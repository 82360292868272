import { Tabs } from '@mui/material';
import styled from 'styled-components';

export const TabsWrapper = styled(Tabs)(() => {
	return {
		height: '100%',
		'& .MuiTabs-flexContainer': {
			height: '100%',
		},
	};
});

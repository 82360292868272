import { createContext, useContext, useEffect, useState } from 'react';
import { apiClient, endpoints } from '../utils';
import { useLoaderContext } from './LoaderContext';

const PublicAppContext = createContext({
	companies: [],
	setCompanies: () => {},
});

export const PublicAppProvider = ({ children }) => {
	const [companies, setCompanies] = useState([]);
	const { closeLoader } = useLoaderContext();

	useEffect(() => {
		loadCompaniesPublic()
			.then((res) => {
				setCompanies(res);
			})
			.finally(() => {
				closeLoader();
			});
	}, []);

	const loadCompaniesPublic = async () => {
		try {
			const res = await apiClient.get(endpoints.getAllCompaniesNoToken);
			return res.data ?? [];
		} catch (err) {
			return [];
		}
	};

	return (
		<PublicAppContext.Provider
			value={{
				companies,
				setCompanies,
			}}
		>
			{children}
		</PublicAppContext.Provider>
	);
};

export const usePublicAppContext = () => useContext(PublicAppContext);

import { Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useRef } from 'react';

const FileUploadInput = ({
	showAsterisk = true,
	id,
	name,
	required,
	setFieldValue,
	inputProps,
	multiple = false,
	disabled,
	value,
	size,
	...rest
}) => {
	const inputRef = useRef(null);

	const resetFileInput = (e) => {
		e.stopPropagation();
		setFieldValue(name, []);
		inputRef.current.value = null;
	};

	return (
		<TextField
			id={id || 'upload'}
			size={size || 'medium'}
			name={name}
			value={
				Array.isArray(value)
					? value.filter((file) => file.name || file.filename).map((file) => file.name ?? file.filename)
					: ''
			}
			required={required}
			disabled={disabled}
			InputProps={{
				readOnly: true,
				onClick: disabled ? undefined : () => inputRef.current.click(),
				endAdornment: (
					<InputAdornment position="end">
						<Stack spacing={1} direction={'row'} alignItems={'center'}>
							{Array.isArray(value) && value.length > 0 && (
								<IconButton sx={{ color: 'text.secondary' }} onClick={resetFileInput} disabled={disabled}>
									<Close />
								</IconButton>
							)}
							<Button
								size={size || 'medium'}
								variant={'contained'}
								component="label"
								disabled={disabled}
								onClick={(e) => e.stopPropagation()}
							>
								Choose file
								<input
									hidden
									multiple={multiple}
									type="file"
									ref={inputRef}
									onChange={(e) => {
										const files = Array.prototype.slice.call(e.target.files);
										setFieldValue(name, [...files]);
									}}
									{...inputProps}
								/>
							</Button>
						</Stack>
					</InputAdornment>
				),
			}}
			InputLabelProps={{
				required: showAsterisk && required,
			}}
			{...rest}
		/>
	);
};

export default FileUploadInput;

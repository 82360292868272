export default (
	<div>
		<h2>What Happens to My Shares or Stock Options if I Leave the Company?</h2>
		<p>
			The fate of your shares or stock options upon leaving a company is contingent on various factors, primarily
			determined by your vesting schedule.
		</p>
		<div>
			<h3>Unvested Shares or Stock Options:</h3>
			<ul>
				<li>
					If you have unvested shares or stock options, these typically remain with the company, and you may lose them
					upon leaving. Unvested equity is often subject to forfeiture.
				</li>
			</ul>
		</div>
		<div>
			<h3>Vested Stock Options:</h3>
			<ul>
				<li>
					In the case of vested stock options, you generally have a specific timeframe (often around 90 days, but this
					varies) after leaving the company to exercise these options. Exercising involves purchasing the shares at the
					predetermined exercise price, allowing you to retain ownership.
				</li>
			</ul>
		</div>
		<div>
			<h3>Vested Private Company Shares:</h3>
			<ul>
				<li>
					If you own vested private company shares or if you exercise your vested stock options after departing the
					company, you usually retain ownership of these shares. This scenario mirrors how a private market investor can
					own shares in a startup without being an active employee.
				</li>
			</ul>
		</div>
		<div>
			<h3>Consideration of Nuances:</h3>
			<ul>
				<li>
					It's crucial to recognize that nuances may exist, and specific company policies can influence the fate of your
					equity. Consulting with a financial advisor and tax advisor is highly recommended to gain a comprehensive
					understanding of your unique situation.
				</li>
			</ul>
		</div>
		<div>
			<h3>Professional Guidance:</h3>
			<ul>
				<li>
					Speaking with a financial advisor can provide insights into the financial implications of holding or selling
					your equity. Additionally, a tax advisor can offer guidance on the tax implications associated with your
					shares or stock options.
				</li>
			</ul>
		</div>
		<div>
			<h3>Key Takeaways:</h3>
			<ul>
				<li>
					<b>Vesting Schedule Matters: </b>Unvested equity may be forfeited, while vested equity provides options for
					retention or exercise.
				</li>
				<li>
					<b>Exercise Period for Stock Options: </b>Vested stock options often have an exercise window after leaving the
					company.
				</li>
				<li>
					<b>Consult with Advisors: </b>Engaging with financial and tax advisors ensures that you make well-informed
					decisions aligned with your financial goals.
				</li>
			</ul>
			<p>
				Understanding the specifics of your equity and navigating the intricacies of leaving a company are essential
				aspects of managing your financial portfolio.
			</p>
		</div>
	</div>
);

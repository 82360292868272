import * as Yup from 'yup';

export const initialValues = {
	'Full Name': '',
	Role: '',
	Email: '',
};

export const schema = Yup.object({
	'Full Name': Yup.string().required('Required'),
	Email: Yup.string().required('Required'),
	Role: Yup.string().required('Required'),
});

import {
	Box,
	Button,
	Card,
	CardMedia,
	FormControl,
	FormHelperText,
	FormLabel,
	IconButton,
	Link,
	Stack,
} from '@mui/material';
import { DeleteOutlined, FileUpload } from '@mui/icons-material';
import { useRef } from 'react';

const FileUploadMultiple = ({ name, label, touched, errors, disabled, setFieldValue, helper, values }) => {
	const ref = useRef(null);

	const handleRemoveDocument = (fileId, index) => {
		const result = values[name].filter((file, fileIndex) => (fileId ? file.id !== fileId : fileIndex !== index));

		//control input
		const found = result.find((file) => ref.current.value && ref.current.value.includes(file.filename || file.name));

		if (!found) {
			ref.current.value = null;
		}

		setFieldValue(name, result);
	};

	return (
		<Stack spacing={2}>
			<FormControl component="fieldset" error={touched[name] && Boolean(errors[name])}>
				<Stack spacing={1}>
					{label && <FormLabel>{label}</FormLabel>}
					<Stack spacing={'4px'}>
						<Button
							variant="contained"
							sx={{ width: 180 }}
							component="label"
							endIcon={<FileUpload />}
							disabled={disabled}
						>
							upload files
							<input
								hidden
								multiple
								type="file"
								ref={ref}
								onChange={(e) => {
									const files = Array.prototype.slice.call(e.target.files);
									setFieldValue(name, [...values[name], ...files]);
								}}
							/>
						</Button>
						{touched[name] && errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
						{helper && <FormHelperText error={false}>helper</FormHelperText>}
					</Stack>
				</Stack>
			</FormControl>
			<Stack spacing={1}>
				{values[name].map((file, index) => (
					<Card sx={{ display: 'flex', alignItems: 'center', padding: '0 10px 0 0' }} key={file.url ?? file.name}>
						<Stack spacing={2} direction={'row'} alignItems={'center'} flex={1} overflow={'hidden'}>
							<CardMedia
								component="img"
								sx={{ height: 60, width: 60 }}
								image={file.url ?? '/illustrations/dark/FileImageBackup.png'}
								onError={(e) => {
									e.target.src = '/illustrations/dark/FileImageBackup.png';
								}}
							/>
							<Link
								color={'text.primary'}
								href={file.url}
								target="_blank"
								rel="noopener noreferrer"
								underline={file.url ? 'hover' : 'none'}
								noWrap
							>
								{file.filename ?? file.name}
							</Link>
						</Stack>
						{!disabled && (
							<Box flexShrink={0}>
								<IconButton onClick={() => handleRemoveDocument(file.id, index)} size={'medium'}>
									<DeleteOutlined />
								</IconButton>
							</Box>
						)}
					</Card>
				))}
			</Stack>
		</Stack>
	);
};

export default FileUploadMultiple;

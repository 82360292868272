import { createContext, useContext, useState, useEffect } from 'react';
import LoadingIndicator from '../components/LoadingIndicator';
import { useTheme } from '@mui/material';

const LoaderContext = createContext({
	openLoader: () => {},
	closeLoader: () => {},
	isLoading: true,
});

export const LoaderProvider = ({ children }) => {
	const [isLoading, setLoading] = useState(true);
	const defaultTheme = useTheme();

	const openLoader = () => {
		setLoading(true);
		document.body.style.overflow = 'hidden';
	};

	const closeLoader = () => {
		document.body.style.overflow = 'unset';
		setLoading(false);
	};

	useEffect(() => {
		const iframe = document.getElementsByTagName('iframe')[0];
		iframe.style.background = defaultTheme.palette.background.default;
		iframe.contentWindow.document.body.style.backgroundColor = defaultTheme.palette.background.default;
	}, []);

	return (
		<LoaderContext.Provider
			value={{
				openLoader,
				closeLoader,
				isLoading,
			}}
		>
			{children}
			<LoadingIndicator isVisible={isLoading} />
		</LoaderContext.Provider>
	);
};

export const useLoaderContext = () => useContext(LoaderContext);
import * as React from 'react';

const UnicornLogo = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 1080 1080"
		style={{
			enableBackground: 'new 0 0 1080 1080',
		}}
		xmlSpace="preserve"
		{...props}
	>
		<style>
			{
				'.st0{display:none}.st3{display:inline;fill:#bb80af}.st4{fill:#a5a5a5}.st6{opacity:.5;fill:#aaa9aa}.st8{fill:#847e7e}.st10{fill:none;stroke:#000;stroke-width:6.9105;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}'
			}
		</style>
		<g id="Layer_4">
			<path
				className="st4"
				d="m87.4 130 158.2 235.6 29.6-22.5 13.6 15.3 85.6-87.6zM405.3 264.7l244.1 230.7-7.5-134.7-131.8-61.4-76-34.6-15-1.6zM572.2 885.9l1.9 66.1h53.1v-16zM844.7 871.8l-33.5 57.9 45 13.2-8.6-71.1zM888.2 620.3l19.9 141.9 84.4 62.6-16-129.1-6.3-35.8-6.1-7.8-70-31.8z"
			/>
			<path
				style={{
					opacity: 0.47,
					fill: '#7c7c7c',
				}}
				d="m85.6 128 201.5 228.6-52.3 164.2 39.7 66.1-59.7-63 47.3-123.5-24-26.8 8.2-6.9z"
			/>
			<path
				className="st6"
				d="m430.2 784.2 134.1 96-18.3 51.9-41-2.4-13.8-55.9-31.4-36.8-26.1-37.5-3.5-11.6M685.9 730.2 668 791.1l24.8 53.3 19.1 47.1v9.5H743l2.1-76.6zM234.8 520.8l139.6 11.4 11.8-14.1z"
			/>
			<path
				style={{
					opacity: 0.5,
					fill: '#adadad',
				}}
				d="m284.2 365.6 121.1 152.5 23.6-.8-141.8-160.7z"
			/>
			<path className="st6" d="m471.5 426.3-17 36.3 195.5 46zM418.5 775.9l233.2-247.5 8.3 54.2z" />
			<path
				className="st8"
				d="m671.3 778.9 71.3 76.8-30.7 34.1V901H743l2.1-79-30.3-43.1zM505 929.7l5.2-48.6 35.8 48.6z"
			/>
			<path
				style={{
					fill: '#7c767c',
				}}
				d="m428.9 264.7 175 150.1 38-54.1-42.6 36.3-160.1-130.8z"
			/>
			<path className="st6" d="m386.2 276.6 3.5 124.8 81.8 24.9-74.9-33.7z" />
		</g>
		<g id="line">
			<path className="st10" d="M426.8 781.8c5.8 25 33.3 57.4 65.7 91.5l12.5 56.3h41l18.2-49.5" />
			<path
				className="st10"
				d="m530.1 855.7-19.9 25.4-5.2 48.6M570.8 884.9l3.3 67.1h53.1l.9-12M668.9 787.9l43 101.9V901H743l2.1-76.6M811.2 929.7l45 13.2-9.1-75.3M671.3 778.9h43.5"
			/>
			<path
				className="st10"
				d="m402.3 532.2 16.2 243.7 153.7 110 55.9 54.1 56.7-211.5M418.5 775.9l233.2-247.5M909.1 760.3l85.2 65.9L972.5 667c-.9-6.8-5.3-12.7-11.5-15.6l-72.9-34M214.8 523.9l66.9-173.5M276.2 588.7l-61.4-64.8M85.6 128l160.7 238.7-8.2 6.9 24 27.8"
			/>
			<path
				className="st10"
				d="m369.8 533.7 280.7-12.9-1.1-25.4M85.6 128l201.5 228.6 3.7 4.1 138.1 156.6 42.6-91-73.7-33.4-11.6-116.3z"
			/>
			<path
				className="st10"
				d="m428.9 517.3-194.1 3.5 52.3-164.2M234.8 520.8l41.5 68c7.5 7.9 19.3 11.7 32.2 2.9l77.7-73.5M650 517.3l31.7 206.2 129.6 206.2 97.9-169.3-21.9-149.2c-33.4-73.9-128.1-89.8-237.3-93.9zM384.9 268.7l12.8 124.2L650 505.8l-8.1-145.1-41.5 36.9-161.2-131.4c-19.6-6.9-35.8-.6-54.3 2.5zM439.2 266.2l202.7 94.5"
			/>
		</g>
	</svg>
);

export default UnicornLogo;

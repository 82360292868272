import { Alert, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ExistingInquiryAlert = ({ inquiries, onClose, companyName, inquiryId }) => {
	const foundNum = inquiries.length;

	if (foundNum === 2 && inquiries[1].id !== inquiryId) {
		return (
			<Alert variant="outlined" severity="warning" sx={{ mb: 2 }} onClose={onClose}>
				{`You already have an inquiry for ${companyName}.`}
				<br />
				Do you want to{' '}
				<Link component={RouterLink} to={'/inquiry/' + inquiries[1].id}>
					edit
				</Link>{' '}
				that one?
			</Alert>
		);
	}

	if (foundNum > 2) {
		return (
			<Alert variant="outlined" severity="warning" sx={{ mb: 2 }} onClose={onClose}>
				{`You already have multiple inquiries for ${companyName}.`}
				<br />
				Do you want to{' '}
				<Link component={RouterLink} to={'/home'}>
					find and edit
				</Link>{' '}
				them?
			</Alert>
		);
	}

	return <></>;
};

export default ExistingInquiryAlert;

export default (
	<div>
		<h2>Navigating a Secondary Transaction: Execution, Clearing, and Settlement</h2>
		<p>
			Investors engaging in secondary transactions, such as the purchase or sale of private company shares, undergo a
			series of essential steps to ensure a seamless and secure process. Understanding the transaction journey, from
			execution to clearing and settlement, is crucial for a transparent and efficient investment experience.
		</p>
		<div>
			<ol>
				<li>
					<b>Initiation and Execution:</b>
					<ul>
						<li>
							<b>Buyer and Seller Agreement:</b> The process begins with a mutual agreement between the buyer and seller
							on the terms of the transaction. This includes the number of shares, price per share, and any other
							relevant conditions.
						</li>
						<li>
							<b>Platform Facilitation:</b> ShareWell, as the platform facilitating the transaction, plays a central
							role in connecting buyers with sellers. The platform ensures a secure environment for negotiation and
							agreement.
						</li>
						<li>
							<b>Legal Documentation:</b> Once terms are agreed upon, legal documentation is initiated to formalize the
							transaction. This may involve the drafting of a purchase agreement, detailing the rights and obligations
							of both parties.
						</li>
					</ul>
				</li>
				<li>
					<b>Clearing Process:</b>
					<ul>
						<li>
							<b>Verification and Due Diligence:</b> ShareWell conducts thorough verification and due diligence to
							ensure the legitimacy of the transaction. This includes confirming the identity of the parties involved,
							checking compliance with regulatory requirements, and validating the ownership status of the shares.
						</li>
						<li>
							<b>Legal Compliance:</b> The transaction is reviewed to ensure compliance with relevant legal and
							regulatory standards. This step contributes to the overall security and transparency of the process.
						</li>
						<li>
							<b>Communication with the Company:</b> ShareWell may liaise with the private company whose shares are
							being transacted to confirm the accuracy of ownership details and any company-specific requirements.
						</li>
					</ul>
				</li>
				<li>
					<b>Settlement and Transfer:</b>
					<ul>
						<li>
							<b>Funds Escrow:</b> To secure the financial aspect of the transaction, funds may be placed in escrow.
							This ensures that both the buyer and seller fulfill their respective obligations before the final transfer
							of shares.
						</li>
						<li>
							<b>Share Transfer Confirmation:</b> Upon successful completion of verification, compliance checks, and
							escrow conditions, ShareWell facilitates the actual transfer of shares. This step involves updating the
							company's cap table to reflect the new ownership.
						</li>
						<li>
							<b>Finalization and Reporting:</b> The transaction is finalized, and both parties receive confirmation of
							the successful settlement. ShareWell provides detailed transaction reports and updates, ensuring
							transparency and record-keeping.
						</li>
					</ul>
				</li>
				<li>
					<b>Post-Transaction Support:</b>
					<ul>
						<li>
							<b>Ongoing Support:</b> ShareWell continues to provide support post-transaction, addressing any queries or
							concerns from both buyers and sellers. This ensures a positive and lasting relationship with the ShareWell
							community.
						</li>
					</ul>
				</li>
			</ol>
		</div>
		<div>
			<h3>Conclusion:</h3>
			<p>
				Understanding the fundamental steps in a secondary transaction, from initiation and execution through clearing
				and settlement, is vital for investors navigating the private market. ShareWell's commitment to security,
				compliance, and transparency ensures a reliable platform for investors seeking to participate in secondary
				transactions with confidence.
			</p>
		</div>
	</div>
);

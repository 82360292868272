import {
	Autocomplete, Box,
	Button,
	Card,
	Checkbox,
	Link,
	Menu,
	MenuItem,
	Skeleton,
	Stack,
	TableContainer,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import React, { useEffect, useState } from 'react';
import { Add, MoreVert } from '@mui/icons-material';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { apiClient, endpoints } from '../../../../utils';
import { toast } from 'react-toastify';
import { NotFoundContainer } from '../../../../components/CompanyList/companyList.styles';
import TeamMemberDrawer from './components/TeamMemberDrawer';
import DeleteDialog from './components/DeleteDialog';
import { strings } from '../../../../utils/strings';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';

const TeamMembersTab = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultTheme = useTheme();
	const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'));

	const [isReadyToLoad, setReadyToLoad] = useState(false);
	const [isFirstLoading, setFirstLoading] = useState(true);

	const [teamMembers, setTeamMembers] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const [roleFilters, setRoleFilters] = useState([]);
	const [selectedRoleFilters, setSelectedRoleFilters] = useState([]);

	const [selectedObj, setSelectedObj] = useState(null);
	const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

	const [isDeleteOpen, setDeleteOpen] = useState(false);

	useEffect(() => {
		// firstly data
		loadData().then((res) => {
			setFilteredData(res);
			const uniqueRoles = new Set();

			res.forEach((item) => {
				if (item.fields && item.fields.Role) {
					uniqueRoles.add(item.fields.Role);
				}
			});
			setRoleFilters([...uniqueRoles]);
			setTeamMembers(res);
			isFirstLoading && setFirstLoading(false);
		});
	}, []);

	useEffect(() => {
		// secondly load parameters
		if (roleFilters.length) {
			const roleParam = searchParams.get('role');
			if (roleParam && roleParam.trim()) {
				const roles = roleParam.trim().split(',');

				const found = roles.reduce((accumulator, currentValue) => {
					const found = roleFilters.find((item) => item === currentValue);
					if (found) {
						return [...accumulator, found];
					}

					return accumulator;
				}, []);

				setSelectedRoleFilters(found);
			} else {
				setSelectedRoleFilters([]);
			}
		}

		!isReadyToLoad &&
		setTimeout(() => {
			setReadyToLoad(true);
		}, 300);
	}, [searchParams, roleFilters]);

	useEffect(() => {
		//thirdly filter data
		if (isReadyToLoad) {
			if (selectedRoleFilters.length) {
				setFilteredData(
					teamMembers.filter((item) => {
						if (item.fields && item.fields.Role) {
							return selectedRoleFilters.includes(item.fields.Role);
						}
						return false;
					}),
				);
			} else {
				setFilteredData(teamMembers);
			}
		}
	}, [setFilteredData, isReadyToLoad, teamMembers, selectedRoleFilters]);

	useEffect(() => {
		//deletion
		if (location?.state?.deleteId) {
			setTeamMembers((prevState) => prevState.filter((item) => item.id !== location?.state?.deleteId));
			setFilteredData((prevState) => prevState.filter((item) => item.id !== location?.state?.deleteId));
		}
	}, [location.pathname, location?.state?.deleteId]);

	useEffect(() => {
		//update
		const updateObjectId = location?.state?.updateObjectId;
		if (
			updateObjectId &&
			(updateObjectId === 'new' || filteredData.find((obj) => obj.id === location?.state?.updateObjectId))
		) {
			loadData().then((res) => {
				const found = res.find((resItem) => resItem.id === location?.state?.updateObjectId);
				if (found) {
					setTeamMembers((prevState) =>
						prevState.map((item) => {
							if (item.id === location?.state?.updateObjectId) {
								return found ?? item;
							} else {
								return item;
							}
						}),
					);

					setFilteredData((prevState) =>
						prevState.map((item) => {
							if (item.id === location?.state?.updateObjectId) {
								return found ?? item;
							} else {
								return item;
							}
						}),
					);
				} else {
					setTeamMembers(res);
					setFilteredData(res);
				}

				const uniqueRoles = new Set();

				res.forEach((item) => {
					if (item.fields && item.fields.Role) {
						uniqueRoles.add(item.fields.Role);
					}
				});
				setRoleFilters([...uniqueRoles]);
			});
		}
	}, [location.pathname, location?.state?.updateObjectId]);

	const columns = [
		{
			field: 'fullName',
			headerName: 'Full Name',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 160 : undefined,
			renderCell: (params) => (
				<Link
					underline='hover'
					variant='body2'
					component={RouterLink}
					to={`/settings/team/${params?.row.id}${window.location.search}`}
					state={{ preventScrollReset: true }}
				>
					{params?.row.fields['Full Name']}
				</Link>
			),
		},
		{
			field: 'Role',
			headerName: 'Role',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 160 : undefined,
			valueGetter: (_, row) => row.fields.Role,
		},
		{
			field: 'Email',
			headerName: 'Email address',
			flex: isMobile ? undefined : 1,
			width: isMobile ? 160 : undefined,
			valueGetter: (_, row) => row.fields.Email,
		},
		{
			field: 'actions',
			type: 'actions',
			width: 60,
			getActions: (params) => [
				<GridActionsCellItem
					size={'large'}
					icon={<MoreVert sx={{ color: 'text.secondary' }} />}
					onClick={(e) => handleActionsClick(e, params.row)}
					label={'actions'}
				/>,
			],
		},
	];

	const loadData = async () => {
		try {
			const res = await apiClient.get(endpoints.associates);
			return res.data ?? [];
		} catch (err) {
			toast.error(strings.errorMessages.teamMember.loadMany);
			return [];
		}
	};

	const handleActionsClick = (event, obj) => {
		setSelectedObj(obj);
		setMenuAnchorEl(event.currentTarget);
	};

	const handleViewDetails = () => {
		navigate('/settings/team/' + selectedObj.id + window.location.search, {
			state: { preventScrollReset: true },
		});
		setMenuAnchorEl(null);
	};

	const addNew = () => {
		navigate('/settings/team/new' + window.location.search, {
			state: { preventScrollReset: true },
		});
	};

	const primaryButton = (
		<Button
			size={isMobile ? 'large' : 'medium'}
			variant='contained'
			onClick={addNew}
			startIcon={<Add />}
			sx={
				isMobile
					? {
						position: 'fixed',
						bottom: 24,
						left: 24,
						right: 24,
						zIndex: 1051,
						height: 48,
						boxShadow: defaultTheme.shadows[24],
					}
					: undefined
			}
		>
			Add new team member
		</Button>
	);

	return (
		<Box>
			<Grid container rowSpacing={2} columnSpacing={3}>
				{isFirstLoading && (
					<Grid size={12}>
						<Skeleton variant='rectangular' height={300} />
					</Grid>
				)}
				{!isFirstLoading && !teamMembers.length && (
					<Grid size={12}>
						<NotFoundContainer>
							<Stack spacing={3} alignItems={'center'}>
								<img src={'/illustrations/dark/userPlus.svg'} style={{ height: 200 }} alt='Not found' />
								<Stack spacing={3} alignItems={'center'}>
									<Typography variant={'h5'}>No team members found</Typography>
									{primaryButton}
								</Stack>
							</Stack>
						</NotFoundContainer>
					</Grid>
				)}
				{!isFirstLoading && teamMembers.length > 0 && (
					<>
						<Grid size={isMobile ? 12 : 6}>
							<Autocomplete
								multiple
								size={'medium'}
								options={roleFilters}
								value={selectedRoleFilters}
								onChange={(_, value) => {
									const newSearchParams = {};
									if (value.length) {
										newSearchParams.role = value.join(',');
									}
									setSearchParams(newSearchParams);
								}}
								renderOption={(props, option, { selected }) => (
									<li {...props}>
										<Checkbox
											icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
											checkedIcon={<CheckBoxIcon fontSize='small' />}
											style={{ marginRight: 8 }}
											checked={selected}
										/>
										{option}
									</li>
								)}
								renderInput={(params) => <TextField {...params} label='Role' placeholder='Select roles' />}
							/>
						</Grid>
						{!isMobile && (
							<Grid size={6}>
								<Stack alignItems={'flex-end'} justifyContent={'center'} height={'100%'}>
									{primaryButton}
								</Stack>
							</Grid>
						)}
						{isMobile && primaryButton}
						<Grid size={12}>
							<TableContainer component={Card} sx={{ p: 0, overflow: 'visible' }}>
								<DataGridPro
									rows={filteredData}
									columns={columns}
									initialState={{ pinnedColumns: isMobile ? { right: ['actions'] } : undefined }}
									disableSelectionOnClick
									hideFooter
									disableColumnMenu
									disableColumnSorting
									disableColumnReorder
									disableColumnResize
								/>
							</TableContainer>
							<Menu
								anchorEl={menuAnchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								open={Boolean(menuAnchorEl)}
								onClose={() => {
									setMenuAnchorEl(null);
									selectedObj(null);
								}}
							>
								<MenuItem onClick={handleViewDetails}>View details</MenuItem>
								<MenuItem
									sx={{ color: defaultTheme.palette.error.main }}
									onClick={() => {
										setDeleteOpen(true);
										setMenuAnchorEl(null);
									}}
								>
									Delete
								</MenuItem>
							</Menu>
							<DeleteDialog
								object={selectedObj}
								onSuccess={() => {
									setFilteredData((prevState) => prevState.filter((item) => item.id !== selectedObj.id));
									setTeamMembers((prevState) => prevState.filter((item) => item.id !== selectedObj.id));
									setSelectedObj(null);
								}}
								open={isDeleteOpen}
								handleClose={() => {
									setDeleteOpen(false);
									setMenuAnchorEl(null);
									setSelectedObj(null);
								}}
							/>
						</Grid>
					</>
				)}
				<TeamMemberDrawer teamMembers={teamMembers} />
			</Grid>
		</Box>
	);
};

export default TeamMembersTab;

import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const EmptyContainer = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: calc(100vh - 150px);
`;

import styled from 'styled-components';
import { alpha, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';

export const InquiryContainer = styled(Stack).attrs(() => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return {
		direction: 'row',
		alignItems: 'flex-start',
		spacing: isMobile ? 1 : '40px',
		p: isMobile ? '4px 16px 10px 8px' : 2,
		pr: isMobile ? 2 : 3,
	};
})(() => ({}));

export const PaperContainer = styled(Paper).attrs({
	elevation: 1,
})((props) => {
	const defaultTheme = useTheme();

	return {
		'&.MuiPaper-root': {
			transition: 'all 0.2s ease',
			backgroundColor: props.$selected ? alpha(defaultTheme.palette.primary.main, 0.1) : undefined,
		},
	};
});

export default (
	<div>
		<h2>What is the Difference Between Shares, Stock Options, and RSUs?</h2>
		<p>
			Shares, stock options, and RSUs represent distinct ownership interests in a company, each with its unique
			characteristics.
		</p>
		<div>
			<h3>Shares:</h3>
			<p>
				Shares signify direct ownership stakes in a company, granting shareholders proportional rights to company assets
				and earnings. Shares can be acquired through various means, including direct purchase, grants, or participation
				in employee stock purchase plans (ESPPs).
			</p>
		</div>
		<div>
			<h3>Stock Options:</h3>
			<p>
				Stock options provide the holder with the right to purchase shares at a predetermined price, known as the
				exercise or strike price. These options are often granted as part of compensation packages and typically have a
				vesting period. Exercising stock options involves converting them into actual shares by purchasing them at the
				predetermined exercise price.
			</p>
		</div>
		<div>
			<h3>Vested and Unvested Shares:</h3>
			<ul>
				<li>
					<b>Vested Shares:</b> Refers to shares that an individual has full ownership of, typically after completing a
					vesting period. Once vested, these shares can be sold, transferred, or otherwise disposed of at the
					shareholder's discretion.
				</li>
				<li>
					<b>Unvested Shares:</b> Are shares that have not yet reached full ownership status. During the vesting period,
					certain conditions must be met for the shares to become fully vested. Until then, these shares may have
					restrictions on transfer or sale.
				</li>
			</ul>
		</div>
		<div>
			<h3>RSUs (Restricted Stock Units):</h3>
			<p>
				RSUs represent a promise to receive shares in the future, subject to certain conditions. Unlike stock options,
				RSUs do not require an upfront purchase. Instead, RSUs are granted, and once vested, they convert into actual
				shares. It's essential to note that RSUs are generally not traded in the market, and the recipient receives the
				underlying shares upon vesting.
			</p>
		</div>
		<div>
			<h3>Key Points:</h3>
			<ul>
				<li>
					<b>Ownership:</b> Shares represent direct ownership, stock options grant the right to acquire ownership, and
					RSUs represent a promise of future ownership.
				</li>
				<li>
					<b>Vesting:</b> Shares can be vested or unvested, with vesting determining the point at which full ownership
					is granted. Stock options and RSUs often have vesting periods.
				</li>
				<li>
					<b>Market Trading:</b> Shares and stock options are traded in the market, while RSUs are generally not traded
					until they vest and convert into actual shares.
				</li>
			</ul>
			<p>
				Understanding the differences between these ownership instruments is crucial for individuals navigating their
				involvement with a company's equity.
			</p>
		</div>
	</div>
);

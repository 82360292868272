import styled from '@emotion/styled';
import { Box, Card, CardHeader } from '@mui/material';

export const CompanyCardContainer = styled(Card)`
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0;
`;

export const CompanyCardHeader = styled(CardHeader)`
	flex: 1;
	align-items: flex-start;
`;

export const TrendOverlayBackground = styled(Box)(({ trend }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	background: trend
		? trend === 'up'
			? 'linear-gradient(180deg, rgba(28, 58, 38, 0.20) 0%, rgba(20, 58, 16, 0.00) 100%)'
			: 'linear-gradient(180deg, rgba(79, 24, 19, 0.20) 0%, rgba(58, 20, 16, 0.00) 100%)'
		: undefined,
	zIndex: 0,
	pointerEvents: 'none',
}));

export const TrendlineSvg = styled('svg')(() => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: 120,
	zIndex: 0,
	pointerEvents: 'none',
}));

import { useRef } from 'react';
import {
	Box,
	Button,
	Card,
	CardMedia,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	IconButton,
	Link,
	MenuItem,
	Radio,
	RadioGroup,
	Stack,
	Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DeleteOutlined, FileUpload, HelpOutline } from '@mui/icons-material';
import InputField from '../FormComponents/InputField';
import { timeFormat } from 'd3';
import Select from '../FormComponents/Select';
import { companyAffiliateYes, companyInsiderYes } from './transactionForm.constants';
import { addMonths } from '../../utils/getDate';

const FormFields = ({ values, setFieldValue, isSubmitting, locked, errors, touched }) => {
	const documentInputRef = useRef(null);

	const renderExplanationInput = (name) => (
		<Grid size={12}>
			<InputField
				label="Provide a written explanation"
				multiline
				fullWidth
				rows={4}
				name={name}
				disabled={locked || isSubmitting}
				required
				showAsterisk={false}
			/>
		</Grid>
	);

	const handleRemoveDocument = (fileId, index) => {
		if (locked) {
			return;
		}
		const result = values.uploadDocuments.filter((file, fileIndex) =>
			fileId ? file.id !== fileId : fileIndex !== index
		);

		//control input
		const found = result.find(
			(file) => documentInputRef.current.value && documentInputRef.current.value.includes(file.filename || file.name)
		);

		if (!found) {
			documentInputRef.current.value = null;
		}

		setFieldValue('uploadDocuments', result);
	};

	return (
		<>
			{/* Are you still working at the company? */}
			<Grid size={12}>
				<FormControl component="fieldset" error={touched.stillInCompany && Boolean(errors.stillInCompany)}>
					<FormLabel id="still-in-company-label">Are you still working at the company?</FormLabel>
					<RadioGroup
						required
						name="stillInCompany"
						value={values.stillInCompany}
						onChange={(event) => {
							setFieldValue('stillInCompany', event.currentTarget.value);
						}}
					>
						<FormControlLabel
							value="Yes"
							label="Yes"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
						<FormControlLabel
							value="No"
							label="No"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
					</RadioGroup>
					{touched.stillInCompany && errors.stillInCompany && <FormHelperText>{errors.stillInCompany}</FormHelperText>}
				</FormControl>
			</Grid>
			{values.stillInCompany === 'Yes' && (
				<>
					<Grid size={12}>
						<InputField
							label={'Position'}
							name="position"
							fullWidth
							disabled={isSubmitting || locked}
							required
							showAsterisk={false}
						/>
					</Grid>
				</>
			)}
			{values.stillInCompany === 'No' && (
				<>
					<Grid size={12}>
						<Select
							fullWidth
							name={'whenDidYouLeave'}
							label={'When did you leave?'}
							required
							showAsterisk={false}
							disabled={isSubmitting || locked}
							onChange={(e) => setFieldValue('whenDidYouLeave', e.target.value)}
						>
							<MenuItem value={'less than 90 days ago'} key={'less than 90 days ago'}>
								Less than 90 days ago
							</MenuItem>
							<MenuItem value={'more than 90 days ago'} key={'more than 90 days ago'}>
								More than 90 days ago
							</MenuItem>
						</Select>
					</Grid>
				</>
			)}
			{/* File Upload */}
			<Grid size={12}>
				<Stack spacing={2}>
					<FormControl component="fieldset" error={touched.uploadDocuments && Boolean(errors.uploadDocuments)}>
						<Stack spacing={1}>
							<FormLabel id="still-in-company-label">Relevant documents</FormLabel>
							<Stack spacing={'4px'}>
								<Button
									variant="contained"
									sx={{ width: 180 }}
									component="label"
									endIcon={<FileUpload />}
									disabled={isSubmitting || locked}
									name={'uploadDocuments'}
								>
									upload files
									<input
										hidden
										multiple
										type="file"
										ref={documentInputRef}
										onChange={(e) => {
											const files = Array.prototype.slice.call(e.target.files);
											setFieldValue('uploadDocuments', [...values.uploadDocuments, ...files]);
										}}
									/>
								</Button>
								{touched.uploadDocuments && errors.uploadDocuments && (
									<FormHelperText>{errors.uploadDocuments}</FormHelperText>
								)}
								<FormHelperText error={false}>
									Equity incentive plan, stock option agreement, option grant holder details, share certificates and
									others
								</FormHelperText>
							</Stack>
						</Stack>
					</FormControl>
					<Stack spacing={1}>
						{values.uploadDocuments.map((file, index) => (
							<Card sx={{ display: 'flex', alignItems: 'center', padding: '0 10px 0 0' }} key={file.url ?? file.name}>
								<Stack spacing={2} direction={'row'} alignItems={'center'} flex={1} overflow={'hidden'}>
									<CardMedia
										component="img"
										sx={{ height: 60, width: 60 }}
										image={file.url ?? '/illustrations/dark/FileImageBackup.png'}
										onError={(e) => {
											e.target.src = '/illustrations/dark/FileImageBackup.png';
										}}
									/>
									<Link
										color={'text.primary'}
										href={file.url}
										target="_blank"
										rel="noopener noreferrer"
										underline={file.url ? 'hover' : 'none'}
										noWrap
									>
										{file.filename ?? file.name}
									</Link>
								</Stack>
								{!(isSubmitting || locked) && (
									<Box flexShrink={0}>
										<IconButton onClick={() => handleRemoveDocument(file.id, index)} size={'medium'}>
											<DeleteOutlined />
										</IconButton>
									</Box>
								)}
							</Card>
						))}
					</Stack>
				</Stack>
			</Grid>
			{/* AON or Min */}
			<Grid size={12}>
				<FormControl component="fieldset" error={touched.aonOrMin && Boolean(errors.aonOrMin)}>
					<Stack spacing={1} direction={'row'} alignItems={'center'}>
						<FormLabel id="aon-or-min-label">AON (All or Nothing) vs. Min (Minimum Quantity)</FormLabel>
						<Tooltip
							placement="top"
							title="AON (All or Nothing) refers to a trading condition used on a buy or sell order which instructs a broker to execute the order in its entirety or to do nothing.  Min refers to Minimum Quantity, a trading condition used on a buy or sell order which instructs a broker to execute the order down to the minimum share or dollar quantity."
						>
							<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
						</Tooltip>
					</Stack>
					<RadioGroup
						required
						name="aonOrMin"
						value={values.aonOrMin}
						onChange={(event) => {
							setFieldValue('aonOrMin', event.currentTarget.value);
						}}
					>
						<FormControlLabel
							value="AON"
							label="AON"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
						<FormControlLabel
							value="Min"
							label="Min"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
					</RadioGroup>
					{touched.aonOrMin && errors.aonOrMin && <FormHelperText>{errors.aonOrMin}</FormHelperText>}
				</FormControl>
			</Grid>
			{values.aonOrMin === 'Min' && (
				<Grid size={6}>
					<InputField
						label={'Min Qty'}
						name="minQty"
						fullWidth
						disabled={isSubmitting || locked}
						required
						showAsterisk={false}
					/>
				</Grid>
			)}
			{/* GTC or expiration date */}
			<Grid size={12}>
				<FormControl component="fieldset" error={touched.gtcOrExpirationDate && Boolean(errors.gtcOrExpirationDate)}>
					<Stack spacing={1} direction={'row'} alignItems={'center'}>
						<FormLabel id="gtc-or-expiration-date-label">GTC (Good ‘til Canceled) vs. Expiration</FormLabel>
						<Tooltip
							placement="top"
							title="GTC Good ’til canceled (GTC) describes a type of order that a shareholder or investor may place to buy or sell a security that remains active until either the order is filled or the shareholder or investor cancels it.  When there is a Expiration a date added that is used to indicate the date and time (4:00 pm Eastern Time) when the ticket automatically cancels."
						>
							<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
						</Tooltip>
					</Stack>
					<RadioGroup
						required
						name="gtcOrExpirationDate"
						value={values.gtcOrExpirationDate}
						onChange={(event) => {
							setFieldValue('gtcOrExpirationDate', event.currentTarget.value);
							if (!values.expirationDate) {
								setFieldValue(
									'expirationDate',
									event.currentTarget.value === 'Expiration' ? timeFormat('%Y-%m-%d')(addMonths(3)) : ''
								);
							}
						}}
					>
						<FormControlLabel
							value="GTC"
							label="GTC"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
						<FormControlLabel
							value="Expiration"
							label="Expiration"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
					</RadioGroup>
					{touched.gtcOrExpirationDate && errors.gtcOrExpirationDate && (
						<FormHelperText>{errors.gtcOrExpirationDate}</FormHelperText>
					)}
				</FormControl>
			</Grid>
			{values.gtcOrExpirationDate === 'Expiration' && (
				<Grid size={12}>
					<InputField
						label={'Date'}
						required
						showAsterisk={false}
						name="expirationDate"
						fullWidth
						disabled={isSubmitting || locked}
						type="date"
						InputLabelProps={{
							shrink: true,
							required: false,
						}}
					/>
				</Grid>
			)}
			{/* Company insider */}
			<Grid size={12}>
				<FormControl component="fieldset" error={touched.companyInsider && Boolean(errors.companyInsider)}>
					<Stack spacing={1} direction={'row'} alignItems={'center'}>
						<FormLabel id="company-insider-label">As per FINRA definition, are you a company insider?</FormLabel>
						<Tooltip
							placement="top"
							title="A company insider means any officer or director of the issuer, or person that performs a similar function, or any person who is, directly or indirectly, the beneficial owner of more than 10 percent of the outstanding units or shares of any equity security (See SEC Rule 15c2-11(e)(1))."
						>
							<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
						</Tooltip>
					</Stack>

					<RadioGroup
						required
						name="companyInsider"
						value={values.companyInsider}
						onChange={(event) => {
							setFieldValue('companyInsider', event.currentTarget.value);
							if (event.currentTarget.value !== companyInsiderYes) {
								setFieldValue('companyInsiderEmployed', '');
								setFieldValue('companyInsiderControl', '');
								setFieldValue('companyInsiderPolitical', '');
							}
						}}
					>
						<FormControlLabel
							value="No, I am not a company insider"
							label="No, I am not a company insider"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
						<FormControlLabel
							value={companyInsiderYes}
							label={companyInsiderYes}
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
						<FormControlLabel
							value="I am not sure, what do you think?"
							label="I am not sure, what do you think?"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
					</RadioGroup>
					{touched.companyInsider && errors.companyInsider && <FormHelperText>{errors.companyInsider}</FormHelperText>}
				</FormControl>
			</Grid>

			{values.companyInsider === companyInsiderYes && (
				// Are you or an immediate family/household member employed by or associated with the securities industry (e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or other associated person of a FINRA or MSRB member firm) or a financial services regulator?
				<Grid size={12}>
					<FormControl
						component="fieldset"
						error={touched.companyInsiderEmployed && Boolean(errors.companyInsiderEmployed)}
					>
						<FormLabel id="company-insider-employed-label">
							Are you or an immediate family/household member employed by or associated with the securities industry
							(e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or other
							associated person of a FINRA or MSRB member firm) or a financial services regulator?
						</FormLabel>
						<RadioGroup
							required
							name="companyInsiderEmployed"
							value={values.companyInsiderEmployed}
							onChange={(event) => {
								setFieldValue('companyInsiderEmployed', event.currentTarget.value);
							}}
						>
							<FormControlLabel
								value="Yes"
								label="Yes"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
							<FormControlLabel
								value="No"
								label="No"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
						</RadioGroup>
						{touched.companyInsiderEmployed && errors.companyInsiderEmployed && (
							<FormHelperText>{errors.companyInsiderEmployed}</FormHelperText>
						)}
					</FormControl>
				</Grid>
			)}
			{values.companyInsiderEmployed === 'Yes' && renderExplanationInput('companyInsiderEmployedExplanation')}

			{values.companyInsider === companyInsiderYes && (
				// Are you or an immediate family/household member a control person or affiliate of a publicly traded company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer, member of the board of directors, etc.)?
				<Grid size={12}>
					<FormControl
						component="fieldset"
						error={touched.companyInsiderControl && Boolean(errors.companyInsiderControl)}
					>
						<FormLabel id="company-insider-control-label">
							Are you or an immediate family/household member a control person or affiliate of a publicly traded company
							under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer, member of
							the board of directors, etc.)?
						</FormLabel>
						<RadioGroup
							required
							name="companyInsiderControl"
							value={values.companyInsiderControl}
							onChange={(event) => {
								setFieldValue('companyInsiderControl', event.currentTarget.value);
							}}
						>
							<FormControlLabel
								value="Yes"
								label="Yes"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
							<FormControlLabel
								value="No"
								label="No"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
						</RadioGroup>
						{touched.companyInsiderControl && errors.companyInsiderControl && (
							<FormHelperText>{errors.companyInsiderControl}</FormHelperText>
						)}
					</FormControl>
				</Grid>
			)}
			{values.companyInsiderControl === 'Yes' && renderExplanationInput('companyInsiderControlExplanation')}

			{values.companyInsider === companyInsiderYes && (
				// Are you or an immediate family/household member a senior foreign political figure?
				<Grid size={12}>
					<FormControl
						component="fieldset"
						error={touched.companyInsiderPolitical && Boolean(errors.companyInsiderPolitical)}
					>
						<FormLabel id="company-insider-political-label">
							Are you or an immediate family/household member a senior foreign political figure?
						</FormLabel>
						<RadioGroup
							required
							name="companyInsiderPolitical"
							value={values.companyInsiderPolitical}
							onChange={(event) => {
								setFieldValue('companyInsiderPolitical', event.currentTarget.value);
							}}
						>
							<FormControlLabel
								value="Yes"
								label="Yes"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
							<FormControlLabel
								value="No"
								label="No"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
						</RadioGroup>
						{touched.companyInsiderPolitical && errors.companyInsiderPolitical && (
							<FormHelperText>{errors.companyInsiderPolitical}</FormHelperText>
						)}
					</FormControl>
				</Grid>
			)}
			{values.companyInsiderPolitical === 'Yes' && renderExplanationInput('companyInsiderPoliticalExplanation')}

			{/* Company affiliate */}
			<Grid size={12}>
				<FormControl component="fieldset" error={touched.companyAffiliate && Boolean(errors.companyAffiliate)}>
					<Stack spacing={1} direction={'row'} alignItems={'center'}>
						<FormLabel id="company-affiliate-label">As per FINRA definition, are you a company affiliate?</FormLabel>
						<Tooltip
							placement="top"
							title="Affiliate of an issuer means a person that directly, or indirectly through one or more intermediaries, controls, or is controlled by, or is under common control with, such issuer (See SEC Rule 144(a)(1))."
						>
							<HelpOutline sx={{ fontSize: '20px', color: 'text.disabled' }} />
						</Tooltip>
					</Stack>
					<RadioGroup
						required
						name="companyAffiliate"
						value={values.companyAffiliate}
						onChange={(event) => {
							setFieldValue('companyAffiliate', event.currentTarget.value);
							if (event.currentTarget.value !== companyAffiliateYes) {
								setFieldValue('companyAffiliateEmployed', '');
								setFieldValue('companyAffiliateControl', '');
								setFieldValue('companyAffiliatePolitical', '');
							}
						}}
					>
						<FormControlLabel
							value="No, I am not a company affiliate"
							label="No, I am not a company affiliate"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
						<FormControlLabel
							value={companyAffiliateYes}
							label={companyAffiliateYes}
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
						<FormControlLabel
							value="I am not sure, what do you think?"
							label="I am not sure, what do you think?"
							control={<Radio disabled={isSubmitting || locked} />}
							disabled={isSubmitting || locked}
						/>
					</RadioGroup>
					{touched.companyAffiliate && errors.companyAffiliate && (
						<FormHelperText>{errors.companyAffiliate}</FormHelperText>
					)}
				</FormControl>
			</Grid>

			{values.companyAffiliate === companyAffiliateYes && (
				// Are you or an immediate family/household member employed by or associated with the securities industry (e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or other associated person of a FINRA or MSRB member firm) or a financial services regulator?
				<Grid size={12}>
					<FormControl
						component="fieldset"
						error={touched.companyAffiliateEmployed && Boolean(errors.companyAffiliateEmployed)}
					>
						<FormLabel id="company-affiliate-employed-label">
							Are you or an immediate family/household member employed by or associated with the securities industry
							(e.g. a sole proprietor, partner, officer, director, branch manager, registered representative or other
							associated person of a FINRA or MSRB member firm) or a financial services regulator?
						</FormLabel>
						<RadioGroup
							required
							name="companyAffiliateEmployed"
							value={values.companyAffiliateEmployed}
							onChange={(event) => {
								setFieldValue('companyAffiliateEmployed', event.currentTarget.value);
							}}
						>
							<FormControlLabel
								value="Yes"
								label="Yes"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
							<FormControlLabel
								value="No"
								label="No"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
						</RadioGroup>
						{touched.companyAffiliateEmployed && errors.companyAffiliateEmployed && (
							<FormHelperText>{errors.companyAffiliateEmployed}</FormHelperText>
						)}
					</FormControl>
				</Grid>
			)}
			{values.companyAffiliateEmployed === 'Yes' && renderExplanationInput('companyAffiliateEmployedExplanation')}

			{values.companyAffiliate === companyAffiliateYes && (
				// Are you or an immediate family/household member a control person or affiliate of a publicly traded company under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer, member of the board of directors, etc.)?
				<Grid size={12}>
					<FormControl
						component="fieldset"
						error={touched.companyAffiliateControl && Boolean(errors.companyAffiliateControl)}
					>
						<FormLabel id="company-affiliate-control-label">
							Are you or an immediate family/household member a control person or affiliate of a publicly traded company
							under SEC Rule 144 (e.g. executive officer, director, 10% shareholder, policy-making officer, member of
							the board of directors, etc.)?
						</FormLabel>
						<RadioGroup
							required
							name="companyAffiliateControl"
							value={values.companyAffiliateControl}
							onChange={(event) => {
								setFieldValue('companyAffiliateControl', event.currentTarget.value);
							}}
						>
							<FormControlLabel
								value="Yes"
								label="Yes"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
							<FormControlLabel
								value="No"
								label="No"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
						</RadioGroup>
						{touched.companyAffiliateControl && errors.companyAffiliateControl && (
							<FormHelperText>{errors.companyAffiliateControl}</FormHelperText>
						)}
					</FormControl>
				</Grid>
			)}
			{values.companyAffiliateControl === 'Yes' && renderExplanationInput('companyAffiliateControlExplanation')}

			{values.companyAffiliate === companyAffiliateYes && (
				// Are you or an immediate family/household member a senior foreign political figure?
				<Grid size={12}>
					<FormControl
						component="fieldset"
						error={touched.companyAffiliatePolitical && Boolean(errors.companyAffiliatePolitical)}
					>
						<FormLabel id="company-affiliate-political-label">
							Are you or an immediate family/household member a senior foreign political figure?
						</FormLabel>
						<RadioGroup
							required
							name="companyAffiliatePolitical"
							value={values.companyAffiliatePolitical}
							onChange={(event) => {
								setFieldValue('companyAffiliatePolitical', event.currentTarget.value);
							}}
						>
							<FormControlLabel
								value="Yes"
								label="Yes"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
							<FormControlLabel
								value="No"
								label="No"
								control={<Radio disabled={isSubmitting || locked} />}
								disabled={isSubmitting || locked}
							/>
						</RadioGroup>
						{touched.companyAffiliatePolitical && errors.companyAffiliatePolitical && (
							<FormHelperText>{errors.companyAffiliatePolitical}</FormHelperText>
						)}
					</FormControl>
				</Grid>
			)}
			{values.companyAffiliatePolitical === 'Yes' && renderExplanationInput('companyAffiliatePoliticalExplanation')}
		</>
	);
};

export default FormFields;

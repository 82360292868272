export default (
	<div>
		<h2>Can I Sell My Private Company Shares?</h2>
		<p>
			If you own private company shares, you generally have the option to sell them, although certain restrictions may
			apply. It's important to note that unlike purchasing private company shares, selling does not typically require
			you to be an accredited investor.
		</p>
		<div>
			<h3>Understanding Potential Restrictions:</h3>
			<ol>
				<li>
					<b>"Right of First Refusal"</b>: Private companies often have specific rules in place, such as a "right of
					first refusal." This means that, before a new buyer can acquire the shares, the company may have the option to
					step in as the buyer or a current investor may exercise their rights and acquire. This process ensures that
					the company has the opportunity to acquire the shares before they are offered to external parties.
				</li>
				<li>
					<b>Company Approvals</b>: In most cases, the company has the right to approve the transaction or block a
					potential sale. Companies have several criteria that they may use to make this formal decision including buyer
					and seller-specific variables, price, size, and timing, among other reasons.
				</li>
			</ol>
		</div>
		<div>
			<h3>Potential Impact on Selling the Full Amount:</h3>
			<p>
				It's crucial to be aware that due to the existence of rights like the "right of first refusal", you may face
				limitations on selling the full amount of shares you originally intended to sell. These restrictions are in
				place to manage the sale of shares in a controlled manner and to protect the interests of the company and its
				stakeholders.
			</p>
		</div>
		<div>
			<h3>Regulatory Considerations:</h3>
			<p>
				When engaging in the sale of private company shares, it's essential to be mindful of regulatory considerations.
				Compliance with FINRA rules is paramount, and investors should ensure that their actions align with all
				applicable regulations governing the sale of securities.
			</p>
		</div>
		<div>
			<h3>Consulting Legal, Tax, and Financial Advisors:</h3>
			<p>
				Given the complexities associated with selling private company shares, it is advisable to seek guidance from
				legal, tax, and financial professionals. They may provide valuable insights into the specific rules and
				regulations governing your situation, ensuring a smooth and compliant selling process.
			</p>
		</div>
		<p>
			<i>
				Note: Remember that the process of selling private company shares involves navigating various legal and
				contractual considerations. Seeking professional advice and understanding the specific rules of the private
				company in question will contribute to a successful and compliant selling experience.
			</i>
		</p>
	</div>
);

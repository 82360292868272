import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ChatBubble from '../ChatBubble';
import { Divider, Stack, Typography } from '@mui/material';
import { onlyDateFormatter } from '../../../../../utils';
import CircularProgress from '@mui/material/CircularProgress';

const ChatBox = ({ messages, isAdmin = false, username }) => {
	// const [hasMore, setHasMore] = useState(false);
	// const [page, setPage] = useState(1);
	let currentDateForMessages = null;

	/*	useEffect(() => {
		fetchMessages(page);
	}, []);*/

	/*	const fetchMessages = (currentPage) => {
		setMessages((prevMessages) => [...prevMessages, ...fakeData]);
	};*/

	const loadMore = () => {
		// Fetch more messages when scrolling
		// const nextPage = page + 1;
		// fetchMessages(nextPage);
		// setPage(nextPage);
	};

	return (
		<InfiniteScroll
			dataLength={messages.length}
			inverse={true}
			scrollableTarget={isAdmin ? 'admin-chat-box' : 'user-chat-box'}
			next={loadMore}
			hasMore={false}
			loader={
				<Stack
					height={40}
					width={40}
					marginLeft={'auto'}
					marginRight={'auto'}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<CircularProgress size={24} />
				</Stack>
			}
			style={{ display: 'flex', flexDirection: 'column-reverse' }}
		>
			{messages.length === 0 && (
				<Stack direction={'column'} spacing={'12px'} alignItems={'center'} justifyContent={'center'} flex={1} mb={8}>
					<img src={'/illustrations/dark/emptyChat.svg'} style={{ width: 160 }} alt="Ask us any question" />
					<Stack direction={'column'} spacing={0} alignItems={'center'}>
						<Typography variant={'body1'}>Ask us any question.</Typography>
						<Typography variant={'body1'}>Your personal manager is always here.</Typography>
					</Stack>
				</Stack>
			)}
			{messages.length > 0 && (
				<div>
					{messages.map((message) => {
						const messageDate = new Date(message.fields['Created At']).toLocaleDateString();

						// Check if the day has changed
						const showDateDivider = currentDateForMessages !== messageDate;
						currentDateForMessages = messageDate;

						return (
							<React.Fragment key={message.id}>
								{showDateDivider && (
									<>
										<Divider orientation="horizontal">
											<Typography variant="caption" color="textSecondary">
												{onlyDateFormatter(new Date(message.fields['Created At']))}
											</Typography>
										</Divider>
									</>
								)}

								<ChatBubble message={message} isAdmin={isAdmin} username={username} />
							</React.Fragment>
						);
					})}
				</div>
			)}
		</InfiniteScroll>
	);
};

export default ChatBox;

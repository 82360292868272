import { Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ThankYouBlock = (props) => {
	const defaultTheme = useTheme();
	const { title, description, buttonText, buttonLink, iconName = 'heart', dataCy } = props;
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Stack spacing={isMobile ? 4 : '40px'}>
			<img
				src={`/illustrations/${defaultTheme.palette.mode}/${iconName}.svg`}
				style={{ height: isMobile ? 160 : '260px' }}
				alt="Thank you"
			/>
			<Stack spacing={4} alignItems={'center'}>
				<Stack spacing={1} alignItems={'center'}>
					<Typography variant={'h5'} color={'text.primary'} textAlign={'center'}>
						{title}
					</Typography>
					<Typography variant={'body1'} color={'text.secondary'} textAlign={'center'}>
						{description}
					</Typography>
				</Stack>
				{buttonText && (
					<Button
						size={'large'}
						variant={'contained'}
						sx={{ width: 270 }}
						onClick={() => navigate(buttonLink)}
						data-cy={dataCy}
					>
						{buttonText}
					</Button>
				)}
			</Stack>
		</Stack>
	);
};

export default ThankYouBlock;

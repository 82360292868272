import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthContext';
import FeedSnackbar from '../components/ActivityFeed/components/FeedSnackbar';

export interface IActivityFeed {
	id: string;
	cardType: 'Bid' | 'Offer';
	sortDate: string;
	answered: boolean;
	expired: boolean;

	[key: string]: any;
}

interface ActivityFeedContextType {
	isOpen: boolean;
	setOpen: (isOpen: boolean) => void;
	feed: Array<IActivityFeed>;
	lastNewFeed: IActivityFeed | null;
	updateFeed: (newFeed: IActivityFeed) => void;
	clearLastNewFeed: () => void;
}

const defaultContextValue: ActivityFeedContextType = {
	isOpen: true,
	setOpen: () => {},
	feed: [],
	lastNewFeed: null,
	updateFeed: () => {},
	clearLastNewFeed: () => {},
};

const ActivityFeedContext = createContext<ActivityFeedContextType>(defaultContextValue);

export const ActivityFeedProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const [feed, setFeed] = useState<Array<IActivityFeed>>([]);
	const [lastNewFeed, setLastNewFeed] = useState<IActivityFeed | null>(null);
	const { bids, offers } = useAuthContext();

	useEffect(() => {
		const sortedFeed: Array<IActivityFeed> = [
			...bids.map((bid) => ({
				...bid,
				cardType: 'Bid',
				sortDate: bid.fields['Created At'],
				answered: Boolean(bid.fields.Answer),
				expired: new Date().getTime() > new Date(bid.fields['Valid To']).getTime(),
			})),
			...offers.map((offer) => ({
				...offer,
				cardType: 'Offer',
				sortDate: offer.createdAt,
				answered: Boolean(offer.answer),
				expired: new Date().getTime() > new Date(offer.validTo).getTime(),
			})),
		].sort((a, b) => new Date(b.sortDate).getTime() - new Date(a.sortDate).getTime());

		setFeed(sortedFeed);
		setLastNewFeed(sortedFeed.find((feed) => !feed.answered && !feed.expired) || null);
	}, [bids, offers]);

	const updateFeed = (newFeed: IActivityFeed) => {
		setFeed((prev) => prev.map((item) => (item.id === newFeed.id ? newFeed : item)));
	};

	const clearLastNewFeed = () => {
		setLastNewFeed(null);
	};

	return (
		<ActivityFeedContext.Provider
			value={{
				feed,
				lastNewFeed,
				isOpen,
				setOpen,
				updateFeed,
				clearLastNewFeed,
			}}
		>
			{children}
			<FeedSnackbar />
		</ActivityFeedContext.Provider>
	);
};

export const useActivityFeedContext = () => useContext(ActivityFeedContext);

import {
	CardActionArea,
	CardContent,
	Divider,
	IconButton,
	Stack,
	SvgIcon,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { MonetizationOn, Star, StarBorder } from '@mui/icons-material';
import { CompanyCardContainer, CompanyCardHeader, TrendlineSvg, TrendOverlayBackground } from './styles';
import { grey, yellow } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import * as d3 from 'd3';
import { useEffect, useMemo, useRef } from 'react';

const CompanyCard = (props) => {
	const {
		id,
		companyName,
		sectorName,
		sectorIcon,
		valuation,
		elevation = 3,
		isStar = false,
		onStarClick,
		link,
		onClick,
		dynamicFields,
		trends = [],
	} = props;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const svgRef = useRef();

	const trend = useMemo(() => {
		if (trends && trends.length > 1) {
			const lastValue = trends[0].Trading;
			const beforeLastValue = trends[1].Trading;
			if (lastValue === beforeLastValue) return null;

			return lastValue > beforeLastValue ? 'up' : 'down';
		}

		return null;
	}, [trends]);

	useEffect(() => {
		const svg = d3.select(svgRef.current);
		const width = svg.node().clientWidth;
		const height = svg.node().clientHeight;

		// Parse the date and prepare the data
		const parsedData = trends
			.filter((d) => d.Trading)
			.map((d) => ({
				Date: d3.timeParse('%Y-%m-%d')(d.Date),
				Trading: +d.Trading,
			}))
			.sort((a, b) => a.Date - b.Date);

		// Define the scales
		const xScale = d3
			.scaleTime()
			.domain(d3.extent(parsedData, (d) => d.Date))
			.range([0, width]);

		const maxY = d3.max(parsedData, (d) => d.Trading) * 1.1;
		const minY = d3.min(parsedData, (d) => d.Trading) * 0.9;

		const yScale = d3.scaleLinear().domain([minY, maxY]).range([height, 0]);

		// Define the line generator
		const line = d3
			.line()
			.x((d) => xScale(d.Date))
			.y((d) => yScale(d.Trading))
			.curve(d3.curveMonotoneX);

		// Draw the trendline
		svg.selectAll('*').remove(); // Clear previous content

		svg
			.append('path')
			.datum(parsedData)
			.attr('fill', 'none')
			.attr(
				'stroke',
				trend ? (trend === 'up' ? theme.palette.success.main : theme.palette.error.main) : theme.palette.grey['500']
			)
			.attr('opacity', 0.07)
			.attr('stroke-width', 8)
			.attr('d', line);
	}, [trends, trend]);

	const onActionClick = (e) => {
		if (onStarClick) {
			e.stopPropagation();
			e.preventDefault();
			onStarClick(id);
		}
	};

	return (
		<CardActionArea
			onClick={onClick ? () => onClick(id) : undefined}
			disabled={!Boolean(onClick || link)}
			component={onClick ? undefined : RouterLink}
			to={link ?? '/'}
			sx={{ height: '100%' }}
		>
			<CompanyCardContainer variant={'elevation'} elevation={elevation} sx={{ position: 'relative' }}>
				<TrendOverlayBackground trend={trend} />
				<TrendlineSvg ref={svgRef} />
				<CompanyCardHeader
					title={companyName}
					action={
						<IconButton disabled={!Boolean(onStarClick)} onClick={onActionClick}>
							{isStar && <Star sx={{ color: yellow[800], fontSize: 24 }} />}
							{!isStar && onStarClick && <StarBorder sx={{ color: grey[500], fontSize: 24 }} />}
						</IconButton>
					}
					sx={{ paddingBottom: 0 }}
					data-cy={'company-card-header'}
				/>
				<CardContent sx={{ paddingTop: isMobile ? 1 : undefined }}>
					<Stack spacing={isMobile ? '12px' : 2}>
						<Stack spacing={isMobile ? '4px' : 1}>
							<Stack spacing={1} direction={'row'} alignItems={'center'}>
								<SvgIcon component={sectorIcon} sx={{ fontSize: 16, color: 'text.secondary' }} />
								<Typography variant="body2" color={'text.secondary'}>
									{sectorName}
								</Typography>
							</Stack>
							{(!dynamicFields || Object.keys(dynamicFields).length === 0) && (
								<Stack spacing={1} direction={'row'} alignItems={'center'}>
									<MonetizationOn sx={{ fontSize: 16, color: 'primary.main' }} />
									<Typography variant="body2" color={'primary.main'}>
										{valuation}
									</Typography>
								</Stack>
							)}
						</Stack>
						{dynamicFields && Object.keys(dynamicFields).length > 0 && (
							<>
								<Divider />
								<Stack spacing={isMobile ? '6px' : 1}>
									{Object.keys(dynamicFields).map((key) => {
										if (key === 'Weekly Change') {
											const value = dynamicFields[key];

											return (
												<Stack key={key} justifyContent={'space-between'} direction={'row'}>
													<Typography variant={'body2'} color={'text.secondary'}>
														{key}
													</Typography>
													<Typography
														variant={'body2'}
														color={
															value !== '-'
																? _.startsWith(value, '+')
																	? 'success.main'
																	: 'error.main'
																: 'text.secondary'
														}
													>
														{value}
													</Typography>
												</Stack>
											);
										}
										return (
											<Stack key={key} justifyContent={'space-between'} direction={'row'}>
												<Typography variant={'body2'} color={'text.secondary'}>
													{key}
												</Typography>
												<Typography variant={'body2'}>{dynamicFields[key]}</Typography>
											</Stack>
										);
									})}
								</Stack>
							</>
						)}
					</Stack>
				</CardContent>
			</CompanyCardContainer>
		</CardActionArea>
	);
};

export default CompanyCard;

import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { useMemo } from 'react';
const Logo = ({ color, isShort = false, isAbsolute = true }) => {
	const absoluteStyle = { position: 'absolute', top: 16, left: 24, zIndex: 2 };
	const shortStyle = { border: `1.6px solid ${color}`, padding: '6px', borderRadius: '4px' };
	const { authenticated, user } = useAuthContext();
	const link = useMemo(() => {
		if (!authenticated) {
			return '/';
		} else {
			if (user?.isAdmin) {
				return '/admin';
			} else {
				return '/home';
			}
		}
	}, [authenticated, user?.isAdmin]);

	return (
		<Link
			to={link}
			style={{
				...(isAbsolute ? absoluteStyle : {}),
				...(isShort ? shortStyle : {}),
				textDecoration: 'none',
			}}
		>
			<Typography variant="h6" sx={{ color: color }}>
				{isShort ? 'SW' : 'ShareWell | SaxonWeber'}
			</Typography>
		</Link>
	);
};

export default Logo;

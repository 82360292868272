import { Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import 'react-material-symbols/rounded';

const ActivityFeedCard = ({ title, date, isNew, children }) => {
	const defaultTheme = useTheme();

	return (
		<Paper
			elevation={15}
			sx={{
				borderLeft: isNew ? `2px solid ${defaultTheme.palette.error.main}` : 'none',
				boxShadow: 'none',
			}}
		>
			<Stack direction={'column'} spacing={'12px'} p={'20px'} pl={isNew ? '18px' : '20px'}>
				<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
					<Stack direction={'row'} alignItems={'center'} spacing={1}>
						<Typography variant={'body1'} fontWeight={600}>
							{title}
						</Typography>
						{isNew && <Box width={6} height={6} borderRadius="50%" bgcolor="error.main" />}
					</Stack>
					<Typography variant={'caption'} color={'text.secondary'}>
						{date}
					</Typography>
				</Stack>
				{children}
			</Stack>
		</Paper>
	);
};

export default ActivityFeedCard;

export default (
	<div>
		<h2>Accredited Investors: Definition for Individuals</h2>
		<p>
			Accredited investors, as defined by the SEC, encompass individuals meeting specific financial criteria. As of
			December 8, 2020, the criteria include:
		</p>
		<ol>
			<li>
				<b>Income Threshold:</b>
				<ul>
					<li>
						Individuals with an annual income of $200,000 or more in each of the last two years, or jointly with a
						spouse, having a combined income of $300,000 or more, with the reasonable expectation of maintaining the
						same level of income for the current year.
					</li>
				</ul>
			</li>
			<li>
				<b>Net Worth Requirement:</b>
				<ul>
					<li>
						Individuals with a net worth of $1 million or more, either individually or jointly with a spouse. This
						calculation excludes the value of the person's primary residence.
					</li>
				</ul>
			</li>
			<li>
				<b>Inclusion of "Spousal Equivalents":</b>
				<ul>
					<li>
						Effective December 8, 2020, "spousal equivalents" may be considered in the calculation of joint income or
						net worth, expanding the definition to include domestic partners or individuals in similar relationships.
					</li>
				</ul>
			</li>
			<li>
				<b>Professional Designations:</b>
				<ul>
					<li>
						Individuals holding certain professional designations or credentials designated by the SEC as qualifying an
						individual as an accredited investor. These designations include:
						<ul>
							<li>General Securities Representative license (Series 7)</li>
							<li>Investment Adviser Representative license (Series 65)</li>
							<li>Private Securities Offerings license (Series 82)</li>
						</ul>
					</li>
				</ul>
			</li>
			<li>
				<b>Family Clients of Family Offices:</b>
				<ul>
					<li>
						Individuals considered "family clients" of a "family office" whose investment is directed by the family
						office.
					</li>
				</ul>
			</li>
			<li>
				<b>Knowledgeable Employees or Officers/Directors of Private Funds:</b>
				<ul>
					<li>
						Individuals who are "knowledgeable employees" or officers/directors of the issuer of the securities, or
						affiliated managers. This applies when the issuer is a private fund relying on Sections 3(c)(1) or 3(c)(7)
						of the Investment Company Act of 1940.
					</li>
				</ul>
			</li>
		</ol>
		<div>
			<h3>Conclusion:</h3>
			<p>
				Accredited investors play a crucial role in private investment markets. The criteria outlined by the SEC aim to
				ensure that individuals meeting specific financial thresholds or possessing certain professional designations
				can participate in investment opportunities that may have higher risk and potential returns. The inclusion of
				spousal equivalents and expanded professional designations reflects recent updates, providing a broader scope
				for individuals to qualify as accredited investors.
			</p>
		</div>
	</div>
);

import { IconButton, Stack } from '@mui/material';
import { AttachFile, Send } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { ChatInput, FilePreviewContainer, VisuallyHiddenInput } from './style';
import FilePreview from '../../../../FilePreview';

const ChatControls = ({ handleSendMessage }) => {
	const [newMessage, setNewMessage] = useState('');
	const [selectedFiles, setSelectedFiles] = useState(null);
	const inputRef = useRef(null);
	const [fileUrl, setFileUrl] = useState(null);

	useEffect(() => {
		if (selectedFiles && selectedFiles.length) {
			const reader = new FileReader();

			reader.onload = function (e) {
				setFileUrl(e.target.result);
			};

			// Read the file as a data URL
			reader.readAsDataURL(selectedFiles[0]);
		}

		setFileUrl(null);
	}, [selectedFiles]);

	const handleInputKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			onSend();
		}
	};

	const onSend = () => {
		if (newMessage || selectedFiles) {
			handleSendMessage(newMessage, selectedFiles);
			setNewMessage('');
			onFileReset();
		}
	};

	const onFileChange = (e) => {
		const files = Array.prototype.slice.call(e.target.files);
		setSelectedFiles(files);
	};

	const onFileReset = () => {
		setSelectedFiles(null);
		inputRef.current.value = null;
	};

	return (
		<Stack direction={'row'} spacing={'2px'} alignItems={'center'} position={'relative'}>
			<IconButton color="primary" size={'medium'} component="label">
				<VisuallyHiddenInput ref={inputRef} type="file" onChange={onFileChange} />
				<AttachFile fontSize={'inherit'} />
			</IconButton>
			<ChatInput
				placeholder="Send message"
				value={newMessage}
				onKeyDown={handleInputKeyPress}
				onChange={(e) => setNewMessage(e.target.value)}
			/>
			<IconButton color="primary" onClick={onSend} size={'medium'}>
				<Send fontSize={'inherit'} />
			</IconButton>
			{Boolean(selectedFiles) && (
				<FilePreviewContainer>
					<FilePreview
						onDelete={onFileReset}
						url={fileUrl}
						fileName={selectedFiles && selectedFiles.length && selectedFiles[0].name}
					/>
				</FilePreviewContainer>
			)}
		</Stack>
	);
};

export default ChatControls;
